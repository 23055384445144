import 'bootstrap/dist/css/bootstrap.css';
import * as React from 'react';
import { ErrorBoundary}  from 'react-error-boundary'
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

function Fallback({ error, resetErrorBoundary }:{error:Error, resetErrorBoundary:any}) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    return (
        <Container fluid={true}>
            <div className="center">
                <div className="col-md-6 offset-md-3">
                    <div role="alert">
                        <h1>Något fick fel/Something went wrong</h1>
                        <p>Please press <button className="btn-primary" onClick={()=> resetErrorBoundary() }>here</button> to clear the local data and reload the page. If this doesn't resolve the issue, please empty your browser cache manually and reload again.</p>
                        <p>Var vänlig tryck <button className="btn-primary" onClick={()=> resetErrorBoundary() }>här</button> för att rensa bort lokal data och ladda om sidan. Om detta inte åtgärdar problemet, var vänlig töm din webbläsares cache manuellt och ladda om sidan.</p>
                        <pre style={{ color: "red" }}>{error.message}</pre>
                    </div>
                </div>
            </div>
        </Container>
    );
  }

const GlobalErrorBoundary = function (props: React.PropsWithChildren<{}>) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    return (
        <ErrorBoundary FallbackComponent={Fallback} onReset={() =>{
            localStorage.clear(); 
            sessionStorage.clear();
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistrations()
                .then(function(registrations) {
                   for(let registration of registrations) {
                      registration.unregister();
                   }
                });
            }
            window.location.reload();
        }} >{props.children}</ErrorBoundary>
    );
  }

  export default GlobalErrorBoundary;