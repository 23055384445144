import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { ISiteReference } from './Login';
import { IReportContext, powerBiServiceFactory } from '../../services/powerBIService';
import { ApplicationState } from '../../store';
import { PowerBIEmbed } from 'powerbi-client-react';
import *  as PowerBI from 'powerbi-client';
import { IReportEmbedConfiguration, Embed, Report } from 'powerbi-client';
import { FilterType, IFilter } from 'powerbi-models';
import './Statistics.css';
import { useTranslation } from 'react-i18next';
import * as AuthenticationStore from '../../store/Authentication'
import Context from '../../configuration/context';
import { IAuthenticationActionCreators, UserState } from '../../store/Authentication';
import { authenticationServiceFactory } from '../../services/authenticationService';
import { useForm } from 'react-hook-form';



export type IStatisticsProps =
    IAuthenticationActionCreators &
    {
        authenticationState?: UserState;
    }

const Statistics: React.FunctionComponent<React.PropsWithChildren<IStatisticsProps>> = ({ authenticationState, setLoginState }: React.PropsWithChildren<IStatisticsProps>) => {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const [context, setContext] = useState<IReportContext>();
    const { t } = useTranslation('report');

    const LoginState = authenticationState?.LoginState;
    const customerId = authenticationState?.UserInRole?.selectedCustomer?.customerId;

    useEffect(() => {
        setError("");
        if (LoginState && LoginState === AuthenticationStore.LoginState.LOGGED_IN && customerId) {
            setIsLoading(true);
            powerBiServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState)).loadContext(customerId)
                .then((reportContext: IReportContext) => {
                    //if(reportContext.internalCustomerId > 0){
                        setContext(reportContext);
                    //}else{
                    //    setContext(undefined);
                    //    setError("ERROR_LOADING_REPORT_MAPPING_NOT_FOUND");
                    // }
                    setIsLoading(false);
                }).catch((error: any) => {
                    setIsLoading(false); 
                    setContext(undefined); 
                });
        }
    }, [LoginState, customerId]);


    useEffect(() => {
        if (context) {
            setIsLoading(false);
        }
    }, [context]);

    let filters: PowerBI.models.ReportLevelFilters[] = [];

   

    filters = customerId === "1000000001" ? [] :[
            {
                $schema: "http://powerbi.com/product/schema#advanced",
                filterType: FilterType.Advanced,
                target: {
                    table: "dDatum",
                    column: "Datumfilter"
                },
                logicalOperator: "And",
                conditions: [{ operator: "Is", value: "1" }]
            },
            {
                $schema: "http://powerbi.com/product/schema#advanced",
                filterType: FilterType.Advanced,
                target: {
                    table: "dKontaktorsak",
                    column: "Kontaktorsak"
                },
                logicalOperator: "Or",
                conditions: [{ operator: "Is", value: "Hållbar" }, { operator: "Is", value: "Hälsa" }, { operator: "Is", value: "Hälsorisk" }, { operator: "Is", value: "Ohälsa" }]
            }
        ,{
            $schema: "http://powerbi.com/product/schema#advanced",
                filterType: FilterType.Advanced,
                    target: {
                table: "dKundforetag",
                    column: "RootId"
            },
            logicalOperator: "And",
            conditions: [{ operator: "Is", value: customerId && customerId === "1000000001" ? 1000000001 : context?.internalCustomerId }]
        }];
    


    const ReportConfig: IReportEmbedConfiguration = {
        type: 'report',   // Supported types: report, dashboard, tile, visual and qna
        id: context?.report.id,
        embedUrl: context?.report.embedUrl,
        accessToken: context?.accessToken,
        tokenType: PowerBI.models.TokenType.Embed,
        filters: filters,
        settings: {
            localeSettings : {  
                language: "en-US"
            },
            navContentPaneEnabled: false,
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                }
            },
            background: PowerBI.models.BackgroundType.Transparent
        }
    }

    let report: Report;

    var resetReport = () => {
        // report.setFilters(filters);
        report.on("rendered", afterInitialRender);
        report.reload();
        // report.getPages().then((pages) => {
        //     report.updateFilters(PowerBI.models.FiltersOperations.ReplaceAll, filters);
        //     report.setPage(pages[0].displayName);
        // });        
    }

    const reportStartLevel: number = 1;
    const reportDepth: number = 5;

    var afterInitialRender = async (event: any) => {
        report.off("rendered");
        report.on('pageChanged', async (event: any) => {
            const report = event.detail.newPage.report as PowerBI.Report;
            report.updateFilters(PowerBI.models.FiltersOperations.ReplaceAll, filters);
        });


        const pages = await report.getPages();
        var cloneTitle = "Clone" + reportStartLevel + "_" + (reportStartLevel + reportDepth - 1);
        var clonePage: PowerBI.Page | null = null;
            pages.forEach(function (e) {
                if (e.displayName === "Kloner") {
                    clonePage = e;
                }
            });
            if (clonePage != null) {
                const visuals = await (clonePage as PowerBI.Page).getVisuals();

                for (var i = 0; i < visuals.length; i++) {
                    //  if (i === 0) {
                    if (visuals[i].title === cloneTitle) {
                        var visual = visuals[i];
                        let cloneRequest = {
                            layout: { x: 15, y: 490, z: 100000 }
                        };
                        try{
                            await visual.clone(cloneRequest);
                        }catch(error){
                            console.error(error);
                            alert("Loading the report failed!");
                        }
                        break;
                    }
                }
            }

        // report.updateFilters(PowerBI.models.FiltersOperations.ReplaceAll, filters);

        // report.setFilters(ReportConfig.filters as IFilter[])
        //     .catch(function (catchFilterErrorData) { console.debug(catchFilterErrorData) });
        
        // report.on('dataSelected', async (event: any) => {
        //     var page = await report.getActivePage();
        //     console.debug('dataSelected', event);
        //     const pagefilters = await page.getFilters();
        //     const reportfilters = await report.getFilters();
        //     console.debug("page filters", pagefilters);
        //     console.debug("report filters", reportfilters);
        // });
    }

    return (
        <div className='powerbi-container row'>
            <div className="mt-4 col-12">
                <h2 className="text-center">{t('HEADER')}</h2>
                {
                    isLoading
                        ? <h1 className="loading-indicator-container col-12"><Spinner />{t('INITIALIZING')}...</h1>
                        : 
                        <>
                            {context &&
                                <>
                                    <div className='row'>
                                        <div className="mt-4 col-12">
                                            <button onClick={() => { report.print() }} className="btn-primary print-button"><i className="fa fa-print" /> {t('PRINT')}</button>  <button onClick={resetReport} className="btn-primary print-button"><i className="fa fa-refresh" /> {t('RESET_REPORT')}</button>
                                        </div>
                                    </div>
                                    <PowerBIEmbed
                                        cssClassName="power-bi-container"
                                        embedConfig={ReportConfig}
                                        eventHandlers={
                                            new Map([
                                                ['rendered', afterInitialRender],
                                                ['error', (event: any) => { console.debug(event.detail); }]
                                            ])
                                        }
                                        getEmbeddedComponent={(embeddedReport: Embed) => {
                                            report = embeddedReport as Report;
                                        }}
                                    />
                                </>
                            }
                            { error && <div className="error-message">{t(error)}</div> }
                        </>
                }
            </div>
        </div>
    );
}

export default connect((appState: ApplicationState) => ({ authenticationState: appState.authentication }),
    { ...AuthenticationStore.actions })(Statistics);