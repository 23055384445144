
import * as React from 'react';
import { Route, useLocation, Routes, Navigate, useNavigate, BrowserRouter, useMatch } from 'react-router-dom';
import {appInsights }from '../../services/applicationInsights';
import { useEffect } from 'react';


const useApplicationInsights = function(){

    const [pageTrackingDetails, setPageTrackingDetails] = React.useState<any>();


    useEffect(() => {
        return function cleanup() {
            if(pageTrackingDetails){
                console.debug("page tracking stop");
                appInsights.stopTrackPage(pageTrackingDetails.title, pageTrackingDetails.url, pageTrackingDetails);
            }
        };
    }, []);

    useEffect(() => {
        if(pageTrackingDetails){
            appInsights.stopTrackPage(pageTrackingDetails.title, pageTrackingDetails.url, pageTrackingDetails);
        }
        appInsights.startTrackPage();
        var _props: any = { url: document.location.href, title: document.title };
        setPageTrackingDetails(_props);
        
    }, [location.pathname]);

    return appInsights;

}

export default useApplicationInsights;