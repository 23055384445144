import * as React from 'react';
import { Route, useLocation, Routes, Navigate, useNavigate, useMatch } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/pages/Home';
import Help from './components/pages/Help';
import Statistics from './components/pages/Statistics';
import Orders from './components/pages/Orders';
import Invoices from './components/pages/Invoices';
import Conversations from './components/pages/Conversations';
import { LoginState, UserState } from './store/Authentication'


import './custom.css'
import { connect } from 'react-redux';
import { ApplicationState } from './store';
import Login, { ISiteReference, IUserInRole } from './components/pages/Login';
import Safetycheck from './components/pages/Safetycheck';
import Files from './components/pages/Files';
import Contact from './components/pages/Contact';
import Order from './components/pages/Order';
import * as AuthenticationStore from './store/Authentication';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import { authenticationServiceFactory } from './services/authenticationService';
import Context from './configuration/context';
import { Alert } from 'reactstrap';
import AccessTokenProvider from './components/controls/AccessTokenProvider';
import Panel from './components/controls/Panel';
import Profile from './components/controls/Profile';
import useApplicationInsights from './components/hooks/useApplicationInsights';
import useServiceWorker from './components/hooks/useServiceWorker';
import useAlerts from './components/hooks/useAlerts';
import useConfiguredDatePicker from './components/hooks/useConfiguredDatePicker';
import SamlLogin from './components/pages/SamlLogin';
import SamlRegister from './components/pages/SamlRegister';

export type AppProps = Partial<typeof AuthenticationStore.actions> & {
    userState?: UserState;
}

const App = function ({ userState, setLoginState, hideUserProfile }: AppProps) {

    useApplicationInsights();

    const { DatePicker } = useConfiguredDatePicker();

    const [title, setTitle] = React.useState("Avonova Customer Portal");
    const authenticationState = userState;
    const [profilePanelIsVisible, setProfilePanelIsVisible] = useState(false);

    const location = useLocation();
    const { pathname, search } = location;

    const navigate = useNavigate();

    const isLoggedIn = userState ? userState.LoginState === LoginState.LOGGED_IN : false;

    const selectedCustomer = userState?.UserInRole?.selectedCustomer;
    const customerHasBeenSelected = !!selectedCustomer;

    const { i18n, t } = useTranslation('home');

    var isConversationLink = useMatch('/contact/conversation/:conversationId');

    const authenticationService = useMemo(() => {
        return authenticationServiceFactory(Context.APIUrl, { setLoginState }, userState);
    }, [userState]); 

    const { showReloadInstructions } = useServiceWorker();

    useEffect(() => {
        if (selectedCustomer) {
            setTitle(selectedCustomer?.customerName + " (" + selectedCustomer?.customerId + ")");
        } else {
            setTitle("Avonova Customer Portal");
        }
    }, [selectedCustomer]);

    useEffect(() => {
        var match = pathname.match(/^\/(\d+)(\/.*|$)/);
        if (match) {
            var matchedId = match[1];
            
            console.debug("Setting customer id by url:", match[1]);
            if (userState && userState.UserInRole && userState?.LoginState === LoginState.LOGGED_IN){
                if (userState.UserInRole?.role === "http://schemas.avonova.com/avonova/v1/identity/claims/role/superadmin"){
                    authenticationService.getOrRenewNativeToken()
                        .then((accessToken) => authenticationService.fetchUserDetails(accessToken, matchedId))
                        .then((userInRole: IUserInRole) => {
                            setLoginState && setLoginState({
                                UserInRole: userInRole
                            });
                        });
                }
                else{ 
                    var customer = !!matchedId ? userState.UserInRole?.availableCustomers?.find(c => c.customerId.toString() === matchedId) : null;
                    if (customer ){
                        setLoginState && setLoginState({
                            UserInRole: { ...userState.UserInRole, selectedCustomer: customer }
                        })
                    }
                }
            }else {
                setLoginState && setLoginState({
                    RequestedCustomerId: matchedId,                    
                });
            }
            if(userState?.LoginState !== LoginState.UNDEFINED){
                console.debug("navigating to", match[2])
                navigate(match[2], {replace:true});
            }
        }        
    }, [pathname, setLoginState, userState?.LoginState, userState?.UserInRole?.selectedCustomer]);

    useEffect(() => {
        const _isLoggedIn = userState ? userState.LoginState === LoginState.LOGGED_IN : false;
        if (location.pathname === "/login" && _isLoggedIn) {
            navigate("/service");
        }
    
    }, [location.pathname, navigate, userState]);
    
    useEffect(() => {
        DatePicker.setDefaultLocale(i18n.language);
    }, [i18n.language])

    useEffect(() => {
        if (isLoggedIn && !customerHasBeenSelected && !isConversationLink){
            setProfilePanelIsVisible(true);
        }
        else{
            setProfilePanelIsVisible(!!userState?.UserPanelIsVisible);
        }
    }, [isLoggedIn, customerHasBeenSelected, userState?.UserPanelIsVisible]);

    const { activeAlerts } = useAlerts(isLoggedIn, authenticationService);

    return (
        <Layout>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {showReloadInstructions && <Alert key="warning" variant="warning" className="alert-warning">{t("NEW_APP_VERSION")}</Alert>}
            {activeAlerts && activeAlerts.map(a => <Alert key={a.key} className={"alert-" + a.alertType }>{t(a.key)}</Alert>)}
            { isLoggedIn ?
                <>
                    <AccessTokenProvider>
                        <Routes>
                            <Route path='/service/statistics' element={<Statistics/>} />
                            <Route path='/service/order/:type/:orderNumber/copy' element={<Order/>} />
                            <Route path='/service/order/:type/:orderNumber' element={<Order/>} />
                            <Route path='/service/order/:type' element={<Order/>} />
                            <Route path='/service/order' element={<Orders/>} />
                            <Route path='/service/invoice' element={<Invoices/>} />
                            {/* <Route path='/service/afs' element={<Afs/>} /> Tillf�lligt dold*/}
                            <Route path='/service' element={<Home/>} />
                            <Route path='/filearea' element={<Files/>} />
                            <Route path='/checklists' element={<Safetycheck/>} />
                            <Route path='/contact/conversation/:conversationId' element={<Conversations/>} />
                            <Route path='/contact/conversation' element={<Conversations/>} />
                            <Route path='/contact' element={<Contact/>}/>
                            <Route path='/logout' element={<Home/>} />
                            <Route path='/saml' element={<SamlRegister/>} />
                            <Route path='/login/resetpassword' element={<Help/>}  />
                            <Route path='*' element={<Navigate replace to="/service" />} />
                        </Routes>
                    </AccessTokenProvider>
                { profilePanelIsVisible && <Panel onClose={() => hideUserProfile && hideUserProfile() }>
                 <Profile
                     OnLogout={() => { 
                        hideUserProfile && hideUserProfile();
                        authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState).logoutUser();
                     }}
                     OnActiveCustomerSelect= {
                         (site: ISiteReference) => {
                             if(setLoginState){
                                 setLoginState({
                                     RequestedCustomerId: site.customerId.toString(),
                                 });
                                 if(!authenticationState){
                                    throw "authentication state cannot be undefined after login";
                                 }
                                 authenticationState.RequestedCustomerId = site.customerId.toString();
                                 authenticationState && authenticationState.AccessToken && authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState)
                                    .fetchUserDetails(authenticationState.AccessToken, site.customerId.toString())
                                    .then((userInRole: IUserInRole) => {
                                        setLoginState && setLoginState({
                                            UserInRole: userInRole
                                        });
                                 })
                             }
                         }
                     }
                    />
                </Panel> }
                </>
            :
                <Routes>
                    <Route path='/samllogin' element={<SamlLogin />}></Route>
                    <Route path='*' element={<Login />}></Route>
                </Routes>
            }

        </Layout>
    );
}

export default connect(
    (state: ApplicationState, ownProps: AppProps) => ({
        userState: state.authentication
    }),
    AuthenticationStore.actions
)(App);