import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as HttpErrors from '../services/httpErrors' ;

import { ITokenProvider } from './authenticationService';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.locale("sv");

export interface ILocalizedText{
    languageCode:string;
    text:string;
}

export interface IAlert {
    from: Date;
    to: Date;
    key:string;
    localizedTexts:ILocalizedText[];
    alertType:string;
}

export interface IAlertsService {    
    getActiveAlerts: () => Promise<IAlert[]>;
}

export interface ILanguageResource{
    language:string;
    namespace:string;
    
}


export const alertsServiceFactory: (ApiHostBaseUrl: string, tokenProvider: ITokenProvider) => IAlertsService = (ApiHostBaseUrl: string, tokenProvider: ITokenProvider) => {
    return {
        getActiveAlerts: () => {
            return tokenProvider.getOrRenewNativeToken().then((accessToken:string) => {
                return fetch(ApiHostBaseUrl + '/customerPortal/getActiveAlerts', {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                })
            })
            .then((data: Response) => {
                if(!data.ok){
                    if(data.status === 401){
                        // Not logged in
                        throw HttpErrors.UNAUTHORIZED;
                    }
                    if (data.status === 403) {
                        // Not logged in
                        throw HttpErrors.FORBIDDEN;
                    }
                }
                return data.json() as Promise<IAlert[]>;
            });
        }
    }
};