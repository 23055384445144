import * as Alerts from './Alerts'
import * as Authentication from './Authentication'
import * as I18Next from './i18next/I18next'

// The top-level state object
export interface ApplicationState {
    authentication: Authentication.UserState;
    alert:Alerts.Alert;
    languageState: string;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    authentication: Authentication.reducer,
    alert: Alerts.reducer,
    locale: I18Next.reducer
};

export const expirationConfigurations = {
    authentication: Authentication.expirationConfig
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}