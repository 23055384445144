import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ICustomerResponsible } from "../pages/Login";
import './ContactCard.css'
import Context from '../../configuration/context';
import { filesServiceFactory } from "../../services/filesService";
import { IAuthenticationService } from "../../services/authenticationService";

interface IContactCardProps{
    contact:ICustomerResponsible;
    authenticationService?: IAuthenticationService;
    className?:String;
}

const ContactCard = function ({ contact, authenticationService, className }: IContactCardProps) {

    // const { t } = useTranslation('contact');
    const [imageUrl,setImageUrl] = useState("");

    useEffect(() => {
        if (authenticationService && contact.email){
            filesServiceFactory(Context.APIUrl + "/api/v1", authenticationService).getContactImage(contact.email).then((blob: Blob) => {
                var objectURL = URL.createObjectURL(blob);
                setImageUrl(objectURL);
            });
        }
    }, [authenticationService, contact])



    return (
        <div className={"contact-card-container " + className}>
            <div className={"contact-card"}>
                <div className="image-container">
                    {imageUrl !== "" && <img src={imageUrl} alt={contact.displayName}/>}
                    {imageUrl === "" && <img src="/assets/images/PersonPlaceholder.png" alt="placeholder" /> }
                </div>
                <div className="data-container">
                    <span>{contact.displayName}</span>
                    {contact.phone && <span><a href={"tel:" + contact.phone.replace(/[- ]/, "")}><i className="fa fa-mobile"></i>{contact.phone}</a></span>}
                    {contact.email && <span><a href={"mailto:" + contact.email}><i className="fa fa-envelope"></i>{contact.email}</a></span> }
                </div>
            </div>
        </div>
    );
}

export default connect()(ContactCard);