import * as React from 'react';
import { Spinner, Table, NavLink } from 'reactstrap';
import './AFSFiles.css';
import { connect } from 'react-redux';
import * as RRD from 'react-router-dom'

import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import { useTranslation } from 'react-i18next';
import { IOrderReference, orderServiceFactory } from '../../services/orderService';
import { useState } from 'react';
import { SortService, ISortState, SortInstruction } from '../../services/sortService';
import { FieldPath } from 'react-hook-form';
import Context from '../../configuration/context';
import { authenticationServiceFactory } from '../../services/authenticationService';
import { IAuthenticationActionCreators, UserState } from '../../store/Authentication';

dayjs.extend(relativeTime);
dayjs.extend(timezone)
dayjs.locale("sv");
// dayjs.tz.setDefault("Great Britain/London");

export type IOrderListProps =  {
    items?: IOrderReference[];
    onCopyClick?: (order: IOrderReference) => void;
    showFetchMoreIndicator?: boolean;
    enableFetchMore?: boolean;
    onFetchMore?: () => void;
    onDownloadPDF:(order: IOrderReference) => void
}


const OrderList = function ({ items, onCopyClick, showFetchMoreIndicator, onFetchMore, enableFetchMore, onDownloadPDF}: IOrderListProps) {

    const { t } = useTranslation('orders');

    const sortService = SortService<IOrderReference>();

    const [sortedItems,setSortedItems] = useState<IOrderReference[]>( !!items ? sortService.sortItems(items, { value: "orderDate", asc: false }) : []);
    const [sortInstruction,setSortInstruction] = useState<SortInstruction<IOrderReference>>({ value: "orderDate", asc: false });
    const [sortedColumn,setSortedColumn] = useState("orderDate");
    
    React.useEffect(() => {
        setSortedItems(!!items ? sortService.sortItems(items, sortInstruction) : []);
    }, [items]);
    
    const onHeaderClick = (columnName:string, value: FieldPath<IOrderReference> | ((item:IOrderReference) => any)) => {
        const _sortInstruction = {  value: value , asc: sortedColumn === columnName ? !sortInstruction.asc : true };
        console.log("Sorting by",value );
        setSortInstruction(_sortInstruction)
        console.log("Sorting instructions",_sortInstruction );
        const _sortedItems = !!items ? sortService.sortItems([...items], _sortInstruction) : [];
        console.log(_sortedItems);
        console.log(_sortedItems === sortedItems);
        setSortedItems(_sortedItems);
        console.log(_sortedItems === sortedItems);
        setSortedColumn(columnName);

    }



    const getClass: (columnName: string) => string = (columnName: string) => {
        return "sortable" + (sortedColumn === columnName ? " sorted" + (sortInstruction.asc ? " asc" : " desc") : "")
    }

    return (
        <>
        <div className="row">
            <div className="col collapsable-table-md">
                <Table hover className=" default" striped={true}>
                    <thead>
                        <tr>
                            <th className={getClass("orderNumber")} onClick={() => onHeaderClick("orderNumber", "orderNumber")}>{t('ORDER_ID')}</th>
                            <th className={getClass("orderDate")} onClick={() => onHeaderClick("orderDate", "orderDate")}>{t('ORDER_DATE')}</th>
                            <th className={getClass("orderStatus")} onClick={() => onHeaderClick("orderStatus", "orderStatus")}>{t('ORDER_STATUS')}</th>
                            <th className={getClass("organization")} onClick={() => onHeaderClick("organization", "organization")}>{t('ORDER_ORGANIZATION')}</th>
                            <th className={getClass("orderType")} onClick={() => onHeaderClick("orderType", "orderType")}>{t('ORDER_TYPE')}</th>
                            <th className={getClass("orderedBy")} onClick={() => onHeaderClick("orderedBy", (order) => order.createdBy)}>{t('ORDER_ORDERED_BY')}</th>
                            <th></th>
                            {/*<th></th>*/}
                        </tr>
                    </thead>
                    <tbody>
                            {sortedItems.map((i: IOrderReference) => 
                            <tr key={i.orderNumber}>
                                <td><span className="collapsed-title">{t('ORDER_ID')}: </span><NavLink tag={RRD.NavLink} to={"/service/order/"+ i.orderType.toLowerCase() +"/" + i.orderNumber}>{i.orderNumber}</NavLink></td>
                                <td><span className="collapsed-title">{t('ORDER_DATE')}: </span> {dayjs(i.orderDate).format("YYYY-MM-DD HH:mm")}</td>
                                <td><span className="collapsed-title">{t('ORDER_STATUS')}: </span> {t("STATUS_" + i.orderStatus)}</td>
                                <td><span className="collapsed-title">{t('ORDER_ORGANIZATION')}: </span> {i.organization}</td>
                                <td><span className="collapsed-title">{t('ORDER_TYPE')}: </span>{t("TYPE_" + i.orderType)}</td>
                                <td><span className="collapsed-title">{t('ORDER_ORDERED_BY')}: </span> {i.createdBy}</td>
                                <td><a href="/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => { e.preventDefault(); e.stopPropagation(); onCopyClick && onCopyClick(i) }}>{t('COPY')}</a></td>
                                {/*<td><a href="/#orderAsPDF" onClick={(event:any) => { event.preventDefault(); event.stopPropagation(); onDownloadPDF(i); }} ><i className="fa fa-print fa-solid"></i></a></td>*/}
                            </tr>)}
                    </tbody>
                </Table>
            </div>
        </div>
        <div className="row footer">
                <div className="col-12 text-center loading-more-indicator-container col-12">
                    {enableFetchMore && !showFetchMoreIndicator && <div className="col-12 text-center btn-primary" onClick={() => { onFetchMore && onFetchMore() }}>{t('FETCH_MORE')}</div>}
                    {showFetchMoreIndicator && <div className="col-12 text-center btn-primary"><Spinner className="load-more-spinner" size="sm"  /> {t('FETCHING_MORE')}...</div>}
                </div>
        </div>
        </>
    );
}

export default connect()(OrderList);