import * as React from 'react';
import { connect } from 'react-redux';
import * as AuthenticationStore from '../../store/Authentication'
import * as AlertStore from '../../store/Alerts'
import { ApplicationState } from '../../store';
import { Alert, FormGroup, Spinner } from 'reactstrap';
import './Login.css'
import { IAuthenticationActionCreators } from '../../store/Authentication';
import { IAlertActionCreators } from '../../store/Alerts';
import { IUser, ICustomerContact, IAdministrator } from '../../models/User';
import { Trans, useTranslation } from 'react-i18next';
import Context from '../../configuration/context';
import { useEffect, useMemo, useState } from 'react';
import { appInsights } from '../../services/applicationInsights';
import CustomLogin from '../controls/CustomLogin/CustomLogin';
import customLoginService from '../../services/customLoginService';
import MSLogin from '../controls/MSLogin/MSLogin';
import samlLoginService from '../../services/samlLoginService';
import { ITokenProvider } from '../../services/authenticationService';
import { useLocation } from 'react-router-dom';
import { access } from 'fs';
import LoginByTOTP from '../controls/MSLogin/TOTPLogin';

export type MSLoginState = "IDLE" | "ACQUIRING_NATIVE_TOKEN" | "AWAITING_CODE_LOGIN" | "DOING_FINAL_LOGIN";

export type ILoginSamProps =
    IAuthenticationActionCreators &
    IAlertActionCreators &
    {
        authenticationState?: AuthenticationStore.UserState;
    }

    interface ILoginState{
        state:MSLoginState;
        otpRegistrationUrl:string;
        totpStepIsRequired: boolean;
    }

const SamlLogin = function (params: ILoginSamProps) {

    const { search } = useLocation();
    const queryParameters = new URLSearchParams(search)
    const accessToken = queryParameters.get("accessToken");
    const errorMessage = queryParameters.get("error");
    const [localErrorMessage, setLocalErrorMessage] = useState<string>("");

    const [localState, setLocalState] = useState<ILoginState>({
        totpStepIsRequired : true,
        otpRegistrationUrl : "",
        state : 'IDLE'
    });

    const { i18n, t } = useTranslation('login');

    const loginService = useMemo(() => { 
        return customLoginService.Create(Context.APIUrl + "/api/v1",  { setLoginState : params.setLoginState } , params.authenticationState, i18n.language );},
     [i18n.language]);    

    const service = useMemo(() => {
        if(!params.authenticationState)
            return;
        return samlLoginService(Context.APIUrl, params, params.authenticationState );
    },[accessToken]);

    useEffect(() => {
        if(!errorMessage)
            return;
        params.addAlert({
            AlertType: "Error",
            Message: errorMessage 
        });
    }, [errorMessage]);

    useEffect(() => {
        if(accessToken){

            setLocalState({
                ...localState,
                state: "ACQUIRING_NATIVE_TOKEN"
            });

            loginService
                .AcquireNativeTokenBySamlToken(accessToken)
                .then(response =>{
                    if(response.totpIsNeeded){
                        setLocalState({
                            state: "AWAITING_CODE_LOGIN",
                            otpRegistrationUrl : response.totpURL,
                            totpStepIsRequired : true
                        });
                    }else{
                        setLocalState({
                            state: "DOING_FINAL_LOGIN",
                            otpRegistrationUrl : response.totpURL,
                            totpStepIsRequired : false
                        })
                    }
            }).catch((Error:{errorCode:string, errorMessage:string }) => {
                if(Error.errorCode === "403"){
                    setLocalErrorMessage("Inloggningsfel: Hittade ingen användare med din epostadress. Var vänlig kontakta systemägaren i din organisation för att lösa detta.")
                }
            });
        }
    },[accessToken, localState.totpStepIsRequired])

    const isLoggingIn = localState.state == "ACQUIRING_NATIVE_TOKEN" || localState.state == 'DOING_FINAL_LOGIN';

    return (
        localState.state == 'AWAITING_CODE_LOGIN' ? 
            <div className="login-container center">
                {errorMessage && <Alert key="danger" variant="danger" className="alert-danger">{errorMessage}</Alert>}
                {isLoggingIn && !errorMessage && <h1 className="login-indicator-container"><Spinner className="login-indicator"></Spinner>{t('LOGGING_IN_MS')}...</h1>}
                {<div className="col-md-6 offset-md-3">
                    <LoginByTOTP qrRegistrationUrl={localState.otpRegistrationUrl} isSubmitting={isLoggingIn} onSubmit={(code:string) => {  
                        loginService && loginService.VerifyByTOTP("",  code);
                    }}/>
                </div>}
            </div>
        : <>
            {localErrorMessage && <Alert key="danger" variant="danger" className="alert-danger">{localErrorMessage}</Alert>}
        </>    
    )

};

export default connect(
    (appState: ApplicationState) => (
        { authenticationState: appState.authentication }),
    { ...AuthenticationStore.actions, ...AlertStore.actionCreators } 
)(SamlLogin);