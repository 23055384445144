import * as React from "react";
import { useState } from "react";
import { Alert, FormGroup } from "reactstrap";
import { ICustomLoginService, UserRegistrationState } from "../../../services/customLoginService";

import './CustomLogin.css';
import { useLocation } from 'react-router-dom'

import SetPassword from "./SetPassword";
import { useTranslation, Trans } from "react-i18next";
import FetchLimitedInfo from "./FetchLimitedInfo";
import OneUseCodeLogin from "./OneUseCodeLogin";
import PasswordLogin from "./PasswordLogin";
import { AuthError } from "@azure/msal-common/dist/error/AuthError";



export interface ICustomLoginProps{
    loginService:ICustomLoginService;
    onOldLogin:() => void;
}

interface ILoginParams {
    resetPasswordToken?: string;

}

const CustomLogin = (props:ICustomLoginProps) => {
    const { search } = useLocation();

    const { t } = useTranslation('login');
    
    const resetPasswordToken = React.useMemo(() => {
        var params = new URLSearchParams(search);
        return params.get("resetPasswordToken");
    }, [search]);
    
    const verificationStatus = React.useMemo(() => {
        var params = new URLSearchParams(search);
        return params.get("verificationStatus");
    }, [search]);
    
    const [limitedUserInformation, setLimitedUserInformation] = useState<{state:UserRegistrationState, authSetupKey?:string}>({state : UserRegistrationState.Unknown, authSetupKey : "" });
    const currentUserState = limitedUserInformation.state;

    const [result, _setResult] = useState(verificationStatus && verificationStatus === "ok" ? "STATUS_MESSAGE_VERIFICATION_SUCCEEDED" : ""); 
    const [error, _setError] = useState(verificationStatus && verificationStatus === "failed" ? "STATUS_MESSAGE_VERIFICATION_FAILED" : ""); 
    const [email,setEmail] = useState("");

    const setErrorMessage = (message: string) => {
        _setResult("");
        _setError(message);
    };

    const setStatusMessage = (message: string) => {
        _setResult(message);
        _setError("");
    };
    
    const clearMessages = () => {
        _setResult("");
        _setError("");
    };

    const [showResetPasswordDialog,setShowResetPasswordDialog] = useState(!!resetPasswordToken);

    const onRequestNewVerificationToken = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setStatusMessage("STATUS_MESSAGE_SENDING_NEW_VALIDATION_LINK");
        return props.loginService.RequestNewVerificationToken(email).then(() => {
            setStatusMessage("STATUS_MESSAGE_NEW_VALIDATION_LINK_WAS_SENT");
        }, () => {
            setErrorMessage("STATUS_MESSAGE_NEW_VALIDATION_LINK_COULD_NOT_BE_SENT")
        });
    };

    const onOldLogin = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onOldLogin()
    };

    const onRequestResetPasswordToken = () => {
        setStatusMessage("STATUS_MESSAGE_SENDING_PASSWORD_RESET_TOKEN");
        return props.loginService.RequestResetPasswordToken(email).then(() => {
            setStatusMessage("STATUS_MESSAGE_PASSWORD_RESET_TOKEN_WAS_SENT");
        }, (error:AuthError) => {
            setErrorMessage("STATUS_MESSAGE_COULD_NOT_SEND_PASSWORD_RESET_TOKEN")
        });
    };

    const onPasswordReset = (_email: string, _password: string) => {
        return new Promise<void>((resolve, reject) => {
            setStatusMessage(t('STATUS_MESSAGE_SETTING_PASSWORD'));
            if (!resetPasswordToken) {
                reject();
                return;
            }
            props.loginService.ResetPassword(_email, _password, resetPasswordToken).then((limitedUserInfo) => {
                setLimitedUserInformation(limitedUserInfo);
                setStatusMessage(t('STATUS_MESSAGE_PASSWORD_WAS_SET'));
                setShowResetPasswordDialog(false);
                resolve();
            }, () => {
                setErrorMessage(t('STATUS_MESSAGE_COULD_NOT_SET_PASSWORD'));
                reject()
            }).catch(() => {
                setErrorMessage(t('STATUS_MESSAGE_COULD_NOT_SET_PASSWORD'));
                reject();
            });

        });
    };

    const onFetchUserInfo = (_email: string) => {
        clearMessages();
        return props.loginService.GetLimitedUserInformation(_email).then((limitedUserInfo) => {
            setLimitedUserInformation(limitedUserInfo);
            if (limitedUserInfo.state === UserRegistrationState.Unregistered)
                setStatusMessage("STATUS_MESSAGE_FIRST_LOGIN");
            if (limitedUserInfo.state === UserRegistrationState.Unverified)
                setErrorMessage("STATUS_MESSAGE_UNVERIFIED");
        }, (error: AuthError) => {
            if(error.errorCode === "404"){
                setErrorMessage("STATUS_MESSAGE_USER_NOT_IN_J4");
            }else{
                setErrorMessage("STATUS_MESSAGE_UNEXPECTED_ERROR");
            }
        });
    };

    const onPasswordSet = (_email: string, _password: string) => {
        clearMessages();
        return props.loginService.RegisterNewUser(_email, _password).then((limitedUserInfo) => {
            setLimitedUserInformation(limitedUserInfo);
            setStatusMessage("STATUS_MESSAGE_REGISTRATION_SUCCEDED");
        }, (error:AuthError) => {
            if(error.errorCode === "404")
                setErrorMessage("STATUS_MESSAGE_USER_NOT_IN_J4");
            else
                setErrorMessage("STATUS_MESSAGE_REGISTRATION_FAILED");
        });
    };

    const onPasswordLogin = (_email: string, _password: string) => {
        clearMessages();
        return props.loginService.Login(_email, _password).then((limitedUserInfo) => {
            if (limitedUserInfo.state === UserRegistrationState.Unverified){
                setErrorMessage("STATUS_MESSAGE_UNVERIFIED");
            }
            setLimitedUserInformation({ state : limitedUserInfo.state, authSetupKey : limitedUserInfo.totpURL});
        }, (error:AuthError) => {
            if(error.errorCode === "401"){
                setErrorMessage("STATUS_MESSAGE_COULD_NOT_SEND_ONE_TIME_CODE")
            }
            else{
                if(error.errorMessage.indexOf("Value cannot be null. (Parameter 'providedPassword')") > -1)
                    throw "Old client error";
                setErrorMessage("STATUS_MESSAGE_UNEXPECTED_ERROR");
            }
        });
    };

    const onOneUseCodeLogin = (_email:string, _code:string ) => {
        clearMessages();
        return props.loginService.VerifyByTOTP(_email, _code).then(() => {
        }, (error:AuthError) => {
            if( error.errorCode === "401" ||
                error.errorCode === "404" ||
                error.errorCode === "400" ) 
            {
                setErrorMessage("CODE_LOGIN_ERROR_" + error.errorCode);
            }else{
                setErrorMessage("CODE_LOGIN_ERROR_-1");
            }
        });
    };

    const onRestart = () => {
        setLimitedUserInformation({"state" : UserRegistrationState.Unknown});
        clearMessages();
    };

    const getFormBasedOnState = () => {

        if (showResetPasswordDialog)
            return <>        
                <h4>{t('HEADER_RESET_PASSWORD')}</h4>
                <SetPassword onSubmit={onPasswordReset} onEmailChange={setEmail} submitButtonLabel={t('SUBMIT_BUTTON_RESET_PASSWORD')} submitButtonActiveLabel={t('SUBMIT_BUTTON_RESETTING_PASSWORD')} />
            </>;

        switch (currentUserState) {
            case UserRegistrationState.Unknown:
                return <FetchLimitedInfo onSubmit={onFetchUserInfo} onEmailChange={setEmail} />
            case UserRegistrationState.Unregistered:
                return <SetPassword onSubmit={onPasswordSet} defaultEmail={email} onRestart={onRestart} submitButtonLabel={t('SUBMIT_BUTTON_CREATE_ACCOUNT')} submitButtonActiveLabel={t('SUBMIT_BUTTON_CREATING_ACCOUNT')} />
            case UserRegistrationState.Unverified:
                break;
            case UserRegistrationState.Verified:
                return <>
                    <PasswordLogin onSubmit={onPasswordLogin} onEmailChange={setEmail} defaultEmail={email} onRestart={onRestart} onRequestResetPasswordToken={onRequestResetPasswordToken}/>
                </>
            case UserRegistrationState.AwaitingOneTimeCode:
                return <OneUseCodeLogin onSubmit={onOneUseCodeLogin} onEmailChange={setEmail} defaultEmail={email} onRestart={onRestart} qrRegistrationUrl={limitedUserInformation.authSetupKey}/>
        };
    };

    return <FormGroup>
        {result && <Alert key="success" variant="success">{t(result)}</Alert>}
        {error && <Alert key="danger" variant="danger" className="alert-danger"><Trans i18nKey={error} ns="login"><a href="#requestNewVerificationToken" className="requestlink" onClick={onRequestNewVerificationToken}>Request new verification link</a></Trans></Alert>}    
        {currentUserState !== UserRegistrationState.Unverified && <div className="customLoginContainer">
            {getFormBasedOnState()}
        </div>}
        {(currentUserState === UserRegistrationState.Unknown || currentUserState === UserRegistrationState.Unregistered) && <div>
            <p className="login-info-section">
                <img src="/assets/images/mslogo.svg" alt="Microsoft logo" className="mslogo" /><Trans i18nKey="INFO_OLD_LOGIN" ns="login"><a href="#loginWithMS" onClick={onOldLogin}>Login with microsoft account</a></Trans>
            </p>
            <p className="login-info-section">
                <img src="/assets/images/avologo.png" alt="Avonova logo" className="avologo" /><Trans i18nKey="INFO_AVONOVA_STAFF" ns="login"><a href="#loginWithMS" onClick={onOldLogin}>Login with microsoft account</a></Trans>
            </p>
            {/* <p className="login-info-section">
                <Trans i18nKey="LINK_TO_FAQ" ns="login"><NavLink tag={RRD.NavLink} to="/login/help" /><a href={t('GETTING_STARTED_URL')}></a><br /></Trans>
            </p> */}
        </div>}
    </FormGroup>
}

export default CustomLogin;