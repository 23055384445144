import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './controls/NavMenu';
// import Footer from './controls/Footer';
export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <div className="layout">
            <div className="layout-main">
                <NavMenu/>
                <Container fluid={true}>
                    {props.children}
                </Container>
            </div>
            {/* <div className="layout-footer">
                <Footer />
            </div> */}
        </div>
    </React.Fragment>
);
