import * as React from 'react';
import { connect } from 'react-redux';
import './Safetycheck.css'
import { useTranslation, Trans } from 'react-i18next';

const Safetycheck = () => {
  
  const { t } = useTranslation('checklist');

  return (
  <div>
    <div className="container image-container">
      <div className="row limited">
        <img className="col-12" src="/assets/images/hero-safetycheck.jpg" alt=""/>
      </div>
    </div>
    <div className="container mt-4">
        <div className="row main-content limited">
          <div className="col-12 col-lg-8 ingress px-4">
            <p>{t('INFO')}</p>
            <p><Trans i18nKey="TEXT" ns="checklist">Vi på Avonova kan också rekommendera att du gör ett besök på <a href="https://www.prevent.se/checklistor" rel="noopener noreferrer" target="_blank">PREVENT's egna sajt</a>. Där kan du bland annat hitta fler checklistor och appar.</Trans></p>
          </div>
          <div className="col-12 col-lg-4 px-4 checklist">
                <a rel="noopener noreferrer" target="_blank" href="https://checklists.prevent.se/checklist/answer/90" className="pill-primary link">
                  <i className="fa fa-chevron-right"></i> {t('CHECKLIST_LINK_1')}
                </a>                
             
                <a rel="noopener noreferrer" target="_blank" href="https://checklists.prevent.se/checklist/answer/100" className="pill-primary link">
                  <i className="fa fa-chevron-right"></i> {t('CHECKLIST_LINK_2')}
                </a>
               
                <a rel="noopener noreferrer" target="_blank" href="https://checklists.prevent.se/checklist/answer/182" className="pill-primary link">
                  <i className="fa fa-chevron-right"></i> {t('CHECKLIST_LINK_3')}
                </a>
          </div>
        </div>
      </div>
  </div>
  )
}

export default connect()(Safetycheck);
