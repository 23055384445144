import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ICustomerReference } from '../models/User';
import { ITokenProvider } from './authenticationService';
// import { object, string, StructError, validate } from 'superstruct';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.locale("sv");

export interface IAEHierarchyItem{
    children: IAEHierarchyItem[];
    default: boolean;
    id: string;
    name: string;
    parentId: string
}

export interface IHierarchyItem {
    children: IHierarchyItem[];
    default: boolean;
    id: string;
    name: string;
    parentId: string
}

export interface IUserService {
    GetAEHierarchy: (customerId: string) => Promise<IAEHierarchyItem[]>;
    GetRelatedCustomerHierarchy: (customerId: string) => Promise<IHierarchyItem[]>;
    GetCustomerReference: (customerId: string) => Promise<ICustomerReference>;
    SearchForCustomersReference: (searchString: string, includeHidden:boolean) => Promise<ICustomerReference[]>;
    // validateOrder : (order:IOrder) => StructError|null;
}

const userServiceFactory: (ApiHostBaseUrl: string, authenticationService: ITokenProvider) => IUserService = (ApiHostBaseUrl: string, authenticationService: ITokenProvider) => {
    return {
        GetCustomerReference : (customerId: string ) => {
            return authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                var url: string = ApiHostBaseUrl + '/CustomerPortal/GetCustomerReference?CustomerId=' + customerId;
                return fetch(url, {
                    method: 'GET',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                });
            })
            .then((data: Response) => {
                return data.json() as Promise<ICustomerReference>;
            });
        },
        SearchForCustomersReference: (searchString: string, includeHidden:boolean) => {
            return authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                var url: string = ApiHostBaseUrl + '/CustomerPortal/SearchForCustomers?searchString=' + searchString + '&includeHidden=' + (includeHidden ? "true" : "false");
                return fetch(url, {
                    method: 'GET',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                });
            })
            .then((data: Response) => {
                return data.json() as  Promise<ICustomerReference[]>;
            });
        },
        GetAEHierarchy: (customerId: string) => {
            var url: string = ApiHostBaseUrl + '/CustomerPortal/GetAEHierarchy?topCustomerId=' + customerId;
            return authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                return fetch(url, {
                    method: 'GET',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                }); 
            })
            .then((data: Response) => {
                return data.json() as Promise<IAEHierarchyItem[]>;
            });
        },  
        GetRelatedCustomerHierarchy: (customerId: string) => {
            return authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                var url: string = ApiHostBaseUrl + '/CustomerPortal/GetCustomerHierarchy?topCustomerId=' + customerId;
                return fetch(url, {
                    method: 'GET',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                });
            })
            .then((data: Response) => {
                return data.json() as Promise<IHierarchyItem[]>;
            });
        }
    }
};

export default userServiceFactory;