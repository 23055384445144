import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as HttpErrors from '../services/httpErrors' ;

import { ITokenProvider } from './authenticationService';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.locale("sv");

export interface IFileAuthor{
    displayName:string;
    email:string;
}

export interface IFile {
    author: IFileAuthor;
    created: Date;
    title: string;
    company: string;
    downloadLink: string;
    deleteLink:string;
}

export interface IFilesService {
    loadFiles: (customerId: string) => Promise<IFile[]>;
    getFileContent: (href: string) => Promise<Blob>;
    uploadFiles: (customerId: string, formData: FormData) => Promise<IFile[]>;
    getContactImage: (identifier: string) => Promise<Blob>;
    deleteFile: (identifier:string) => Promise<void>;
}

export const filesServiceFactory: (ApiHostBaseUrl: string, tokenProvider: ITokenProvider) => IFilesService = (ApiHostBaseUrl: string, tokenProvider: ITokenProvider) => {

    return {
        loadFiles: (customerId: string) => {
            return tokenProvider.getOrRenewNativeToken().then((accessToken:string) => {
                return fetch(ApiHostBaseUrl + '/customerPortal/getFiles?customerId=' + customerId, {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                })
            })
            .then((data: Response) => {
                if(!data.ok){
                    if(data.status === 401){
                        // Not logged in
                        throw HttpErrors.UNAUTHORIZED;
                    }
                    if (data.status === 403) {
                        // Not logged in
                        throw HttpErrors.FORBIDDEN;
                    }
                }
                return data.json() as Promise<IFile[]>;
            });
        },
        uploadFiles : (customerId:string, formData:FormData) => {
            return tokenProvider.getOrRenewNativeToken().then((accessToken: string) => {
                return fetch(ApiHostBaseUrl + '/customerPortal/uploadFiles?customerId=' + customerId, {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Authorization': 'bearer ' + accessToken
                    },
                    body : formData,
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                })
            })
            .then((data: Response) => {
                return data.json()  as Promise<IFile[]>;
            });
        },
        deleteFile: (href:string) => {
            return tokenProvider.getOrRenewNativeToken().then((accessToken: string) => {
                return fetch(href, {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                })
            })
            .then((data: Response) => {
                if (!data.ok) {
                    if (data.status === 401) {
                        // Not logged in
                        throw HttpErrors.UNAUTHORIZED;
                    }
                    if (data.status === 403) {
                        // Not logged in
                        throw HttpErrors.FORBIDDEN;
                    }
                }
            });
        },
        getFileContent: (href: string) => {
            return tokenProvider.getOrRenewNativeToken().then((accessToken: string) => {
                return fetch(href, {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                });
            }).then((response) => {
                return response.blob() as Promise<Blob>;
            }).catch((error: any) => {
                console.warn("Could not fetch user details");
                throw error;
            });
        },
        getContactImage: (identifier:string) => {
            return tokenProvider.getOrRenewNativeToken().then((accessToken: string) => {
                var href = ApiHostBaseUrl + '/customerPortal/GetContactImage?identifier=' + identifier
                return fetch(href, {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                })
            }).then((response) => {
                return response.blob() as Promise<Blob>
            }).catch((error: any) => {
                console.warn("Could not fetch user details");
                throw error;
            });
        }
    }
};