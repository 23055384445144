
import { FieldPath,  FieldValues } from "react-hook-form";

export interface SortInstruction<TItem extends FieldValues>{
    value:FieldPath<TItem> | ((item: TItem) => any);
    asc:boolean;
}

export interface ISortState<TItem extends FieldValues> {
    sortedColumn: string;
    sortInstruction: SortInstruction<TItem>;
    sortedItems:TItem[];
}

export interface ISortResult<TItem extends FieldValues> {
    newSortState: ISortState<TItem>;
    sortedItems: SortInstruction<TItem>;
}

export function SortService<TItem extends FieldValues>() {

    function sortItems(items: TItem[], sortInstruction: SortInstruction<TItem>){
        
        let getValue: (item: TItem) => any;
        
        if (typeof sortInstruction.value !== "function") {
            getValue = (item: TItem) => {
                var fieldName = sortInstruction.value as FieldPath<TItem>;
                var _item = item as any;
                return _item[fieldName];
            }
        }else{
            getValue = sortInstruction.value as ((item: TItem)=>any);
        }
        
        var _items = items.sort((itemA: TItem, itemB: TItem)=> { 
            var _a = getValue(itemA);
            var _b = getValue(itemB);
            
            if( _a > _b){
                return sortInstruction.asc ? 1 : -1;   
            }
            if (_a < _b){
                return sortInstruction.asc ? -1 : 1;
            }
            return 0;
        });
        return _items;
    }
    
    return{
        sortItems:sortItems
    };
}