import * as React from "react";
import { useMemo, useState } from "react";
import { Button, Input, Label, Spinner } from "reactstrap";
import './CustomLogin.css';
import { createChangeHandler, CreateEnterCallback } from "../../../services/formUtils";
import { useTranslation } from 'react-i18next';
import SubmitButton from "./SubmitButton";

export interface ISetPasswordProps{
    onSubmit : (email:string, password:string) => Promise<void>;
    defaultEmail?:string;
    onEmailChange?:(newValue:string) => void;
    onRestart?:()=>void;
    submitButtonLabel:string;
    submitButtonActiveLabel:string;
}

const SetPassword = (props: ISetPasswordProps) => {

    const { t, i18n } = useTranslation('login');

    const hasCapitalLetters: RegExp = /[A-ZÅÄÖ]/;
    const hasSmallLetters: RegExp = /[a-zåäö]/;
    const hasDigits: RegExp = /[0-9]/;

    const [email,setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [showVisualCueForPassword, setShowVisualCueForPassword] = useState(false);
    const [showVisualCueForPasswordRepeat, setShowVisualCueForPasswordRepeat] = useState(false);
    const [isSubmitting,setIsSubmitting] = useState(false);

    React.useEffect(() => {
        if (email === "" && props.defaultEmail !== email && props.defaultEmail) {
            setEmail(props.defaultEmail);
        }
    }, [props.defaultEmail]);

    const onSubmit = () => {
        if(!canBeSubmitted)
            return;
        setIsSubmitting(true);
        props.onSubmit(email,password).then(() => {
            setIsSubmitting(false);
        },() => {
            setIsSubmitting(false);
        }).catch(() => {
            setIsSubmitting(false);
        });
    }

    React.useEffect(() => {
        props.onEmailChange && props.onEmailChange(email);
    },[email]);

    const passwordIsValid = useMemo(() => {
        return password.length >=12
            && hasDigits.test(password)
            && hasCapitalLetters.test(password)
            && hasSmallLetters.test(password)
    }, [password]);

    const passwordMatches = password === repeatPassword;
    const passwordIsNotEmpty = password.length > 0;

    const canBeSubmitted = useMemo(() => {
        return passwordIsValid && passwordMatches && !isSubmitting;
    }, [isSubmitting, passwordIsValid, passwordMatches]);

    const updateVisualCueStatusForPassword = () => {
        setShowVisualCueForPassword(passwordIsNotEmpty && !passwordIsValid);
    }

    const updateVisualCueStatusForPasswordRepeat = () => {
        setShowVisualCueForPasswordRepeat(passwordIsNotEmpty && !passwordMatches);
    }

    return <>
        <Label>{t('LABEL_EMAIL')} <Input placeholder={t("PROMPT_FOR_EMAIL")} value={email} type="text" onChange={createChangeHandler(setEmail)} disabled={!props.onEmailChange} /></Label>
        {showVisualCueForPassword && <p>{t('PASSWORD_FORMAT_INFO')}</p>}
        <Label>{t('LABEL_PASSWORD')} <Input placeholder={t("PROMPT_FOR_NEW_PASSWORD")} type="password" value={password} onChange={createChangeHandler(setPassword)} onBlur={() => updateVisualCueStatusForPassword()}/></Label>
        {showVisualCueForPasswordRepeat && <p>{t('PASSWORD_REPEAT_MISSMATCH_INFO')}</p>}
        <Label>{t('LABEL_REPEAT_PASSWORD')} <Input placeholder={t("PROMPT_FOR_PASSWORD_REPEAT")} type="password" value={repeatPassword} onChange={createChangeHandler(setRepeatPassword)} onBlur={() => updateVisualCueStatusForPasswordRepeat()} onKeyPress={CreateEnterCallback(() => onSubmit())}  /></Label>
        <span className="buttons">
            {props.onRestart && <Button onClick={props.onRestart}>{t('SUBMIT_BUTTON_RESTART')}</Button>}
            <SubmitButton submitting={isSubmitting ? true : undefined} disabled={!canBeSubmitted} label={props.submitButtonLabel} activelabel={props.submitButtonActiveLabel} className="btn-primary" onClick={onSubmit} />
        </span>
    </>
}
export default SetPassword;