import * as React from "react";
import { useMemo, useState } from "react";
import { Button, Input, Label} from "reactstrap";
import './Login.css';
import { createChangeHandler, CreateEnterCallback } from "../../../services/formUtils";
import { useTranslation, Trans, Translation } from 'react-i18next';
import SubmitButton from '../../controls/CustomLogin/SubmitButton';
import QRCode from "react-qr-code";

export interface ILoginByTOTPProps{
    onSubmit : (oneUseCode:string) => void;
    onRestart?: () => void;
    qrRegistrationUrl?:string,
    isSubmitting:boolean
}

const LoginByTOTP = (props: ILoginByTOTPProps) => {

    const { t, i18n } = useTranslation('login');
    const {onSubmit,  isSubmitting} = props;

    const [password, setPassword] = useState("");

    const canBeSubmitted = useMemo(() => {
        return password.length == 6 && !isSubmitting;
    }, [isSubmitting, password]);

    const code = props.qrRegistrationUrl ? props.qrRegistrationUrl.split("secret=")[1].split("&")[0] : undefined;

    return <>   
       { props.qrRegistrationUrl ? <>
        <h3 style={{marginTop: "0em"}}>{t('NEW_AUTHENTICATION_METHOD')}</h3>
        <p style={{paddingLeft:"0", paddingRight: "0"}}>{t('REASON_FOR_AUTHENTICATOR')}</p>
        <div style={{"display": "flex", flexDirection : "row"}}>
            <div style={{"display" : "block", "marginBottom" : "1em"}}>
                <Trans i18nKey="AUTHENTICATOR_REGISTRATION_INSTRUCTIONS" ns="login" values={{code : code}}>
                    <div>
                        <ol>
                            <li></li>
                            <li><br/><code></code></li>
                            <li></li>
                        </ol>
                    </div>
                </Trans>
                <Trans i18nKey="AUTHENTICATOR_REGISTRATION_LINKS" ns="login">
                <a href="https://avonovawpstatic.blob.core.windows.net/public/Instruktion%20f%C3%B6r%20registrering%20av%20MFA%20Android.pdf">Så här installerar du Microsoft Authenticator på din Andriod-enhet.</a>
                    <br/>
                    <a href="https://avonovawpstatic.blob.core.windows.net/public/Instruktion%20f%C3%B6r%20registrering%20av%20MFA%20iPhone.pdf">Så här installerar du Microsoft Authenticator på din IOS-enhet.</a>
                </Trans>
            </div>
            <div style={{"display" : "block"}}>
                <QRCode
                    size={256}
                    style={{ height: "256", width: "256", "padding" : "1rem" }}
                    value={ props.qrRegistrationUrl}
                    viewBox={`0 0 256 256`}
                    />
            </div>
        </div>  
        </> :  <h3 style={{marginTop: "0em"}}>{t('TOTP')}</h3>}
        <Label>{t('LABEL_ONE_USE_CODE')} <Input placeholder={t("PROMPT_FOR_ONE_USE_CODE")} type="text" value={password} onChange={createChangeHandler(setPassword)} onKeyUp={CreateEnterCallback(() => onSubmit(password))}/></Label>
        <span className="buttons">
            {props.onRestart && <Button onClick={props.onRestart}>{t('SUBMIT_BUTTON_RESTART')}</Button>}
            <SubmitButton 
                submitting={isSubmitting ? true : undefined} 
                disabled={!canBeSubmitted} 
                className="btn-primary" 
                activelabel={t('SUBMIT_BUTTON_SUBMITTING')} 
                label={t('SUBMIT_BUTTON_FINALIZE')} 
                onClick={() => onSubmit(password)} />
        </span>
    </>
}
export default LoginByTOTP;