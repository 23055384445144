import i18n from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: "sv",
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'//  + (sessionStorage.getItem("AVO-AppRevisionVersion") ? "?revision=" + sessionStorage.getItem("AVO-AppRevisionVersion") : "")
        }, 
        react: { useSuspense: false },
        supportedLngs: ["en","sv", "no"],
        load:'all',
        preload:['en', 'sv'],
        defaultNS : "home",
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain']
        }
    });
export default i18n;