import * as React from 'react';
import { Table } from 'reactstrap';

import './InvoiceFiles.css';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { IInvoiceFile } from '../pages/Invoices';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { ISortState, SortService } from '../../services/sortService';
import { FieldPath } from 'react-hook-form';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.locale("sv");

export type IInvoiceFilesProps = {
    items?: IInvoiceFile[],
    onFileClick?: (url:string, filename:string) =>void
}


// export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean, expanded :boolean }> {

const InvoiceFiles = function ({ items, onFileClick }: IInvoiceFilesProps) {
   
    const sortService = React.useMemo(() => SortService<IInvoiceFile>(), []);

    const { t } = useTranslation('invoices');

    const onDownloadClick = (event:React.MouseEvent<HTMLAnchorElement,MouseEvent>) => {
        event.preventDefault();
        const target = event.currentTarget as HTMLAnchorElement;
        if(!target){
            alert("target was empty");
            console.debug(target); 
        }
        if(onFileClick){
            onFileClick(target.href, target.innerText);
        }
    } ;

    const [localState, setLocalState] = useState<ISortState<IInvoiceFile>>({
        sortedItems: !!items ? items : [],
        sortInstruction: { value: "invoiceDate", asc: false },
        sortedColumn: "orderNumber"
    });

    React.useEffect(() => {
        const stateCopy = { ...localState };
        stateCopy.sortedItems = !!items ? sortService.sortItems(items, stateCopy.sortInstruction) : []
        console.log("items, sort service or local state has changed");
        setLocalState(stateCopy);
    }, [items
        , sortService
        // , localState
    ]);

    const onHeaderClick = (columnName: string, value: FieldPath<IInvoiceFile> | ((item: IInvoiceFile) => any)) => {
        const stateCopy = { ...localState };
        stateCopy.sortInstruction = { value: value, asc: stateCopy.sortedColumn === columnName ? !stateCopy.sortInstruction.asc : true };
        stateCopy.sortedItems = !!items ? sortService.sortItems(items, stateCopy.sortInstruction) : []
        stateCopy.sortedColumn = columnName;
        setLocalState(stateCopy);
    }

    const getClass: (columnName: string) => string = (columnName: string) => {
        return "sortable" + (localState.sortedColumn === columnName ? " sorted" + (localState.sortInstruction.asc ? " asc" : " desc") : "")
    }

    return (
        <div className="row">
            <div className="col fullwidth-table-container collapsable-table-md">
                <Table hover className="fullwidth-table invoice-file-table" striped={true}>
                    <thead>
                        <tr>
                            <th className={getClass("description")} onClick={() => onHeaderClick("description", "description")}>{t('INVOICE_DESCRIPTION')}</th>
                            <th className={getClass("category")} onClick={() => onHeaderClick("category", "category")}>{t('INVOICE_CATEGORY')}</th>
                            <th className={getClass("invoiceDate")} onClick={() => onHeaderClick("invoiceDate", "invoiceDate")}>{t('INVOICE_DATE')}</th>
                            <th className={getClass("company")} onClick={() => onHeaderClick("company", "company")}>{t('INVOICE_UNIT')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {localState.sortedItems && localState.sortedItems.map((i: IInvoiceFile) => <tr key={i.invoiceId}>
                            <td><a onClick={onDownloadClick} href={i.downloadLink}>{i.description}</a></td>
                            <td><span className="collapsed-title">{t('INVOICE_CATEGORY')}: </span>{i.category}</td>
                            <td><span className="collapsed-title">{t('INVOICE_DATE')}: </span>{dayjs(i.invoiceDate).format("YYYY-MM-DD")}</td>
                            <td><span className="collapsed-title">{t('INVOICE_UNIT')}: </span>{i.company}</td>
                            </tr>)}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default connect((appState: ApplicationState) => (
    { alert: appState.alert }))(InvoiceFiles);