import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AccountInfo, AuthenticationResult, AuthError, BrowserAuthError, BrowserAuthErrorMessage, PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { IUserInRole } from '../components/pages/Login';
import { IAuthenticationActionCreators, UserState} from '../store/Authentication';
import * as Authentication from '../store/Authentication'
import * as AuthenticationStore from '../store/Authentication'
import jwt_decode from "jwt-decode";
import { loginScopes, msalConfig } from '../configuration/authConfig';
import { access } from 'fs';
import { LoginTokensDTO, UserRegistrationState } from './customLoginService';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.locale("sv");

export interface IError {
    errorMessage:string;
    errorCode:string;
}

export interface IRefreshedAccessTokenResult{
    accessToken:string;
    expirationDate:Date;
}

export interface IAuthenticationService{
    loginUser: (loginScopes: string[], useRedirectFlow: boolean) => Promise<string>;
    logoutUser: () => Promise<void>;
    cancel: () => void;
    getCurrentlyLoggedInUsers: () => AccountInfo[] | null;
    acquireNativeToken:(msAccessToken?:string) => Promise<LoginTokensDTO>;
    verifyByTOTP: (code:string) => Promise<LoginTokensDTO>;
    fetchUserDetails: (nativeAccessToken: string, selectedCustomer?: string) => Promise<IUserInRole>
    getOrRenewNativeToken: () => Promise<string>
    continueAsUser: (account: AccountInfo, useRedirectFlow: boolean) => Promise<string>;    
};

export interface ITokenProvider {
    getOrRenewNativeToken(): Promise<string>; 
}

var refreshTokenPromise:Promise<string>|null;
const fetchUserPromise = new Map<string, Promise<IUserInRole>>();

export const authenticationServiceFactory = (APIBaseUrl: String, actionCreators:IAuthenticationActionCreators, currentUserState: UserState | undefined ) => {

    const msalApp = new PublicClientApplication(msalConfig);

    var isCanceled:boolean;
    
    function getCurrentlyLoggedInUsers(){
            return msalApp.getAllAccounts();
    }
    function acquireNativeToken(msAccessToken: string){
        
        return fetch(APIBaseUrl + "/api/v3/AccessToken/Sharepoint", {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + msAccessToken
            },
            redirect: 'error',
            referrerPolicy: 'no-referrer'
        })
        .then(ThrowAuthErrorOnErrorResponse)
        .then((response:Response) => response.json())
        .then((response:LoginTokensDTO) => {
            let nativeAccessToken = response.accessToken;
            let nativeRefreshToken = response.refreshToken;
            var tokenData: any = jwt_decode(nativeAccessToken);
            var refreshTokenData: any = jwt_decode(nativeRefreshToken);
            
            
            var state = {
                AccessToken: nativeAccessToken,
                AccessTokenExpirationDate: new Date(tokenData["exp"] * 1000),
                RefreshToken: nativeRefreshToken,
                RefreshTokenExpirationDate: new Date(refreshTokenData["exp"] * 1000) ,
                MfaWasUsed: response.state == UserRegistrationState.Authenticated ? true:false 
            };

            actionCreators.setLoginState && actionCreators.setLoginState(state);

            if(!response.totpIsNeeded)
                fetchUserDetails(nativeAccessToken)
                .then(updateUserInRole);
            return response;
        })
        .catch((error:any) => {
            if(error.message && error.message === "Failed to fetch"){
                throw(new AuthError("-1", "Could not find server"));
            }else{
                throw({ errorCode: -1, errorMessage: "Unexpected Error" });
            }
        });
        
    }
    const ThrowAuthErrorOnErrorResponse = (response:Response) => {
        if (response.status === 400) {
            throw new AuthError("400", "Bad request");
        }
        else if (response.status === 401) {
            throw new AuthError("401", "Unauthorized")
        }
        else if (response.status === 403) {
            throw new AuthError("403", "Forbidden");
        }
        if (response.status === 404) {
            throw new AuthError("404", "Not found");
        }
        else if (response.status < 200 || response.status > 400) {
            throw new AuthError(response.status.toString(), "Server error");
        } 
        else {
            return response;
        }
    }
    function refreshNativeToken(){
        if(refreshTokenPromise == null){
            refreshTokenPromise = new Promise<string>((resolve, reject) => {
                fetch(APIBaseUrl + "/api/v2/AccessToken/Refresh", {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + currentUserState?.RefreshToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                }).then((response:Response) => {
                    refreshTokenPromise = null;
                    if(response.status >= 200 && response.status < 300){
                        response.json().then((result:IRefreshedAccessTokenResult) => {
                            var tokenData: any = jwt_decode(result.accessToken);
                            var refreshedState = {
                                AccessToken: result.accessToken,
                                AccessTokenExpirationDate: new Date(tokenData["exp"] * 1000),
                                MfaWasUsed : false
                            };
                            actionCreators.setLoginState && actionCreators.setLoginState(refreshedState);
                            resolve(result.accessToken);
                        });
                    }else{
                        if (response.status === 400) {
                            reject(new AuthError("400", "Bad request"));
                        }
                        else if(response.status === 401){
                            reject(new AuthError("401", "Unauthorized"));
                        }
                        else if (response.status === 403) {
                            reject(new AuthError("403", "Forbidden"));
                        }else{
                            reject(new AuthError(response.status.toString(), "Server error"));
                        }
                    }
                }).catch((error:any) => {
                    refreshTokenPromise = null;
                    if(error.message && error.message === "Failed to fetch"){
                        reject(new AuthError("-1", "Could not find server"));
                    }else{
                        reject({ errorCode: -1, errorMessage: "Unexpected Error" });
                    }
                });
            });
        }
        return refreshTokenPromise;
    }
    function _acquireMSToken(account: AccountInfo | null, loginScopes: string[], useRedirectFlow:boolean): Promise<string>{
        return new Promise<string>((resolve, reject) => {

            account = account || (msalApp.getAllAccounts().length > 0 ? msalApp.getAllAccounts()[0] : null);

            if (!account || account == null) {
                reject(new AuthError("no_active_account","Could not determine active account"));
            }else{
                msalApp.acquireTokenSilent({ scopes: loginScopes, account: account }).then((response: AuthenticationResult) => {
                    resolve(response.accessToken);
                }).catch((error: any) => {
                    console.debug("silent token acquisition failed, falling back to interactive method");
                    if (useRedirectFlow) {
                        return msalApp.acquireTokenRedirect({ scopes: loginScopes });
                    } else {
                        return msalApp.acquireTokenPopup({ scopes: loginScopes }).then((response: AuthenticationResult) => {
                            resolve(response.accessToken);
                        }, (error:any) =>  {
                            console.error(error);
                            reject(error);
                        });
                    }
                });
            }
        });
    }
    function loginWithMicrosoftAccount(loginScopes: string[], useRedirectFlow: boolean){
        return new Promise<string>((resolve, reject) => {
            if (useRedirectFlow) {
                msalApp.acquireTokenRedirect({ scopes: loginScopes, prompt: 'select_account' });
                resolve("foo");
            } else {
                try{
                msalApp.acquireTokenPopup({ scopes: loginScopes, prompt: 'select_account' })
                    .then((response: AuthenticationResult) => {
                        if(response.account){
                            msalApp.setActiveAccount(response.account);
                        }
                        resolve(response.accessToken);
                    })
                    .catch((error:any) => {
                        reject(error)
                    });
                }catch(exception){
                    reject(exception);
                }
            }
        });
    }    
    function fetchUserDetails(nativeAccessToken: string, selectedCustomer?:string){

        let url:string = APIBaseUrl + "/api/v1/User/GetUserInfo";
        var customer = "none";
        if (selectedCustomer){
            url += "?specificCustomerId=" + selectedCustomer
            customer = selectedCustomer;
        }

        if(fetchUserPromise.has(customer)){
            console.log("from cache");
            return fetchUserPromise.get(customer) as Promise<IUserInRole>;
        }

        var promise = new Promise<IUserInRole>((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache',
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + nativeAccessToken
                },
                redirect: 'error',
                referrerPolicy: 'no-referrer'
            }).then((response:Response) => {
                fetchUserPromise.delete(customer);
                if (response.status >= 200 && response.status < 300) {
                    response.json().then((responseAsJson: IUserInRole) => {
                        if (responseAsJson.availableCustomers) {
                            responseAsJson.availableCustomers = responseAsJson.availableCustomers.sort((a, b) => a.customerName < b.customerName ? -1 : (a.customerName > b.customerName ? 1 : 0));
                            if (responseAsJson.availableCustomers.length === 1){
                                responseAsJson.selectedCustomer = responseAsJson.availableCustomers[0];
                            }
                            else if (selectedCustomer){
                                const requestedCustomer = responseAsJson.availableCustomers.find(c => c.customerId.toString() === selectedCustomer);
                                responseAsJson.selectedCustomer = requestedCustomer;
                            }
                        }
                        resolve(responseAsJson);
                    });
                } else {
                    if (response.status === 400) {
                        reject({ errorMessage: "Bad request", errorCode: 400 })
                    }
                    else if (response.status === 401) {
                        reject({ errorMessage: "Unauthorized", errorCode: 401 })
                    }
                    else if (response.status === 403) {
                        reject({ errorMessage: "Forbidden", errorCode: 403 })
                    } else {
                        reject({ errorMessage: "Server Error", errorCode: response.status })
                    }
                }
            }).catch((error: any) => {
                fetchUserPromise.delete(customer);
                console.warn("Could not fetch user details");
                reject(error);
            });
        });
        fetchUserPromise.set(customer, promise);
        
        return promise;
        
    }
    function getOrRenewNativeToken(): Promise<string>{

        var _nativeAccessToken:string = "";
        
        return new Promise((resolve, reject) => {



            console.debug("Getting token from cache.");
            if (currentUserState && currentUserState.AccessTokenExpirationDate && currentUserState.AccessTokenExpirationDate > new Date() && currentUserState.AccessToken) {
                console.debug("Using cached access token");
                resolve(currentUserState.AccessToken);
                return;
            }
            

            if (!currentUserState 
                || !currentUserState.RefreshToken
                || !currentUserState.RefreshTokenExpirationDate 
                || currentUserState.RefreshTokenExpirationDate < new Date() ) 
            {   
                console.debug("Both cached access token and refresh token was outdated or missing.");             
                logoutUser().then(() =>{
                    throw("AccessToken_Expired");
                });
                reject();
                return;
            }

            console.debug("Cached token was outdated, using valid refresh token to refresh access token.", currentUserState.AccessTokenExpirationDate);
            refreshNativeToken()
                .then((nativeAccessToken: string) => {
                    console.debug("Access token refresh succeeded");
                     var promise = fetchUserDetails(nativeAccessToken);
                     promise.then(() => {
                        var tokenData: any = jwt_decode(nativeAccessToken);
                        actionCreators.setLoginState && actionCreators.setLoginState({
                            AccessToken: nativeAccessToken,
                            AccessTokenExpirationDate: new Date(tokenData["exp"] * 1000)
                        });
                        _nativeAccessToken = nativeAccessToken;
                        resolve(_nativeAccessToken);
                     }).catch((error: any) => {
                         console.debug("Could not fetch user details. Error: " + JSON.stringify(error), error);
                         actionCreators.setLoginState && actionCreators.setLoginState({
                             LoginState: AuthenticationStore.LoginState.LOGIN_FAILED,
                             UserInRole: undefined,
                             AccessToken: ""
                         });
                     });
                     return promise;
                })
                .then((userInRole: IUserInRole) => {
                    console.debug("New user details were fetched.", userInRole);
                    userInRole.selectedCustomer = currentUserState?.UserInRole?.selectedCustomer;
                    actionCreators.setLoginState && actionCreators.setLoginState({
                        LoginState: AuthenticationStore.LoginState.LOGGED_IN,
                        UserInRole: userInRole
                    })
                    resolve(_nativeAccessToken);
                });
        });
    }
    function loginUser(loginScopes: string[], useRedirectFlow: boolean){
        isCanceled = false;
        var promise = new Promise<string>((resolve, reject) => {
            actionCreators.setLoginState && actionCreators.setLoginState({
                LoginState: AuthenticationStore.LoginState.LOGGING_IN,
                AccessToken: "",
                AccessTokenExpirationDate: new Date(Date.parse("1980-01-01")),
                RefreshToken: "",
                RefreshTokenExpirationDate: new Date(Date.parse("1980-01-01"))
            });

            var promiseHasBeenResolved: boolean = false;
            
            setTimeout(() => {
                if (!promiseHasBeenResolved) {
                    setLoginError();
                    promiseHasBeenResolved = true;
                }
            }, 60000);

            loginWithMicrosoftAccount(loginScopes, useRedirectFlow)
                .then((accessToken:string) => {
                    promiseHasBeenResolved = true;
                    resolve(accessToken);
                }, () => reject(""));
        });
        return promise;
    }
    function verifyByTOTP(code: string): Promise<LoginTokensDTO> {
        return getOrRenewNativeToken()
            .then((nonMFAAccessToken) => {
                return fetch(APIBaseUrl + "/api/v3/CustomAuthentication/TOTP?code=" + encodeURIComponent(code), {
                    method: 'Post',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + nonMFAAccessToken,                
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                })
                .then(ThrowAuthErrorOnErrorResponse)
                .then(response => response.json() as Promise<LoginTokensDTO>)
                .then((response) => {
                    let nativeAccessToken = response.accessToken;
                    let nativeRefreshToken = response.refreshToken;
                    var tokenData: any = jwt_decode(nativeRefreshToken);
                    var refreshTokenData: any = jwt_decode(nativeAccessToken);
                    actionCreators.setLoginState && actionCreators.setLoginState({
                        AccessToken: nativeAccessToken,
                        AccessTokenExpirationDate: new Date(tokenData["exp"] * 1000),
                        RefreshToken: nativeAccessToken,
                        RefreshTokenExpirationDate: new Date(refreshTokenData["exp"] * 1000),
                        MfaWasUsed: true
                    });
                    fetchUserDetails(nativeAccessToken)
                        .then(updateUserInRole)
                    return response;
                })
                .catch((error: any) => {
                    console.debug("Could not fetch user details. Error: " + JSON.stringify(error), error);
                    actionCreators.setLoginState && actionCreators.setLoginState({
                        LoginState: AuthenticationStore.LoginState.LOGIN_FAILED
                    });
                    
                    if(error.errorCode && error.errorMessage){
                        throw error
                    }
                    if (error.message && error.message === "Failed to get info") {
                        throw error
                    } else {
                        throw new AuthError("-1", error);
                    }
                });
        });
    }   
    function updateUserInRole(userInRole: IUserInRole){
        if (userInRole.availableCustomers) {
            userInRole.availableCustomers = userInRole.availableCustomers.sort((a, b) => a.customerName < b.customerName ? -1 : (a.customerName > b.customerName ? 1 : 0));
        }
        actionCreators.setLoginState && actionCreators.setLoginState({
            LoginState: AuthenticationStore.LoginState.LOGGED_IN,
            UserInRole: userInRole
        });
    }
    function setLoginError() {
        actionCreators.setLoginState && actionCreators.setLoginState({
            LoginState: AuthenticationStore.LoginState.LOGIN_FAILED,
            UserInRole: undefined,
            AccessToken: ""
        });
    }
    function continueAsUser(account: AccountInfo, useRedirectFlow: boolean) {
        isCanceled = false;
        return new Promise<string>((resolve, reject) => {

            actionCreators.setLoginState && actionCreators.setLoginState({
                LoginState: AuthenticationStore.LoginState.LOGGING_IN,
                AccessToken: "",
                AccessTokenExpirationDate: new Date(Date.parse("1980-01-01")),
                RefreshToken: "",
                RefreshTokenExpirationDate: new Date(Date.parse("1980-01-01"))
            });
            
            var result = _acquireMSToken(account, loginScopes, useRedirectFlow);
            
            if (!useRedirectFlow) {
                result
                    .then((accessToken: string) => {                        
                        if (!isCanceled) {
                            

                            resolve(accessToken);
                        } else {
                            reject({
                                errorMessage: "CANCELLED",
                                errorCode: "-3"
                            });                        }
                        });
                    // .then(acquireNativeToken)
                    // .then(fetchUserDetails)
                    // .then((userInRole: IUserInRole) => {
                    //     updateUserInRole(userInRole);
                    //     resolve();
                    // })
                    // .catch((error: AuthError) => {
                    //     setLoginError();
                    //     reject(error);
                    // });
            }
            
        });
    }
    function logoutUser(){
        return new Promise<void>((resolve, reject) => {
            actionCreators.setLoginState && actionCreators.setLoginState({
                LoginState: AuthenticationStore.LoginState.NOT_LOGGED_IN,
                UserInRole: undefined,
                AccessToken: "",
                RefreshToken: "",
                AccessTokenExpirationDate: new Date(Date.parse("1970-01-02")),
                RefreshTokenExpirationDate: new Date(Date.parse("1970-01-02")),
                MfaWasUsed: false
            });
            resolve();
        });
    }
    function cancel(){
        isCanceled = true;
    }

    return {
        loginUser: loginUser,
        logoutUser: logoutUser,
        cancel : cancel,        
        getCurrentlyLoggedInUsers: getCurrentlyLoggedInUsers,
        //acquireMSToken: acquireMSToken,
        acquireNativeToken: acquireNativeToken,
        verifyByTOTP: verifyByTOTP,
        fetchUserDetails: fetchUserDetails,
        getOrRenewNativeToken: getOrRenewNativeToken,
        continueAsUser: continueAsUser,         
    } as IAuthenticationService
};