import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
// import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from './';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/es/storage';

export default function configureStore(initialState?: ApplicationState) {
    const middleware = [
        thunk
        //,routerMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers
        //, router: connectRouter(history)
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

   
    const persistConfig = {
        key: "root",
        storage
        // ,transforms: transforms
    };

    const persistedReducer = persistReducer(persistConfig, rootReducer);
    
    const store = createStore(
        persistedReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    persistStore(store);
    
    return store;
}