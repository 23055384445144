import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IAuthenticationService, ITokenProvider } from './authenticationService';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.locale("sv");

export interface IReport {
    id: string;
    name: string;
    webUrl: string;
    embedUrl: string;
    datasetId: string;
}

export interface IReportContext {
    email: string;
    embedUrl: string;
    report: IReport;
    accessToken: string;
    internalCustomerId:number;
}

interface IReportContextResult {
    email: string;
    embedUrl: string;
    report: IReport;
    accessToken: string;
}

export interface IStatisticsService {
    loadContext: (customerId: number) => Promise<IReportContext>;
}

export const powerBiServiceFactory = (ApiHostBaseUrl: string, authenticationService: ITokenProvider) => {

    return {
        loadContext: (customerId:string) => {
            
            var accessToken:string;
            return authenticationService
                .getOrRenewNativeToken()
                .then((_accessToken: string) => {
                    accessToken = _accessToken;
                }).then(() => {
                    return fetch(ApiHostBaseUrl + '/customerPortal/TranslateToInternalCustomerId?customerId=' + customerId, {
                        method: 'Get',
                        mode: 'cors', 
                        cache: 'no-cache',
                        credentials: 'same-origin', 
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'bearer ' + accessToken
                        },
                        redirect: 'error',
                        referrerPolicy: 'no-referrer'
                    })
                })
                .then((data: Response) => {
                    return data.json();
                })
                .then((internalIdResponse:number) => {
                    
                    var endpoint: string = internalIdResponse === -1 
                        ? ApiHostBaseUrl + '/customerPortal/GetReportContext?demo=true'
                        : ApiHostBaseUrl + '/customerPortal/GetReportContext';

                    return fetch(endpoint, {
                            method: 'POST',
                            mode: 'cors', 
                            cache: 'no-cache',
                            credentials: 'same-origin',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'bearer ' + accessToken
                            },
                            redirect: 'error',
                            referrerPolicy: 'no-referrer'
                        })
                        .then((data: Response) => {
                            return data.json();
                        })
                        .then((contextResponse: IReportContext) => {
                            return {
                                ...contextResponse,
                                internalCustomerId: internalIdResponse
                            }; 
                        });
                })
                .catch((error:any) => {
                    console.error(error);
                    throw error;
                });
        }
    }
};