import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './Home.css'
import { Alert } from 'reactstrap';
import * as AuthenticationStore from '../../store/Authentication';
import { ApplicationState } from '../../store';
import { LoginState } from '../../store/Authentication';
import { IUserInRole } from './Login';
import CustomerMovies from '../controls/CustomerMovies';

import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


export type IHomeProps = typeof AuthenticationStore.actions & {
  authenticationState?: AuthenticationStore.UserState;
}

const Home = ({ authenticationState }: IHomeProps) => {

  const { t } = useTranslation('home');

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const isLoggedIn = authenticationState ? authenticationState.LoginState === LoginState.LOGGED_IN : false;
  const customerHasBeenSelected = isLoggedIn && !!authenticationState && !!authenticationState.UserInRole && !!authenticationState.UserInRole.selectedCustomer;
  const [showMoviesItem, setShowMoviesItem] = useState(false);

  

  useEffect(() => {
    if (isLoggedIn && !customerHasBeenSelected) {
      setShowMoviesItem(false);
    }
    else {
      authenticationState?.UserInRole?.selectedCustomer &&
        setShowMoviesItem(authenticationState?.UserInRole?.selectedCustomer?.movieServiceIsAvailable);
    }
  }, [isLoggedIn, customerHasBeenSelected, authenticationState?.UserInRole?.selectedCustomer]);


  return (

    <div>
      <div className="container image-container">
        <div className="row limited">
          <Alert key="warning" variant="warning" className="alert-warning" isOpen={false}>
            <Trans i18nKey="GATHERING_INFO" ns="home">
              <p></p>
              <br />
            </Trans>
          </Alert>
          <img className="col-12" src="/assets/images/hero-start.jpg" alt="" />
          <div className=" col-4 offset-md-8 overlay col-md-hide hidden">
            <div className="overlay-content">
              <h3>{t('WIZARD_MOVING_FORWARD')}</h3>
              <p>{t('WIZARD_INFO')}</p>
              <button className="startIntroTrigger btn-primary"><i className="fa fa-play-circle-o" /> {t('WIZARD_BUTTON')}</button>
            </div>
          </div>

        </div>
      </div>
      <div className="container mt-4">
        <div className="row main-content limited">
          <div className="col-12 col-md-8 ingress px-4">
            {/*<Alert key="warning" variant="warning" className="alert-warning" isOpen={true}>{t("AFSInfo")}</Alert>*/}
            <Trans i18nKey="INFO" ns="home">
              <p></p>
            </Trans>
          </div>
          <div className="col-12 col-md-4 px-4 ">
            {showMoviesItem &&
              <div>
                <a className="pill-primary link" onClick={toggle}>
                  {!isOpen ? (
                    <i className="fa fa-chevron-right"></i>
                  ) : (
                      <i className="fa fa-chevron-down"></i>
                  )}
                  <span className="p-1">{t('PUSH_MOVIES')}</span>
                </a>
                <CustomerMovies isOpen={isOpen} />
              </div>
            }
            <a href="https://www.avonova.se/dfh" className="pill-primary link">
              <i className="fa fa-chevron-right"></i> {t('PUSH_DIGITAL_SERVICES')}
            </a>
            <Trans i18nKey="PUSH_GDPR" ns="home">
              <a className="pill-primary link" href="https://www.avonova.se/nedladdning-webbportal-sve">
                <i className="fa fa-chevron-right"></i> {t('PUSH_GDPR')}
              </a>
              <a className="pill-primary link" href="https://www.avonova.se/download-web-portal-eng">
                <i className="fa fa-chevron-right"></i> {t('PUSH_GDPR')}
              </a>
            </Trans>
          </div>
        </div>
      </div>
    </div>

  )
};

export default connect((appState: ApplicationState) => (
  { authenticationState: appState.authentication }),
  {...AuthenticationStore.actions}
)(Home);
