import * as React from 'react';
import { Button, Table } from 'reactstrap';

import './CustomerFiles.css';

import { connect } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IFile } from '../../services/filesService';
import { useTranslation} from 'react-i18next';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.locale("sv");

export type ICustomerFilesProps = {
    items?: IFile[],
    onDownload?: (url:string, filename:string) =>void
    onDelete?: (url: string) => void
    onUpload?: (formData:FormData) => Promise<void>
}


// export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean, expanded :boolean }> {

const CustomerFiles = function ({ items, onDownload, onUpload, onDelete }: ICustomerFilesProps) {

    const onDeleteClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        const target = event.currentTarget as HTMLAnchorElement;
        if (onDelete) {
            onDelete(target.href);
        }
    };

    const onDownloadClick = (event:React.MouseEvent<HTMLAnchorElement,MouseEvent>) => {
        event.preventDefault();
        const target = event.currentTarget as HTMLAnchorElement;
        if (onDownload){
            onDownload(target.href, target.innerText);
        }
    } ;

    const onUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        let formData = new FormData();
        const fileList = event.currentTarget.files;
        if(fileList){
            for(var i = 0; i < fileList.length; i++){
                formData.append("uploadedfiles", fileList[i], fileList[i].name);
            }
        }

        event.preventDefault();
        if (onUpload) {
            onUpload(formData).then(() => {
                
            }).catch(() => {

            });
        }
    };

    const { t } = useTranslation('filearea');
    
    return (
        <div className="row">
            <div className="col fullwidth-table-container collapsable-table-md">
                <Table hover className="default" striped={true}>
                    <thead>
                        <tr>
                            <th>{t('FILENAME',)}</th>
                            <th>{t('AUTHOR')}</th>
                            <th>{t('CREATED')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items && items.map((i: IFile) => <tr key={i.downloadLink}>
                            <td><span className="collapsed-title">{t('FILENAME')}: </span><a onClick={onDownloadClick} href={i.downloadLink}>{i.title}</a></td>
                            <td><span className="collapsed-title">{t('AUTHOR')}: </span>{i.author.displayName}, {i.author.email}</td>
                            <td><span className="collapsed-title">{t('CREATED')}: </span>{dayjs(i.created).format("YYYY-MM-DD")}</td>
                            <td><a href={i.deleteLink} onClick={onDeleteClick}><i className="fa fa-trashcan"></i>Delete</a></td>
                        </tr>)}
                    </tbody>
                </Table>
                <label>
                     <input name="attachment_files" 
                        id="attachment_files" type="file" onChange={onUploadClick} multiple={true} accept=".pdf, .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .csv"
                        className="hidden"/>

                    <Button color="primary" onClick={() => { document.getElementById("attachment_files")?.click(); }}><i className="fa fa-plus"></i> {t('UPLOAD_FILES')}</Button>
                    
                </label>
            </div>
        </div>
    );
}

export default connect()(CustomerFiles);