import * as React from 'react';
import { useEffect } from 'react';
import './Panel.css';
import { connect } from 'react-redux';
import * as AuthenticationStore from '../../store/Authentication'
import { ApplicationState } from '../../store';
import ReactDOM from 'react-dom';

export type IFolderProps = {
    onClose?:()=>void; 
}


const Panel: React.FunctionComponent<React.PropsWithChildren<IFolderProps>> = ({ children, onClose}: React.PropsWithChildren<IFolderProps>)  => {

    const overlayContainerId:string = "overlayContainer";
    
    var overlayContainer = document.getElementById(overlayContainerId);
    if (!overlayContainer) {
        overlayContainer = document.createElement('div');
        overlayContainer.id = overlayContainerId;
        document.body.appendChild(overlayContainer);
    }

    useEffect(() => {
        return () => {
            overlayContainer && document.body.removeChild(overlayContainer)
        }
    }, [overlayContainer])

    return ReactDOM.createPortal(
        <div className="overlayBackdrop" onClick={() => { onClose && onClose(); }}>
            <div className="overlayContent" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                <button className="close-panel-button btn-primary" onClick={(e) => { onClose && onClose(); e.preventDefault(); e.stopPropagation(); }} ><i className="fa fa-times" /> </button>
                {children}
            </div>
        </div>,
        overlayContainer
    );
} 

export default connect((appState: ApplicationState) => (
    { authenticationState: appState.authentication }),
    AuthenticationStore.actions)(Panel);