import { Spinner } from "reactstrap";
import { Button, ButtonProps } from "reactstrap"
import './SubmitButton.css';
import * as React from 'react';
import { useMemo } from "react";

type ISubmitButtonProps = ButtonProps & {
    submitting?:boolean;
    label?:string;
    activelabel?:string;
};

const SubmitButton = (props:ISubmitButtonProps) => {

    const className = useMemo(() => {
        return props.className ? props.className + " submitButton" : "submitButton";
    }, [props.className]);

    const submitButtonLabel = useMemo(() => {
        return props.submitting ? props.activelabel : props.label;
    }, [props.submitting, props.activelabel , props.label]);
    const children = submitButtonLabel || props.children;
    return <Button {...props} className={className}> {props.submitting && <Spinner size="sm" className="spinner" />} {children}</Button>
}
export default SubmitButton;