import { Action, Reducer } from 'redux';

export const AlertType = {
    ERROR: "error",
    WARNING: "warning",
    INFO: "info"
}

export interface Alert {
    AlertType: string;
    Message:string;
}


export interface AddAlertAction { type: 'ADD_ALERT', alert: Alert }

export type AlertAction = AddAlertAction;

export interface IAlertActionCreators{
    addAlert: (alert: Alert) => AddAlertAction
}

export const actionCreators : IAlertActionCreators = {
    addAlert: (alert: Alert) => ({ type: 'ADD_ALERT', alert: alert } as AddAlertAction)
}

const defaultState = {
    AlertType: "",
    Message: "" 
}

export const reducer: Reducer<Alert> = (state: Alert | undefined, incomingAction: Action): Alert => {

    if (state === undefined) {
        return defaultState;
    }

    const action = incomingAction as AlertAction;
    switch (action.type) {
        case 'ADD_ALERT':
            // const loginAction = incomingAction as AddAlertAction;
            var newState = { ...state };
            return newState;
        default:
            return state;
    }
};