import { FC, PropsWithChildren, useEffect, useState } from "react";
import * as React from "react";
import { authenticationServiceFactory } from "../../services/authenticationService";
import { IAuthenticationActionCreators, LoginState, UserState } from "../../store/Authentication";
import * as AuthenticationStore from '../../store/Authentication'
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import Context from '../../configuration/context';

export type IAccessTokenProviderProps = PropsWithChildren<IAuthenticationActionCreators & {
	authenticationState?: UserState;
}>;

const AccessTokenProvider:FC<IAccessTokenProviderProps> = (props:IAccessTokenProviderProps) => {

	const [accessTokenIsValid, setAccessTokenIsValid] = useState(false);

    const {authenticationState, setLoginState} = props;

	useEffect(() => {
		if (authenticationState?.LoginState === AuthenticationStore.LoginState.LOGGED_IN && !!setLoginState){
			authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState).getOrRenewNativeToken().then(() => {
				setAccessTokenIsValid(true);
			});
		}
	}, [LoginState, authenticationState]);

    return  <React.Fragment>{accessTokenIsValid && props.children}</React.Fragment>
}

const foo = connect(
	(appState: ApplicationState) => ({ authenticationState: appState.authentication }),
	{ ...AuthenticationStore.actions }
	)(AccessTokenProvider);

	export default foo;