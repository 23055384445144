import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IAuthenticationService, ITokenProvider } from './authenticationService';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.locale("sv");

export interface IOrderer {
    displayName: string;
    email: string;
}

export interface IOrderPage{
    nextPageInfo:string;
    orders: IOrderReference[];
    hasMoreOrders:boolean;
}

export interface IOrderReference {
    company: string;
    companyId: string;
    createdBy: string;
    orderDate: Date;
    orderNumber: string;
    orderStatus: string;
    orderType: string;
    organization: string;
    service: string;
    pdfLink: string;
}

export interface IEntityReference{
    id:string;
    displayName:string;    
}

export interface IOrderFileReference{
    displayName:string;
    uri:string;
}

export interface IOrderRequest {
    createdBy: string;
    createdByPhone: string;
    createdByEmail: string;
    externalReference: string;
    costBearingUnit: string;
    requestedService: string;
    requestedServiceDescription: string;
    otherInformation: string;
    requestedDeliveryDate: Date;
    digitalDeliveryIsPreferred: boolean;
    feedbackMethod: string;
    specificFeedbackRecipient: boolean;
    specificFeedbackRecipientDetails: string;
    preferredPerformingUnit: string;
    customerId:string;
    employeeName: string;
    employeeAddress: string;
    employeeMobilePhone: string;
    employeeWorkPhone: string;
    employeeEmail: string;
    employeePersonalIdentityNumber: string;
    groupSize: string;
    additionalPerformingUnits: string[];
    orderType:string;
}

export interface IFileDownloadInfo{
    displayName:string;
    url:string;
}

export interface IOrder{
    attachments: IFileDownloadInfo[];
    created:Date;
    orderId: string;
    orderType:number;
    createdById:string;
    createdBy:string;
    createdByPhone:string;
    createdByEmail:string;
    externalReference:string;
    costBearingUnit: IEntityReference;
    requestedService:string;
    requestedServiceDescription:string;
    otherInformation:string;
    requestedDeliveryDate:Date;
    digitalDeliveryIsPreferred:boolean;
    feedbackMethod:number;
    specificFeedbackRecipient:boolean;
    specificFeedbackRecipientDetails:string;
    preferredPerformingUnit: IEntityReference;
    attachmentsDownloadInfo: IOrderFileReference[];
    employeeName:string;
    employeeAddress:string;
    employeeMobilePhone:string;
    employeeWorkPhone:string;
    employeeEmail:string;
    employeePersonalIdentityNumber:string;
    groupSize:string;
    additionalPerformingUnits: IEntityReference[];
    scheduledDate:Date;
    bookingDetails:string;
    orderStatus:number;
}


export interface IOrderService {
    getOrders: (customerId: string, nextPageInfo?:string) => Promise<IOrderPage>;
    saveOrder: (order: IOrderRequest, attachedFiles:File[]) => Promise<IOrder>;
    getOrder: (orderId:string) => Promise<IOrder>;
    getAttachmentContent: (attachmentUrl: string) => Promise<Blob>;
    getPDFContent: (pdfUrl: string) => Promise<Blob>;
}

export const orderServiceFactory: (ApiHostBaseUrl: string, authenticationService: ITokenProvider) => IOrderService = (ApiHostBaseUrl: string, authenticationService: ITokenProvider) => {
    return {
        getOrder : (orderId:string) => {
            return authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                    var url: string = ApiHostBaseUrl + '/customerPortal/getOrder?orderId=' + orderId;
                    return fetch(url, {
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache',
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Authorization': 'bearer ' + accessToken
                        },
                        redirect: 'error',
                        referrerPolicy: 'no-referrer'
                    });
                })
                .then((data: Response) => {
                    return data.json() as Promise<IOrder>;
                });
        },

        getOrders: (customerId: string, nextPageInfo?: string) => {
            return authenticationService.getOrRenewNativeToken() .then((accessToken: string) => {
                var url:string = ApiHostBaseUrl + '/customerPortal/getOrders?topCustomerId=' + customerId;
                if(nextPageInfo){
                    url += "&paging=" + nextPageInfo;
                }
                return fetch(url, {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                });
            })
            .then((data: Response) => {
                return data.json() as  Promise<IOrderPage>;
            });
        },
        
        saveOrder: (order: IOrderRequest, attachedFiles: File[]) => {
            return authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                var url: string = ApiHostBaseUrl + '/customerPortal/saveOrder';
                var formData = new FormData();
                formData.append("orderAsJson", JSON.stringify(order));
                attachedFiles && attachedFiles.forEach(function (file, key) {
                    formData.append("files[" + key + "]", file);
                });
                return fetch(url, {
                        body: formData,
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache',
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Authorization': 'bearer ' + accessToken
                        },
                        redirect: 'error',
                        referrerPolicy: 'no-referrer'
                    });
                })
                .then((data: Response) => {
                    return data.json() as Promise<IOrder>;
                });
        },

        getAttachmentContent: (attachmentUrl: string) => {
            var url: string = ApiHostBaseUrl + '/customerPortal/getOrderAttachment?attachmentUrl=' + encodeURIComponent(attachmentUrl);
                return authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                    return fetch(url, {
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache',
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'bearer ' + accessToken
                        },
                        redirect: 'error',
                        referrerPolicy: 'no-referrer'
                    });
                }).then((response) => {
                    return response.blob() as Promise<Blob>
                }).catch<never>((error: any) => {
                    console.warn("Could not fetch user details");
                    throw error;
                });
        },

        getPDFContent: (pdfUrl: string) => {
            var url: string = pdfUrl;
                return authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                    return fetch(url, {
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache',
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'bearer ' + accessToken
                        },
                        redirect: 'error',
                        referrerPolicy: 'no-referrer'
                    });
                }).then((response) => {
                    return response.blob() as Promise<Blob>
                }).catch<never>((error: any) => {
                    console.warn("Could not fetch user details");
                    throw error;
                });
        }
    }
};