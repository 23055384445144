import * as React from 'react';
import * as RRD from 'react-router-dom'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Input, InputGroup, InputGroupText, NavLink, Spinner } from 'reactstrap';
import { orderServiceFactory, IOrderPage, IOrderReference } from '../../services/orderService';
import { ApplicationState } from '../../store';
import OrderList from '../controls/OrderList';
import Context from '../../configuration/context';
import './Orders.css';
import dayjs from 'dayjs';
import * as AuthenticationStore from '../../store/Authentication'
import { authenticationServiceFactory } from '../../services/authenticationService';
import { IAuthenticationActionCreators } from '../../store/Authentication';
import { UserState} from '../../store/Authentication';

export type IOrdersProps =
    IAuthenticationActionCreators &
    {
        authenticationState?: UserState;
    }

const Orders = function ({ setLoginState, authenticationState }:IOrdersProps) {
    
    const [orders, setOrders] = useState<IOrderReference[]>([]);
    const [filteredOrders, setFilteredOrders] = useState<IOrderReference[]>([]);
    const [filterTerm, setFilterTerm] = useState("");
    const [nextPageInfo, setNextPageInfo] = useState("");
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setIsLoadingMore] = useState(false);

    const { t } = useTranslation('orders');

    const LoginState = authenticationState?.LoginState;
    const customerId = authenticationState?.UserInRole?.selectedCustomer?.customerId;

    const downloadPDF = (url:string, filename:string) => {
        return orderServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState)).getPDFContent(url).then((text: Blob) => {
            let file = new File([text], filename, { type: "application/octet-stream", });
            let exportUrl = URL.createObjectURL(file);
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.href = exportUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(exportUrl);
        });
    }

    useEffect(() => {
        if (LoginState && LoginState === AuthenticationStore.LoginState.LOGGED_IN && customerId) {
        // Anything in here is fired on component mount.

            setIsLoading(true);
            orderServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState)).getOrders(customerId.toString())
                .then((files: IOrderPage) => {
                    setIsLoading(false);
                    setOrders([...files.orders]);
                    setHasMore(files.hasMoreOrders);
                    setNextPageInfo(files.nextPageInfo);
                }).catch((error: any) => {
                    setIsLoading(false);
                    setHasMore(false);
                    setOrders([]);
                });
        }
    }, [LoginState, customerId]);

    const location = RRD.useLocation();
    const navigate = RRD.useNavigate();
    
    const fetchMore =  () => {
        if (LoginState && LoginState === AuthenticationStore.LoginState.LOGGED_IN && customerId) {
            setIsLoadingMore(true);
            
            orderServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState)).getOrders(customerId.toString(), nextPageInfo)
                .then((files: IOrderPage) => {
                    setIsLoadingMore(false);
                    setOrders([...orders, ...files.orders]);
                    setHasMore(files.orders.length > 0 && files.nextPageInfo.length > 0);
                    setNextPageInfo(files.nextPageInfo);
                }).catch((error: any) => {
                    setIsLoadingMore(false);
                    setHasMore(false);
                    setOrders([]);
                });
        }else{
            console.error("No access token!");
        }
    }

    const onCopyClick = (order: IOrderReference) => {
       navigate(location.pathname + "/"+ order.orderType.toLowerCase() +"/" + order.orderNumber + "/copy");
    }

    useEffect(() => {
        const _filterTerm =  filterTerm.toLowerCase();
        setFilteredOrders(orders.length > 0 
            ? orders.filter(o => {
                return dayjs(o.orderDate).format("YYYY-MM-DD HH:mm").toLowerCase().startsWith(_filterTerm) 
                    || o.organization.toLowerCase().indexOf(_filterTerm) >= 0 
                    || o.orderNumber.toLowerCase().indexOf(_filterTerm) >= 0
                    || t("STATUS_" + o.orderStatus).toLowerCase().indexOf(_filterTerm) >= 0
                    || t("TYPE_" + o.orderType).toLowerCase().indexOf(_filterTerm) >= 0
                    || o.createdBy.toLowerCase().indexOf(_filterTerm) >= 0
            }) 
            : [...orders]);
    }, [orders, filterTerm, t]);

    const updateFilter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        setFilterTerm(event.currentTarget.value);
    }
    
    return (
        <div className="row  mt-4">
            <div className="col-12 col-xl-9">
                <div className="orders-content">
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <h4>{t('ALL_ORDERS')}</h4>
                        </div>
                        <div className="col-lg-4 col-12">
                            <InputGroup className="search-container">
                                <InputGroupText><i className="fa fa-search"  /></InputGroupText>
                                <Input placeholder={t('SEARCH')} onKeyUp={updateFilter}  />
                            </InputGroup>
                        </div>
                        {isLoading
                            ? <h1 className="loading-indicator-container col-12"><Spinner /> {t('FETCHING_ORDERS')}...</h1>
                            : <div className="col-12"><OrderList onFetchMore={fetchMore} onCopyClick={onCopyClick} items={filteredOrders} enableFetchMore={hasMore} showFetchMoreIndicator={loading} onDownloadPDF={(i:IOrderReference) => downloadPDF(i.pdfLink, "order-" + i.orderNumber + ".pdf")} />
                            </div>}
                    </div>
                </div>
                <span className="field-description">{t('ORDER_RULES_AND_CONDITIONS')}</span>
            </div>
            <div className="col-12 col-xl-3">
                <div className="row">
                    <div className="col-12">
                        <h4 className="new-order-col-header">{t('NEW_ORDER')}</h4>
                    </div>
                    <NavLink className="col-12 col-md-4 col-lg-4 col-xxl-6 individual-container order-link-container" tag={RRD.NavLink} to="/service/order/individual"><span className="link-title">{t('TYPE_Individual')}</span></NavLink>
                    <NavLink className="col-12 col-md-4 col-lg-4 col-xxl-6 group-container order-link-container" tag={RRD.NavLink} to="/service/order/group"><span className="link-title">{t('TYPE_Group')}</span></NavLink>
                    <NavLink className="col-12 col-md-4 col-lg-4 col-xxl-6 education-container order-link-container" tag={RRD.NavLink} to="/service/order/education"><span className="link-title">{t('TYPE_Education')}</span></NavLink>
                </div>
            </div>
        </div>
    );
}

export default connect(
    (appState: ApplicationState) => ({ authenticationState: appState.authentication }),
    { ...AuthenticationStore.actions }
)(Orders);