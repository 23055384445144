import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IAuthenticationService, ITokenProvider } from './authenticationService';


dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.locale("sv");

export interface IConversationReference{
    conversationId:string;
    creationDate:string;
    customerId: string;
    hasUnreadMessages: boolean;
    isArchived: boolean;
    mostRecentActivityDate: string;
    otherParty: IConversationMember[],
    subject:string
}

export interface IMessageAttachment{
    displayName:string;
    url:string;
}


export interface IMessage{
    attachments: IMessageAttachment[];
    author:string;
    message:string;
    creationDate:Date;
    authorIsCurrentUser:boolean;
}

export interface IMessageCreationRequest{
    messageBody:string;
    conversationId:string;
}

export interface IMessageRecipient{
    displayName:string;
    email:string
}

export interface IConversationPageData{
    conversations:IConversationReference[];
    recipients : IMessageRecipient[];
}

export interface IConversationCreationRequest {
    subject: string,
    recipient: IMessageRecipient,
    message: string,
    attachments: File[],
}

export interface IConversation {
    conversationId: string;
    customerId: string;
    isArchived: boolean;
    subject: string;
    messages : IMessage[];
}

export interface IConversationMember {
    conversationId: string;
    displayName: string;
    email: string;
}

export interface IConversationService{
    loadConversationsData: (customerId: string) => Promise<IConversationPageData>;
    loadConversation: (conversationId: string) => Promise<IConversation>;
    addMessageToConversation: (model: IMessageCreationRequest, attachments:File[]) => Promise<IMessage>;
    createNewConversation : (customerId: string, model: IConversationCreationRequest, attachments: File[]) => Promise<IConversation>;
    getAttachmentContent: (attachmentUrl: string) => Promise<Blob>;
    unarchiveConversation: (conversationId: string) => Promise<void>;
    archiveConversation: (conversationId: string) => Promise<void>;
}

export const conversationServiceFactory: (ApiHostBaseUrl: string, authenticationService: ITokenProvider) => IConversationService = (ApiHostBaseUrl: string, authenticationService: ITokenProvider) => {

    return {
        loadConversationsData : (customerId:string) => {
            console.debug("load conversations", customerId);
            return new Promise<IConversationPageData>((resolve, reject) => {
                authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                fetch(ApiHostBaseUrl + '/customerPortal/getConversations?customerId=' + customerId, {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                })
                    .then((data:Response) => {
                        return data.json();
                    })
                    .then((response: IConversationPageData) => {
                        resolve({
                            conversations : response.conversations.map((conversation: IConversationReference) => {
                                return {
                                    ...conversation,
                                    creationDate: dayjs(conversation.creationDate).format('LLL'),
                                    mostRecentActivityDate: dayjs(conversation.creationDate).format('LLL')
                                }
                            }), 
                            recipients: response.recipients
                        });
                    }, function onError(error) {
                        reject(error);
                    });
                });
            });
        },
        loadConversation: (conversationId: string) => {
            console.debug("load conversation", conversationId);
            return new Promise<IConversation>((resolve, reject) => {
                authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                fetch(ApiHostBaseUrl + '/customerPortal/getConversation?conversationId=' + conversationId, {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                })
                    .then((data: Response) => {
                        if (data.status <= 200 && data.status <= 300) {
                            return data.json();
                        } else {
                            throw data.status
                        }
                    })
                    .then((response: IConversation) => {
                        resolve(response);
                    }, function onError(error) {
                        reject(error);
                    });
                });
            });
        },
        addMessageToConversation: (model: IMessageCreationRequest, attachedFiles: File[]) => {
            return new Promise<IMessage>((resolve, reject) => {

                var formData = new FormData();
                formData.append("messageAsJson", JSON.stringify(model));
                attachedFiles && attachedFiles.forEach(function (file, key) {
                    formData.append("files[" + key + "]", file);
                });
                authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                    fetch(ApiHostBaseUrl + '/customerPortal/addMessageToConversation', {
                        body: formData,
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache',
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Authorization': 'bearer ' + accessToken
                        },
                        redirect: 'error',
                        referrerPolicy: 'no-referrer'
                    })
                    .then((data: Response) => {
                        if(data.status <= 200 && data.status <= 300){
                            return data.json();
                        }else{
                            throw data.status
                        }
                    })
                    .then((response: IMessage) => {
                        resolve(response);
                    }, function onError(error) {
                        reject(error);
                    }).catch((error:any) => {
                        reject(error);
                    })
                });
            });
        },
        createNewConversation: (customerId: string, model: IConversationCreationRequest, attachments: File[]) => {
            return new Promise<IConversation>((resolve, reject) => {
                
                var formData = new FormData();
                formData.append("messageAsJson", JSON.stringify({ ...model, customerId: customerId }));
                attachments && attachments.forEach(function (file, key) {
                    formData.append("files[" + key + "]", file);
                });
                authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                    fetch(ApiHostBaseUrl + '/customerPortal/createConversation?customerId=' + customerId, {
                        body: formData,
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache',
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Authorization': 'bearer ' + accessToken
                        },
                        redirect: 'error',
                        referrerPolicy: 'no-referrer'
                    })
                    .then((data: Response) => {
                        return data.json();
                    })
                    .then((response: IConversation) => {
                        resolve(response);
                    }, function onError(error) {
                        reject(error);
                    });
                });
            });
        },
        getAttachmentContent: (attachmentUrl: string) => {
            return new Promise<Blob>((resolve, reject) => {

                var url: string = ApiHostBaseUrl + '/customerPortal/getMessageAttachment?attachmentUrl=' + encodeURIComponent(attachmentUrl);
                authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                    fetch(url, {
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache',
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'bearer ' + accessToken
                        },
                        redirect: 'error',
                        referrerPolicy: 'no-referrer'
                    }).then((response) => {
                        response.blob().then((text: Blob) => {
                            resolve(text);
                        })
                    }).catch((error: any) => {
                        console.warn("Could not fetch user details");
                        reject(error);
                    });
            });
            });
        },
        unarchiveConversation: (conversationId: string) => {
            return new Promise<void>((resolve, reject) => {
                authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                    fetch(ApiHostBaseUrl + '/customerPortal/unarchiveConversation?conversationId=' + conversationId, {
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache',
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'bearer ' + accessToken
                        },
                        redirect: 'error',
                        referrerPolicy: 'no-referrer'
                    })
                        .then((data: Response) => {
                            return data.json();
                        })
                        .then((response: IConversation) => {
                            resolve();
                        }, function onError(error) {
                            reject(error);
                        });
                });
            });
        },
        archiveConversation: (conversationId: string) => {
            return new Promise<void>((resolve, reject) => {
                authenticationService.getOrRenewNativeToken().then((accessToken: string) => {
                    fetch(ApiHostBaseUrl + '/customerPortal/archiveConversation?conversationId=' + conversationId, {
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache',
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'bearer ' + accessToken
                        },
                        redirect: 'error',
                        referrerPolicy: 'no-referrer'
                    })
                        .then((data: Response) => {
                            return data.json();
                        })
                        .then((response: IConversation) => {
                            resolve();
                        }, function onError(error) {
                            reject(error);
                        });
                });
            });
        }
    }
};