import { useEffect, useState } from "react";
import { IAlert, alertsServiceFactory } from "../../services/alertsService";
import { IAuthenticationService } from "../../services/authenticationService";
import Context from '../../configuration/context';
import i18n from "../../services/i18nConfiguration";

const useAlerts = function(isLoggedIn:boolean, authenticationService: IAuthenticationService){
    
    const [activeAlerts, setActiveAlerts] = useState<IAlert[]>([])

    useEffect(() => {
        if(isLoggedIn){
            alertsServiceFactory(Context.APIUrl + "/api/v1", authenticationService).getActiveAlerts().then(alerts => {
                alerts.forEach(alert => {
                    alert.localizedTexts.forEach(t => {
                        i18n.addResource(t.languageCode, "home", alert.key, t.text);
                    });
                    setActiveAlerts(alerts);
                });
            })
        }
    },[isLoggedIn]);

    return {
        activeAlerts
    };
}
export default useAlerts;