import { AuthError } from "@azure/msal-browser";
import { IUserInRole } from "../components/pages/Login";
import { IAuthenticationActionCreators, UserState } from "../store/Authentication";
import * as AuthenticationStore from '../store/Authentication'
import jwt_decode from "jwt-decode";
import { IRefreshedAccessTokenResult } from "./authenticationService";
export interface ILimitedUserInformation{
    email:string;
    state: UserRegistrationState;
    token?:string;
    authSetupKey?:string;
}

export enum UserRegistrationState {
    "Unknown",
    "Unregistered",
    "Unverified",
    "Verified",
    "AwaitingOneTimeCode",
    "Authenticated"
}

export interface ICustomLoginService {
    GetLimitedUserInformation: (email:string) => Promise<ILimitedUserInformation>;
    RegisterNewUser: (username: string, password:string) => Promise<ILimitedUserInformation>;
    RequestNewVerificationToken: (username: string) => Promise<void>;
    VerifyByTOTP: (email: string, code: string) => Promise<void>;    
    Login: (username: string, password: string) => Promise<LoginTokensDTO>;
    RequestResetPasswordToken: (username: string) => Promise<void>;
    ResetPassword: (email:string, pw:string, resetPasswordToken:string) => Promise<ILimitedUserInformation>;
    FetchUserDetails : (nativeAccessToken:string) => Promise<void>;
    AcquireNativeTokenBySamlToken : (samlToken:string) => Promise<LoginTokensDTO>;
} 

export interface LoginTokensDTO{
    accessToken:string;
    refreshToken:string;
    expirationDate:Date;
    expirationDateForRefreshToken:Date;
    totpIsNeeded:boolean;
    totpURL:string;
    state: UserRegistrationState;
}

const ThrowAuthErrorOnErrorResponse = (response:Response) => {
    if (response.status === 400) {
        throw new AuthError("400", "Bad request");
    }
    else if (response.status === 401) {
        throw new AuthError("401", "Unauthorized")
    }
    else if (response.status === 403) {
        throw new AuthError("403", "Forbidden");
    }
    if (response.status === 404) {
        throw new AuthError("404", "Not found");
    }
    else if (response.status < 200 || response.status > 400) {
        throw new AuthError(response.status.toString(), "Server error");
    } 
    else {
        return response;
    }
}

const CatchAndThrowFailedToGetInfo = (error:any) => {
    if (error.message && error.message === "Failed to get info") {
         throw new AuthError("-1", "Could not find server");
    } else {
        throw error;
    }
}

const DoNothing = () => {};

var refreshTokenPromise:Promise<string>|null;
 
const Create = (APIBaseUrl: string, actionCreators: IAuthenticationActionCreators, currentUserState: UserState | undefined, currentLanguage:string): ICustomLoginService => {

    const fetchUserDetails = (nativeAccessToken: string) => {
        let url: string = APIBaseUrl + "/User/GetUserInfo";
        if (currentUserState?.RequestedCustomerId) {
            url += "?specificCustomerId=" + currentUserState?.RequestedCustomerId
        }
        return fetch(url, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + nativeAccessToken, 
                'Accept-Language': currentLanguage
            },
            redirect: 'error',
            referrerPolicy: 'no-referrer'
        })
        .then(ThrowAuthErrorOnErrorResponse)
        .then((response: Response) => response.json() )
        .then((responseAsJson: IUserInRole) => {
            if (responseAsJson.availableCustomers) {
                responseAsJson.availableCustomers = responseAsJson.availableCustomers.sort((a, b) => a.customerName < b.customerName ? -1 : (a.customerName > b.customerName ? 1 : 0));
                if (responseAsJson.availableCustomers.length === 1) {
                    responseAsJson.selectedCustomer = responseAsJson.availableCustomers[0];
                }
                else if (currentUserState && currentUserState?.RequestedCustomerId) {
                    const requestedCustomer = responseAsJson.availableCustomers.find(c => c.customerId.toString() === currentUserState.RequestedCustomerId);
                    responseAsJson.selectedCustomer = requestedCustomer;
                }
            }
            return responseAsJson;
        }).catch((error: any) => {
            console.warn("Could not fetch user details");
            throw error;
        });
    }

    const updateGlobalLoginState = (userInRole:IUserInRole) => {
        userInRole.selectedCustomer = currentUserState?.UserInRole?.selectedCustomer || userInRole.selectedCustomer;
        actionCreators.setLoginState && actionCreators.setLoginState({
            LoginState: AuthenticationStore.LoginState.LOGGED_IN,
            UserInRole: userInRole
        });
    }

    function refreshNativeToken(){
        if(refreshTokenPromise == null){
            refreshTokenPromise = new Promise<string>((resolve, reject) => {
                fetch(APIBaseUrl + "/api/v2/AccessToken/Refresh", {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + currentUserState?.RefreshToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                }).then((response:Response) => {
                    refreshTokenPromise = null;
                    if(response.status >= 200 && response.status < 300){
                        response.json().then((result:IRefreshedAccessTokenResult) => {
                            var tokenData: any = jwt_decode(result.accessToken);
                            var refreshedState = {
                                AccessToken: result.accessToken,
                                AccessTokenExpirationDate: new Date(tokenData["exp"] * 1000),
                                MfaWasUsed : false
                            };
                            actionCreators.setLoginState && actionCreators.setLoginState(refreshedState);
                            resolve(result.accessToken);
                        });
                    }else{
                        if (response.status === 400) {
                            reject(new AuthError("400", "Bad request"));
                        }
                        else if(response.status === 401){
                            reject(new AuthError("401", "Unauthorized"));
                        }
                        else if (response.status === 403) {
                            reject(new AuthError("403", "Forbidden"));
                        }else{
                            reject(new AuthError(response.status.toString(), "Server error"));
                        }
                    }
                }).catch((error:any) => {
                    refreshTokenPromise = null;
                    if(error.message && error.message === "Failed to fetch"){
                        reject(new AuthError("-1", "Could not find server"));
                    }else{
                        reject({ errorCode: -1, errorMessage: "Unexpected Error" });
                    }
                });
            });
        }
        return refreshTokenPromise;
    }

    function getOrRenewNativeToken(): Promise<string>{

        var _nativeAccessToken:string = "";
        
        return new Promise((resolve, reject) => {

            console.debug("Getting token from cache.");
            if (currentUserState && currentUserState.AccessTokenExpirationDate && currentUserState.AccessTokenExpirationDate > new Date() && currentUserState.AccessToken) {
                console.debug("Using cached access token");
                resolve(currentUserState.AccessToken);
                return;
            }

            if (!currentUserState 
                || !currentUserState.RefreshToken
                || !currentUserState.RefreshTokenExpirationDate 
                || currentUserState.RefreshTokenExpirationDate < new Date() ) 
            {   
                console.debug("Both cached access token and refresh token was outdated or missing.");             
                logoutUser().then(() =>{
                    throw("AccessToken_Expired");
                });
                reject();
                return;
            }

            console.debug("Cached token was outdated, using valid refresh token to refresh access token.", currentUserState.AccessTokenExpirationDate);
            refreshNativeToken()
                .then((nativeAccessToken: string) => {
                    console.debug("Access token refresh succeeded");
                     var promise = fetchUserDetails(nativeAccessToken);
                     promise.then(() => {
                        var tokenData: any = jwt_decode(nativeAccessToken);
                        actionCreators.setLoginState && actionCreators.setLoginState({
                            AccessToken: nativeAccessToken,
                            AccessTokenExpirationDate: new Date(tokenData["exp"] * 1000)
                        });
                        _nativeAccessToken = nativeAccessToken;
                        resolve(_nativeAccessToken);
                     }).catch((error: any) => {
                         console.debug("Could not fetch user details. Error: " + JSON.stringify(error), error);
                         actionCreators.setLoginState && actionCreators.setLoginState({
                             LoginState: AuthenticationStore.LoginState.LOGIN_FAILED,
                             UserInRole: undefined,
                             AccessToken: ""
                         });
                     });
                     return promise;
                })
                .then((userInRole: IUserInRole) => {
                    console.debug("New user details were fetched.", userInRole);
                    userInRole.selectedCustomer = currentUserState?.UserInRole?.selectedCustomer;
                    actionCreators.setLoginState && actionCreators.setLoginState({
                        LoginState: AuthenticationStore.LoginState.LOGGED_IN,
                        UserInRole: userInRole
                    })
                    resolve(_nativeAccessToken);
                });
        });
    }

    function logoutUser(){
        return new Promise<void>((resolve, reject) => {
            actionCreators.setLoginState && actionCreators.setLoginState({
                LoginState: AuthenticationStore.LoginState.NOT_LOGGED_IN,
                UserInRole: undefined,
                AccessToken: "",
                RefreshToken: "",
                AccessTokenExpirationDate: new Date(Date.parse("1970-01-02")),
                RefreshTokenExpirationDate: new Date(Date.parse("1970-01-02")),
                MfaWasUsed: false
            });
            resolve();
        });
    }

    return {
        GetLimitedUserInformation: function (email: string): Promise<ILimitedUserInformation> {
            return fetch(APIBaseUrl + "/CustomAuthentication/GetLimitedUserInfo?email=" + encodeURIComponent(email), {
                method: 'Get',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache',
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': currentLanguage                        
                },
                redirect: 'error',
                referrerPolicy: 'no-referrer'
            })
            .then(ThrowAuthErrorOnErrorResponse)
            .then(response => response.json() as Promise<ILimitedUserInformation>)
            .catch(CatchAndThrowFailedToGetInfo);
        },
        RegisterNewUser: (email: string, password: string): Promise<ILimitedUserInformation> => {
            return fetch(APIBaseUrl + "/CustomAuthentication/CreateNewUser", {
                body: JSON.stringify({ "email": email, "password": password }),
                method: 'Post',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache',
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': currentLanguage
                },
                redirect: 'error',
                referrerPolicy: 'no-referrer'
            })
            .then(ThrowAuthErrorOnErrorResponse)
            .then((response: Response) => response.json() as Promise<ILimitedUserInformation>)
            .catch(CatchAndThrowFailedToGetInfo);
        },
        RequestResetPasswordToken: function (email: string): Promise<void> {
            return fetch(APIBaseUrl + "/CustomAuthentication/RequestResetPasswordToken?email=" + encodeURIComponent(email), {
                method: 'Get',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache',
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': currentLanguage
                },
                redirect: 'error',
                referrerPolicy: 'no-referrer'
            })
            .then(ThrowAuthErrorOnErrorResponse)
            .then(DoNothing)
            .catch(CatchAndThrowFailedToGetInfo);

        },
        RequestNewVerificationToken: function (email: string): Promise<void> {
            return fetch(APIBaseUrl + "/CustomAuthentication/RequestNewVerificationToken?email=" + encodeURIComponent(email), {
                method: 'Get',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache',
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': currentLanguage
                },
                redirect: 'error',
                referrerPolicy: 'no-referrer'
            })
            .then(ThrowAuthErrorOnErrorResponse)
            .then(() => {})
            .catch(CatchAndThrowFailedToGetInfo);
        },
        Login: function (email: string, password: string): Promise<LoginTokensDTO> {
            
            return fetch(APIBaseUrl.replace("v1","v3") + "/CustomAuthentication/Login", {
                body: JSON.stringify({ "email": email, "password": password }),
                method: 'Post',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache',
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': currentLanguage
                },
                redirect: 'error',
                referrerPolicy: 'no-referrer'
            })
            .then(ThrowAuthErrorOnErrorResponse)
            .then((response: Response) => response.json() as Promise<LoginTokensDTO>)
            .then((userInformation: LoginTokensDTO) => {
                
                let nativeAccessToken = userInformation.accessToken;
                let nativeRefreshToken = userInformation.refreshToken;
                var tokenData: any = jwt_decode(nativeAccessToken);
                var refreshTokenData: any = jwt_decode(nativeRefreshToken);

                actionCreators.setLoginState && actionCreators.setLoginState({
                    AccessToken: nativeAccessToken,
                    AccessTokenExpirationDate: new Date(tokenData["exp"] * 1000),
                    RefreshToken: nativeRefreshToken,
                    RefreshTokenExpirationDate: new Date(refreshTokenData["exp"] * 1000),
                    MfaWasUsed: !userInformation.totpIsNeeded
                });

                if (!userInformation.totpIsNeeded) {
                    fetchUserDetails(nativeAccessToken)
                    .then(updateGlobalLoginState)
                    .catch((error: any) => {
                        
                        console.error("Could not fetch user details. Error: " + JSON.stringify(error), error);

                        actionCreators.setLoginState && actionCreators.setLoginState({
                            LoginState: AuthenticationStore.LoginState.NOT_LOGGED_IN,
                            UserInRole: undefined,
                            AccessToken: "",
                            RefreshToken: "",
                            AccessTokenExpirationDate: new Date(Date.parse("1970-01-02")),
                            RefreshTokenExpirationDate: new Date(Date.parse("1970-01-02")),
                            MfaWasUsed: false
                        });
                    
                       
                        if(error.errorCode && error.errorMessage){
                            throw error
                        }
                        if (error.message && error.message === "Failed to get info") {
                            throw error
                        } else {
                            throw new AuthError("-1", error);
                        }
                    });
                }
                return userInformation;                         

            }).catch(CatchAndThrowFailedToGetInfo);
        },
        VerifyByTOTP: function (email: string, code: string): Promise<void> {
            return getOrRenewNativeToken().then((nonMFAAccessToken) => fetch(APIBaseUrl.replace("v1","v3") + "/CustomAuthentication/TOTP?email=" + encodeURIComponent(email) + "&code=" + encodeURIComponent(code), {
                method: 'Post',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache',
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + nonMFAAccessToken,
                    'Accept-Language': currentLanguage
                },
                redirect: 'error',
                referrerPolicy: 'no-referrer'
            })
            .then(ThrowAuthErrorOnErrorResponse)
            .then(response => response.json())
            .then((response:LoginTokensDTO) => {
                let nativeAccessToken = response.accessToken;
                let nativeRefreshToken = response.refreshToken;
                var tokenData: any = jwt_decode(nativeAccessToken);
                var refreshTokenData: any = jwt_decode(nativeRefreshToken);
                actionCreators.setLoginState && actionCreators.setLoginState({
                    AccessToken: nativeAccessToken,
                    AccessTokenExpirationDate: new Date(tokenData["exp"] * 1000),
                    RefreshToken: nativeRefreshToken,
                    RefreshTokenExpirationDate: new Date(refreshTokenData["exp"] * 1000),
                    MfaWasUsed: true
                });
                
                return nativeAccessToken;
            })
            .then(fetchUserDetails)
            .then(updateGlobalLoginState)
            .catch((error: any) => {
                console.debug("Could not fetch user details. Error: " + JSON.stringify(error), error);
                actionCreators.setLoginState && actionCreators.setLoginState({
                    LoginState: AuthenticationStore.LoginState.LOGIN_FAILED,
                    UserInRole: undefined
                });
                
                if(error.errorCode && error.errorMessage){
                    throw error
                }
                if (error.message && error.message === "Failed to get info") {
                    throw error
                } else {
                    throw new AuthError("-1", error);
                }
            }));
        },
        FetchUserDetails:function (accessToken:string) {
           return  fetchUserDetails(accessToken)
            .then(updateGlobalLoginState)
            .catch((error: any) => {
                console.debug("Could not fetch user details. Error: " + JSON.stringify(error), error);
                
                actionCreators.setLoginState && actionCreators.setLoginState({
                        LoginState: AuthenticationStore.LoginState.NOT_LOGGED_IN,
                        UserInRole: undefined,
                        AccessToken: "",
                        RefreshToken: "",
                        AccessTokenExpirationDate: new Date(Date.parse("1970-01-02")),
                        RefreshTokenExpirationDate: new Date(Date.parse("1970-01-02")),
                        MfaWasUsed: false
                });
                
                if(error.errorCode && error.errorMessage){
                    throw error
                }
                if (error.message && error.message === "Failed to get info") {
                    throw error
                } else {
                    throw new AuthError("-1", error);
                }
            });
        },
        ResetPassword: (email: string, password: string, token:string): Promise<ILimitedUserInformation> => {
            return fetch(APIBaseUrl + "/CustomAuthentication/ResetPassword", {
                method: 'Post',
                body: JSON.stringify({ "email": email, "password": password, "token" : token }),
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache',
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': currentLanguage
                },
                redirect: 'error',
                referrerPolicy: 'no-referrer'
            })
            .then(ThrowAuthErrorOnErrorResponse)
            .then(response => response.json() as Promise<ILimitedUserInformation>)
            .catch(CatchAndThrowFailedToGetInfo);
        },
        AcquireNativeTokenBySamlToken : (samlToken:string) => {
            return new Promise<LoginTokensDTO>((resolve, reject) => {
                fetch(APIBaseUrl.replace("v1", "v3") + "/AccessToken/SAML", {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + samlToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                }).then((response:Response) => {
                    if(response.status >= 200 && response.status < 300){
                        response.json()
                            .then((result:LoginTokensDTO) => {
                                var tokenData: any = jwt_decode(result.accessToken);

                                actionCreators.setLoginState && actionCreators.setLoginState({
                                    LoginState: AuthenticationStore.LoginState.LOGGED_IN,
                                    RefreshToken: "",
                                    RefreshTokenExpirationDate: new Date(Date.parse("1970-01-02")),
                                    AccessToken: result.accessToken,
                                    AccessTokenExpirationDate: new Date(tokenData["exp"] * 1000),                                        
                                    MfaWasUsed : !result.totpIsNeeded
                                });

                                if(result.totpIsNeeded){
                                    resolve(result);
                                    return;
                                }
                                
                                console.debug("User mfa is trusted. fetchin user details.");

                                fetchUserDetails(result.accessToken)
                                    .then((userInRole: IUserInRole) => {
                                        console.debug("User details were fetched.", userInRole);
                                        if(currentUserState?.UserInRole?.selectedCustomer)
                                            userInRole.selectedCustomer = currentUserState?.UserInRole?.selectedCustomer;
                                        actionCreators.setLoginState && actionCreators.setLoginState({
                                            LoginState: AuthenticationStore.LoginState.LOGGED_IN,
                                            UserInRole: userInRole
                                        });
                                        resolve(result);
                                    });
                            });
                    }else{
                        if (response.status === 400) {
                            reject(new AuthError("400", "Bad request"));
                        }
                        else if(response.status === 401){
                            reject(new AuthError("401", "Unauthorized"));
                        }
                        else if (response.status === 403) {
                            reject(new AuthError("403", "Forbidden"));
                        }else{
                            reject(new AuthError(response.status.toString(), "Server error"));
                        }
                    }
                }).catch((error:any) => {
                    if(error.message && error.message === "Failed to fetch"){
                        reject(new AuthError("-1", "Could not find server"));
                    }else{
                        reject({ errorCode: -1, errorMessage: "Unexpected Error" });
                    }
                });
            });
        }
    }
};

export default { Create };