import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import './Conversations.css'
import Context from '../../configuration/context';
import { conversationServiceFactory, IMessageRecipient } from '../../services/conversationService';
import { FormGroup, Label, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { ControlledFileUploadInput, ControlledSelect, ControlledTextArea, ControlledTextInput } from '../controls/FormComponents';
import dayjs from 'dayjs';
import { IAuthenticationActionCreators, UserState } from '../../store/Authentication';
import * as AuthenticationStore from '../../store/Authentication'
import { authenticationServiceFactory } from '../../services/authenticationService';
import useConversations from '../hooks/useConversations';
import { useNavigate, useParams } from 'react-router-dom';
import reactSelectAsync from 'react-select/async';
import { IUserInRole } from './Login';
import CustomLogin from '../controls/CustomLogin/CustomLogin';
import customLoginService from '../../services/customLoginService';
import i18n from '../../services/i18nConfiguration';

export type IConversationsProps =
	IAuthenticationActionCreators &
	{
		authenticationState?: UserState;
	}

const View = {
	INFO: "Info",
	NEW_CONVERSATION: "New",
	VIEW_CONVERSATION: "Show",
}

export interface IConversationModel {
	subject: string,
	recipient: IMessageRecipient,
	message: string,
	attachments: File[],
}

const Conversations: React.FunctionComponent<React.PropsWithChildren<IConversationsProps>> = ({ authenticationState, setLoginState }: React.PropsWithChildren<IConversationsProps>) => {
	
	const navigate = useNavigate();
	const { conversationId } = useParams();

	const customerId:string|undefined = authenticationState?.UserInRole?.selectedCustomer?.customerId;
	const conversationService = conversationServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState))


	const {  
		recipients, 
		conversationsAreLoading, 
		conversationIsLoading, 
		conversations,
		loadConversation,
		selectedConversation,
		addMessageToConversation,
		isSubmitting,
		createConversation,
		errorMessage,
		toggleArchivingStatus
	} = useConversations(customerId || -1, conversationId || -1, conversationService);

	const LoginState = authenticationState?.LoginState;
	
	const [showArchivedConversations, setShowArchivedConversations] = useState(false);
	
	const showNewConversationForm = (event: any) => {
		event.preventDefault(); event.stopPropagation();
		navigate("/contact/conversation");
		reset({});
	}

	const toggleShowArchivedConversationsVisibility = (event: any) => {
		event.preventDefault(); event.stopPropagation();
		setShowArchivedConversations(!showArchivedConversations);
	}

	const { t } = useTranslation('conversation');

	const showArchivedConversationTogglerClass = showArchivedConversations ? "fa fa-toggle-on" : "fa fa-toggle-off";
	const conversationActivationTogglerClass = selectedConversation && selectedConversation.isArchived ? "fa fa-toggle-on" : "fa fa-toggle-off";
	const conversationArchivingTogglerLabel = selectedConversation && selectedConversation.isArchived ? "ARCHIVED" : "NOT_ARCHIVED";

	useEffect(() => {
		if(customerId !== selectedConversation?.customerId && customerId && selectedConversation?.customerId) {
			navigate("/contact/conversation");
		}
	}, [customerId]);


    const authenticationService = useMemo(() => {
        return authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState);
    }, [authenticationState]); 

	useEffect(() => {
		if(customerId + "" !== selectedConversation?.customerId + "" && customerId) {

			if (authenticationState && authenticationState.UserInRole && authenticationState?.LoginState === AuthenticationStore.LoginState.LOGGED_IN && !!selectedConversation?.customerId){
                if (authenticationState.UserInRole?.role === "http://schemas.avonova.com/avonova/v1/identity/claims/role/superadmin"){
                    authenticationService.getOrRenewNativeToken()
                        .then((accessToken) => authenticationService.fetchUserDetails(accessToken, selectedConversation?.customerId))
                        .then((userInRole: IUserInRole) => {
                            setLoginState && setLoginState({
                                UserInRole: userInRole
                            });
                        });
                }
                else{ 
                    var customer = !!selectedConversation?.customerId 
						? authenticationState.UserInRole?.availableCustomers?.find(c => c.customerId.toString() === selectedConversation?.customerId) 
						: null;
                    if (customer ){
                        setLoginState && setLoginState({
                            UserInRole: { ...authenticationState.UserInRole, selectedCustomer: customer }
                        })
                    }
                }
            }else {
                setLoginState && setLoginState({
                    RequestedCustomerId: selectedConversation?.customerId,                    
                });
            }
		}
		loadConversation();
	}, [conversationId, selectedConversation?.customerId]);

	useEffect(() => {
		if (!!selectedConversation?.customerId
			&& selectedConversation?.customerId !== authenticationState?.UserInRole?.selectedCustomer?.customerId
			&& setLoginState)
		{
			var customers = authenticationState?.UserInRole?.availableCustomers?.filter(c => c.customerId == selectedConversation.customerId);
			if(customers?.length === 1 && authenticationState?.UserInRole?.availableCustomers ){
				var customer = authenticationState?.UserInRole?.availableCustomers?.filter(c => c.customerId == selectedConversation.customerId)[0];
				setLoginState({ ...authenticationState, UserInRole : {...authenticationState?.UserInRole, selectedCustomer : customer}});
			}
		}		
	}, [selectedConversation?.conversationId, setLoginState]);

	const { reset, watch, handleSubmit, control, setValue } = useForm<IConversationModel>({ defaultValues: { attachments: []}, mode: "all" });
	const conversationForm = useForm<IConversationModel>({ defaultValues: { attachments: [] }, mode: "all" });

	let messageFormModel: IConversationModel = watch();
	let conversationFormModel: IConversationModel = conversationForm.watch();

	const onMessageSubmit = (form: IConversationModel) => {
		if (LoginState && LoginState === AuthenticationStore.LoginState.LOGGED_IN  && selectedConversation) {
			addMessageToConversation(form,() => reset());
 		}
	};

	const onConversationSubmit = (form: IConversationModel) => {
		if (LoginState && LoginState === AuthenticationStore.LoginState.LOGGED_IN && customerId) {
			createConversation(form, () => {
				conversationForm.reset({ attachments: [], message: "", subject: "", recipient: undefined }, {keepDefaultValues:true });
				messageListRef.current && messageListRef.current.scrollIntoView({ behavior: "smooth" });
			});
		}
	};

	const toggleArchivingForSelectedConversation = (event:any) => {
		event.preventDefault();
		selectedConversation && toggleArchivingStatus();
	};
	
	const removeMessageAttachment = (index: number) => {
		setValue('attachments', messageFormModel.attachments.filter((i, _index) => { return index !== _index }));
	};

	const removeConversationAttachment = (index: number) => {
		conversationForm.setValue('attachments', conversationFormModel.attachments.filter((i, _index) => { return index !== _index }));
	};

	const downloadItem = (url:string, filename:string) => {
		if (LoginState && LoginState === AuthenticationStore.LoginState.LOGGED_IN) {
			return conversationService.getAttachmentContent(url).then((text: Blob) => {
				let file = new File([text], filename, { type: "application/octet-stream", });
				let exportUrl = URL.createObjectURL(file);
				const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
				a.href = exportUrl;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				URL.revokeObjectURL(exportUrl);
			});
		}
	}

    const loginService = useMemo(() => { 
        return customLoginService.Create(Context.APIUrl + "/api/v1", { setLoginState }, authenticationState, i18n.language );},
     [i18n.language]);

	const messageListRef = React.useRef<HTMLDivElement>(null);
	return (
 <div ref={messageListRef}>
				<div className="container mt-4">
				<div className="row limited">
					<div className="col-12 col-lg-8 px-4">
						{!!errorMessage && <div className="error-message">{t(errorMessage)}</div>}
						{conversationId && !errorMessage && 
							<>
							{ conversationIsLoading ? <div className="conversation-loader">{t('FETCHING_CONVERSATIONS')}</div> :
							<>
								<div className="conversation-form row">
										{ isSubmitting && <div className="conversation-submission-overlay" onClick={(event: any) => { event.preventDefault(); event.stopPropagation(); return false; }}></div> }
									<div className="col-12">
										<h3 className="conversation-title">{selectedConversation?.subject}</h3>
										<a href="/" className="conversation-archiver" onClick={toggleArchivingForSelectedConversation}><i className={conversationActivationTogglerClass}></i> {t(conversationArchivingTogglerLabel)}</a>
									</div>
									<form name="add-message-form" className="col-12" onSubmit={handleSubmit((form, event) => {
										event && event.preventDefault();
										event && event.stopPropagation();
										onMessageSubmit(form)
									}, (e) => { console.error(e) })}>
										<div className="row">
											<Label className="col-12">
												{t('FIELD_NAME_MESSAGE')}
												<ControlledTextArea<IConversationModel>
													readonly={isSubmitting}
													rules={{ required: true }}
													control={control}
													fieldName="message"
													className="message-text-area"
													placeholder={t('FIELD_PLACEHOLDER_MESSAGE')} />
												<span className="field-description">{t('FIELD_DESCRIPTION_MESSAGE')}</span>
											</Label>
											<div className="col-md-6">
												<Label>
													<ControlledFileUploadInput<IConversationModel>
														className="hidden"
														control={control}
														fieldName="attachments"											
														placeholder={t('BUTTON_LABEL_ATTACH_FILES')}
													/>
												<div className="pill link"><i className="fa fa-plus-circle"></i> {t('BUTTON_LABEL_ATTACH_FILES')}</div>
												</Label>
												{
													messageFormModel.attachments && !!messageFormModel.attachments.length && <>
														<br />
														<Label>{t('FIELD_NAME_ATTACHMENTS')}</Label>
														<ul>
														{messageFormModel.attachments.map((a: File, index: number) => <li key={index}>{a.name} <i onClick={() => { removeMessageAttachment(index) }} title={t('TITLE_REMOVE_ATTACHMENT')} className="fa fa-minus-circle remove-attachment-btn"></i></li>)}
														</ul>
													</>
												}
											</div>
											<div className="col-md-6">
												<button className="conversation-tool pill-primary link submit-button" disabled={isSubmitting}>{isSubmitting ? <span><Spinner size="sm" /> {t('SUBMITTING')}</span> : <span><i className="fa fa-plus-circle"></i> {t('SUBMIT')}</span>}</button>
											</div>
										</div>
									</form>
								</div>
								<h4 className="row">{t('MESSAGES')}</h4>
								<ul className="message-list row">
									{selectedConversation?.messages.map((m,index) =>
										<li className="message-item col-12" key={index}>
											<span className="message-header">{dayjs(m.creationDate).format('YY/MM/DD HH:mm')} {m.authorIsCurrentUser ? t('YOU') : m.author} {t('WROTE')} </span>
											<span className="message-content">{m.message}</span>
											{m.attachments.length > 0 && <span className="message-attachments">
												{m.attachments.map(a => <span key={a.url} onClick={(event) => { event.preventDefault(); event.stopPropagation(); downloadItem(a.url, a.displayName)}} className="attachment">{a.displayName}</span>)}
											</span>}
										</li>)}
								</ul>
							</>
							}
							</>
						}
						
						{!conversationId &&
							<div className="conversation-form row">
								{ isSubmitting &&  <div className="conversation-submission-overlay" onClick={(event: any) => { event.preventDefault(); event.stopPropagation(); return false; }}></div> }
							<form name="add-conversation-form col-12 mt-4" onSubmit={conversationForm.handleSubmit((form, event) => {
									event && event.preventDefault();
									event && event.stopPropagation();
									onConversationSubmit(form)
								}, (e) => { console.error(e) })}>
									<div className="row">
										<div className="form-header col-12 mt-4 ">
											<span className="form-title">{t('START_NEW_CONVERSATION')}</span><span className="archived-toggler"></span>
										</div>
										<Label className="col-12 mt-4">
											{t('FIELD_NAME_SUBJECT')}
											<ControlledTextInput<IConversationModel>
												readonly={isSubmitting}
												rules={{ required: true }}
												control={conversationForm.control}
												fieldName="subject"
												placeholder={t('FIELD_PLACEHOLDER_SUBJECT')} />
											{/* <span className="field-description">{t('FIELD_DESCRIPTION_SUBJECT')}</span> */}
										</Label>
										<Label className="col-12 mt-4">
											{t('FIELD_NAME_RECIPIENT')}
											<ControlledSelect<IConversationModel, IMessageRecipient>
												readonly={isSubmitting}
												options={recipients.map(r => { return { label: r.displayName + " <" + r.email + ">", value: r } })}
												// options={recipients.map()}
												rules={{ required: true }}
												getOptionValue={v => v.value.email}
												getOptionLabel={v => v.label}
												control={conversationForm.control}
												fieldName="recipient"
												placeholder={t('FIELD_PLACEHOLDER_RECIPIENT')} />
											{/* <span className="field-description">{t('FIELD_DESCRIPTION_RECIPIENT')}</span> */}
										</Label>
										<Label className="col-12 mt-4">
											{t('FIELD_NAME_MESSAGE')}
											<ControlledTextArea<IConversationModel>
												readonly={isSubmitting}
												rules={{ required: true }}
												control={conversationForm.control}
												fieldName="message"
												className="message-text-area"
												placeholder={t('FIELD_PLACEHOLDER_MESSAGE')} />
											<span className="field-description">{t('FIELD_DESCRIPTION_MESSAGE')}</span>
										</Label>
										<div className="col-md-6">
											<Label>
												<ControlledFileUploadInput<IConversationModel>
													readonly={isSubmitting}
													className="hidden"
													control={conversationForm.control}
													fieldName="attachments"
													placeholder={t('BUTTON_LABEL_ATTACH_FILES')}
												/>
												<div className="pill link"><i className="fa fa-plus-circle"></i> {t('BUTTON_LABEL_ATTACH_FILES')}</div>
											</Label>
											{
												conversationFormModel.attachments && !!conversationFormModel.attachments.length && <>
													<br />
													<Label>{t('FIELD_NAME_ATTACHMENTS')}</Label>
													<ul>
													{conversationFormModel.attachments.map((a: File, index: number) => <li key={index}>{a.name} <i onClick={() => { removeConversationAttachment(index) }} title={t('TITLE_REMOVE_ATTACHMENT')} className="fa fa-minus-circle remove-attachment-btn"></i></li>)}
													</ul>
												</>
											}
										</div>
										<div className="col-md-6">
											<button className="conversation-tool pill-primary link submit-button" disabled={isSubmitting}>{isSubmitting ? <span><Spinner size="sm" /> {t('SUBMITTING')}</span> : <span><i className="fa fa-plus-circle"></i> {t('SUBMIT')}</span>}</button>
										</div>
									</div>
								</form>
							</div>
						}
					</div>
					<div className="col-12 col-lg-4 px-4 ">
						<h3>{t('YOUR_CONVERSATIONS')}</h3>
						{!conversationsAreLoading 
							? <div>
								<a href="/" className="conversation-tool pill-primary link" onClick={showNewConversationForm}><i className="fa fa-plus-circle"></i> {t('START_NEW_CONVERSATION')}</a>
								<a href="/" className="conversation-tool" onClick={toggleShowArchivedConversationsVisibility}><i className={showArchivedConversationTogglerClass}></i> {t('SHOW_ARCHIVED_CONVERSATIONS')}</a>
								<ul className="conversation-list">
									{conversations.filter(c => !c.isArchived || showArchivedConversations || c.conversationId == selectedConversation?.conversationId).map(c => { 
										
										const className: string = c.conversationId === conversationId ? "active conversation-summary-card" : "conversation-summary-card";

										return (<li className={className} onClick={(event) => {
											event.preventDefault();
											event.stopPropagation();
											navigate("/contact/conversation/" + c.conversationId )
										}} key={c.conversationId}>
											<div className="conversation-meta">
												<div className="conversation-creation-date">{c.creationDate} {c.hasUnreadMessages && <span className="new">NYTT!</span>}</div>
												<div className="conversation-other-party">{c.otherParty[0].displayName}</div>
											</div>
											<div className="conversation-subject">
												{c.isArchived && <i className="fa fa-archive"></i>}
												{c.subject}
												</div>
										</li>);
									})}
								</ul>
							</div> 
							: <div className="conversation-loader"><Spinner /> {t('FETCHING_CONVERSATIONS')}</div>}
					</div>
				</div>
				</div>
			</div>
	);
};

export default connect(
	(appState: ApplicationState) => ({ authenticationState: appState.authentication }),
	{ ...AuthenticationStore.actions }
	)(Conversations);
