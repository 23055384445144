import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { ApplicationState } from '../../store';

import { ISiteReference } from './Login';
import InvoiceFiles from '../controls/InvoiceFiles';
import Context from '../../configuration/context';
import './Invoices.css';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { IAuthenticationActionCreators, LoginState, UserState } from '../../store/Authentication';
import * as AuthenticationStore from '../../store/Authentication'
import { authenticationServiceFactory, IAuthenticationService } from '../../services/authenticationService';


export type IInvoicesProps =
    IAuthenticationActionCreators &
    {
        UserState: UserState;
    }
export interface IInvoiceFile {
    description: string;
    invoiceDate: Date;
    invoiceId: string;
    category: string;
    company:string;
    downloadLink: string;
}


const Invoices: React.FunctionComponent<React.PropsWithChildren<IInvoicesProps>> = ({ UserState,  setLoginState }: React.PropsWithChildren<IInvoicesProps>) => {

    const [invoiceFiles, setInvoiceFiles] = useState([] as IInvoiceFile[]);
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showDetailedError, setShowDetailedError] = useState(false);
    const { t } = useTranslation('invoices');

    const loginState = UserState?.LoginState;
    const customerId = UserState?.UserInRole?.selectedCustomer?.customerId;
    const [errorDetails, setErrorDetails] = useState("");


    useEffect(() => {
        // Anything in here is fired on component mount.
        if (loginState && loginState === LoginState.LOGGED_IN && !!customerId) {
            setIsLoading(true);
            const authenticationService = authenticationServiceFactory(Context.APIUrl, { setLoginState }, UserState);
            fetchInvoiceDocuments(authenticationService, customerId)
                .then((files: IInvoiceFile[]) => {
                    setIsLoading(false);
                    setInvoiceFiles(files);
                }).catch((error: any) => {
                    setIsLoading(false);
                    setInvoiceFiles([]);                    
                });
        }
    }, [loginState, customerId]);

    var fetchInvoiceContent = (authenticationService: IAuthenticationService, url: string, filename: string) => {
        return new Promise<{}>((resolve, reject) => {
            authenticationService.getOrRenewNativeToken()
                .then((msAccessToken: string) => {
                    fetch(url, {
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache',
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'bearer ' + msAccessToken
                        },
                        redirect: 'error',
                        referrerPolicy: 'no-referrer'
                    }).then((response) => {
                        if (response.status >= 200 && response.status < 300){

                            response.blob().then((text:Blob) => {

                                let file = new File([text], filename + ".pdf", { type: "application/octet-stream",  });
                                let exportUrl = URL.createObjectURL(file);

                                const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

                                a.href = exportUrl;
                                a.download = filename + ".pdf";
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);


                                // window.location.assign(exportUrl);
                                URL.revokeObjectURL(exportUrl);
                                
                                resolve({});
                                // const objectURL = URL.createObjectURL(text);
                                // window.open(objectURL);
                            });
                        }else{
                            console.warn("Could not fetch invoice!");
                            reject(response);
                        }
                        
                    }).catch((error: any) => {
                        console.warn("Could not fetch invoice!");
                        reject(error);
                    });
                }, 
                (error:any) => { 
                    reject(error);
                });
        });
    };

    const onFileClick = (href:string, filename:string) => {
        if (loginState && loginState === LoginState.LOGGED_IN && !!customerId) {
            const authenticationService = authenticationServiceFactory(Context.APIUrl, { setLoginState }, UserState);
            fetchInvoiceContent(authenticationService, href, filename).catch((error:any)=>{
                setShowError(true);
                setErrorDetails(JSON.stringify(error));
            })
        }
    }

    const [filteredInvoices, setFilteredInvoices] = useState<IInvoiceFile[]>([]);
    const [filterTerm, setFilterTerm] = useState("");

    useEffect(() => {
        const _filterTerm:string = filterTerm.toLowerCase();
        setFilteredInvoices(invoiceFiles.length > 0 
            ? invoiceFiles.filter(o => dayjs(o.invoiceDate)
                .format("YYYY-MM-DD HH:mm").startsWith(_filterTerm) ||
                o.description.toLowerCase().indexOf(_filterTerm) >= 0 ||
                o.company.toLowerCase().indexOf(_filterTerm) >= 0 ||
                o.category.toLowerCase().indexOf(_filterTerm) >= 0)
            : [...invoiceFiles]);
    }, [invoiceFiles, filterTerm, t]);

    const updateFilter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        setFilterTerm(event.currentTarget.value);
    }

    var fetchInvoiceDocuments= (authenticationService: IAuthenticationService, currentCustomerId: string) => {
        return new Promise<IInvoiceFile[]>((resolve, reject) => {
            authenticationService.getOrRenewNativeToken().then((msAccessToken: string) => {
                fetch(Context.APIUrl + "/api/v1/CustomerPortal/GetInvoiceDocuments?customerId=" + currentCustomerId, {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + msAccessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                }).then((response) => {
                    response.json().then((responseAsJson: IInvoiceFile[]) => {
                        resolve(responseAsJson);
                    });
                }).catch((error: any) => {
                    console.warn("Could not fetch user details");
                    reject(error);
                });
            });
        });
    }

    return (
       <>
        <div className="row">
            <div className="col-12 image-container">
                <img src="/assets/images/hero-fakturaunderlag.jpg" alt="" />
            </div>
        </div>
            <div className="row mt-4">
                <div className="col">
                    <div className="afs-content">
                        <div className="row">
                                <div className="col-md-8 col-12">
                                <h4>{t('ALL_INVOICES')}</h4>
                                </div>
                                <div className="col-md-4 col-12">
                                    <InputGroup className="search-container">
                                        <InputGroupText><i className="fa fa-search" /></InputGroupText>
                                    <Input placeholder={t('SEARCH')} onKeyUp={updateFilter} />
                                    </InputGroup>
                                </div>
                            {isLoading 
                                ? <h1 className="loading-indicator-container col-12"><Spinner />{t('FETCHING_INVOICES')}...</h1> 
                                : <div className="col-12"><InvoiceFiles onFileClick={onFileClick} items={filteredInvoices} /></div>}
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={showError} onClosed={() => { setShowError(false) }}>
                <ModalHeader>
                    {t('FETCH_INVOICE_ERROR_HEADING')}
                </ModalHeader>
                <ModalBody>
                    {t('FETCH_INVOICE_ERROR_CONTENT')}<br/>
                    {<a onClick={(event: any) => { event.preventDefault(); setShowDetailedError(!showDetailedError) }} href="#">{showDetailedError ? t('HIDE_ERROR_DETAILS') : t('SHOW_ERROR_DETAILS')}</a>}
                    {showDetailedError && <div>{errorDetails}</div>}
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-primary" onClick={() => { setShowError(false); setShowDetailedError(false);  }}> OK </Button>
                </ModalFooter>
            </Modal>
       </>
    );
}

export default connect(
    (appState: ApplicationState) => ({ UserState: appState.authentication }),
    { ...AuthenticationStore.actions }
)(Invoices);