import React from "react";

const useServiceWorker = function(){
    
    const [showReloadInstruction,setShowReloadInstructions] = React.useState(false);
    
    navigator.serviceWorker.getRegistration("/custom-service-worker.js").then(registration => {
        if(registration) {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing as ServiceWorker;
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            setShowReloadInstructions(true);
                        } else {
                            console.debug('Content is cached for offline use.');
                        }
                    }
                };
            };
        }
    });

    return {
        showReloadInstructions : showReloadInstruction
    }
}
export default useServiceWorker;