import * as React from 'react';
import './Profile.css';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { IAuthenticationActionCreators, LoginState, UserState} from '../../store/Authentication';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import { ISiteReference, IUserInRole } from '../pages/Login';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { ICustomerReference } from '../../models/User';
import Context from '../../configuration/context';
import { IUserService } from '../../services/userService';
import userServiceFactory from '../../services/userService';
import { ApplicationState } from '../../store';
import { authenticationServiceFactory } from '../../services/authenticationService';
import * as AuthenticationStore from '../../store/Authentication'

export type IProfileProperties =
    IAuthenticationActionCreators &
    {
        UserState: UserState;
        OnLogout?: () => void;
        OnActiveCustomerSelect?: (selectedSiteReference: ISiteReference) => void
    }

const Profile = function ({ UserState, OnLogout, OnActiveCustomerSelect, setLoginState }:IProfileProperties) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [includeHidden, setIncludeHidden] = useState<boolean>(false);
    const { t } = useTranslation('profile');
    let timer: NodeJS.Timeout;


    const loginState = UserState?.LoginState;
    
    if (loginState && loginState === LoginState.LOGGED_IN) {
        const userService: IUserService = userServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, UserState));
        const toggleDropDown = () => setDropdownOpen(prevState => !prevState);
        const loadOptions = (inputValue: string, callback: (options: ICustomerReference[]) => void) => {
            if(timer)
                clearTimeout(timer);
            timer = setTimeout(async () => {
                const references: ICustomerReference[] = await userService.SearchForCustomersReference(inputValue, !!includeHidden);
                callback(references);
            }, 500);
        };

        if (UserState.UserInRole){
            const UserInRole:IUserInRole = UserState.UserInRole;
            const UserIsAdmin: boolean = UserInRole.role === "http://schemas.avonova.com/avonova/v1/identity/claims/role/superadmin";
            let availableCustomers:ISiteReference[] = UserInRole.availableCustomers
                ? UserInRole.availableCustomers
                :[];

            React.useEffect(() => {
                console.log("useEffect", includeHidden);
            },[includeHidden]);

            return (
                <>
                    <h1>{t('YOUR_PROFILE')}</h1>
                    <h2>{UserInRole.name} <Button className="logout-button btn-primary" onClick={() => {
                        OnLogout && OnLogout()
                    }} >{t('LOGOUT')}</Button></h2>
                    <div>
                        {UserIsAdmin && <>
                            <span>{t('ACTIVE_CUSTOMER')}:  { UserInRole?.selectedCustomer?.customerName }</span>
                            <p>{t('ADMIN_SELECT_CUSTOMER_INSTRUCTIONS')}</p>
                            
                            {/* <label>
                                <input
                                key={includeHidden ? "true" : "false"}
                                type="checkbox"
                                id="includeHiddenCheckbox"
                                name="includeHiddenCheckbox"
                                onChange={(e) => {
                                    setIncludeHidden(!includeHidden);
                                }}
                                checked={includeHidden}
                             />
                           <Trans i18nKey="INCLUDE_HIDDEN_UNITS" ns="profile">Inkludera dolda enheter (visas <span className='hidden-in-j4'> kursivt</span>  i listan)</Trans> </label> */}
                           <AsyncSelect<ICustomerReference>
                                formatOptionLabel={(data:ICustomerReference) => <>{data.isHidden ? <span className='hidden-in-j4'>{data.name}</span> : data.name}</>}
                                placeholder={t('ENTER_CUSTOMER_NAME')}
                                getOptionLabel={(r) => r.name }
                                loadOptions={loadOptions}
                                onChange={(d) => {
                                    d && OnActiveCustomerSelect && OnActiveCustomerSelect({
                                        customerName: d?.name,
                                        customerId: d?.id.toString(),
                                        invoicesAreAvailable: false,
                                        orderServiceIsAvailable : false,
                                        movieServiceIsAvailable : false
                                    });
                                    d && (
                                        availableCustomers = [{
                                            customerName: d.name,
                                            customerId: d?.id.toString(),
                                            invoicesAreAvailable: false,
                                            orderServiceIsAvailable: false,
                                            movieServiceIsAvailable: false
                                        }]
                                    )
                                }}
                            />
                            </>
                        }
                        {!UserIsAdmin && <>
                            {availableCustomers.length === 1 && <span>{t('CUSTOMER')}: {UserInRole?.selectedCustomer?.customerName}</span>}
                            {availableCustomers.length > 1 &&
                            <>
                                <p>{t('USER_SELECT_CUSTOMER_INSTRUCTIONS')}</p>
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown} onSelect={(d) => { console.debug(d); }} >
                                    <DropdownToggle caret className="btn-primary" >
                                        {UserInRole?.selectedCustomer ? t('ACTIVE_CUSTOMER') + " : " + UserInRole?.selectedCustomer?.customerName : t('SELECT_ACTIVE_CUSTOMER') }
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-content">
                                        {availableCustomers.map((item: ISiteReference, index: number) =>
                                            <DropdownItem title={item.customerName + " (" + item.customerId + ")"} className="dropdown-item" onClick={() => {
                                                OnActiveCustomerSelect && OnActiveCustomerSelect(availableCustomers[index]);
                                            }} key={index}>{item.customerName} ({item.customerId})</DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </>
                            }
                        </>}
                    </div>
                </>
            );
        }
        else{
            return (<></>);
        }
    }else{
        return (<></>);
    }
}

export default connect(
    (appState: ApplicationState) => ({ UserState: appState.authentication }),
    { ...AuthenticationStore.actions }
    )(Profile);