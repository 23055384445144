import React from 'react';
import { connect } from 'react-redux';
import './Help.css'

import { Trans, useTranslation } from 'react-i18next';

import * as RRD from 'react-router-dom'
import { NavLink } from 'reactstrap';

const Help = () => {
  return (
    <div className="container mt-4 help">
      <div className="row main-content">
        <div className="col-12 ingress px-4">
          <Trans i18nKey="BACK" ns="help"><NavLink tag={RRD.NavLink} to="/service"></NavLink></Trans>
          <Trans i18nKey="INTRO" ns="help">
            <p></p>
            <h1></h1>
            <h2></h2>
            <h3></h3>
            <h4></h4>
            <h5></h5>
            <div></div>
            <a href="https://avonova-kp-login.azurewebsites.net/">Till den gamla inloggningen</a>
            <em></em>
          </Trans>
          <Trans i18nKey="FAQ" ns="help">
            <p><a href="https://avonova-kp-login.azurewebsites.net/"></a>
              <em></em></p>
            <h1></h1>
            <h2></h2>
            <h3></h3>
            <h4></h4>
            <h5></h5>
            <div></div>            
          </Trans>
          <Trans i18nKey="BACK" ns="help"><NavLink className='btn-primary back' tag={RRD.NavLink} to="/service"></NavLink></Trans>
        </div>
      </div>
    </div>
  )
};

export default connect()(Help);
