import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import { Collapse, Button, CardBody, Card, Modal, ModalBody } from 'reactstrap';
import './CustomerMovies.css';



const CustomerMovies = function (props:any) {

  const [modal, setModal] = useState(false);
  const [url, setUrl] = useState<any>("");
  

  const toggle = () => setModal(!modal);

  const movies = [
    { title: "Organisatorisk och social arbetsmiljö", url: "https://vimeo.com/778447459/0299ac40aa" },
    { title: "Ergonomi och belastning i arbete", url: "https://vimeo.com/773820017/4c7b23e9bc" },
    { title: "Alkohol- och drogproblem i arbetslivet", url: "https://vimeo.com/774274210/aef66c3c38" },
    { title: "Arbetsanpassning och rehab", url: "https://vimeo.com/780759580/578744a353" }
  ];
   
  const externalCloseBtn = ( 
    <Button
      type="button"
      className="close pill-primary rounded-full"
      style={{ position: 'absolute', top: '15px', right: '15px'}}
      onClick={toggle}
    >&times;</Button>
  );

  return (
    <>
      <Collapse isOpen={props.isOpen}>
        <Card className="movie-card">
          <CardBody className="movie-card-body">
            {movies.map((movie, key) => (
              <div className="row" key={key}>
                <div className="col-2 movie-icon m-auto rounded-1"><i className="fa fa-play fa-2xs icon"></i></div>
                <div className="col-10 movie-link" onClick={() => { setModal(!modal); setUrl(movie.url) } }>{movie.title}</div>
              </div>
            ))}
            
            
          </CardBody>
        </Card>
      </Collapse>
      <div className="player-wrapper">
        <Modal contentClassName="react-player-modal" isOpen={modal} toggle={toggle} external={externalCloseBtn} backdrop={true} centered>
          <ModalBody className="modal-body" style={{ padding: '0'}}>
            {/* <ReactPlayer className="react-player" url={url} controls={true} width="1000px" height="500px" /> */}
            <ReactPlayer
              className="reactplayer"
              url={url}
              config={{
                vimeo: {
                  playerOptions: {
                    transparent: false,
                    controls: true,
                  }
                }
              }}
              width="1000px"
              height="700px"
              />
          </ModalBody>
        </Modal>
      </div>
      
    </>
  )
}

export default connect()(CustomerMovies);