import {Action, Reducer } from 'redux';
import { IUserInRole } from '../components/pages/Login';
import * as Constants from 'redux-persist/lib/constants';
import { ITokenProvider } from '../services/authenticationService';
import dayjs from 'dayjs';
// import { AppThunkAction } from './';

export const LoginState = {
    NOT_LOGGED_IN : "notLoggedIn",
    LOGGING_IN : "loggingin",
    LOGIN_FAILED : "login_failed",
    LOGGED_IN : "loggedin",
    UNDEFINED : "undefined",
    REFRESHING_TOKEN : "refreshing"
}

export interface UserStateParam {
    RedirectWhenAuthenticated?:boolean;
    RedirectUrl?:string;
    UserName?: string;
    AccessToken?: string;
    RefreshToken?: string;
    AccessTokenExpirationDate?: Date;
    RefreshTokenExpirationDate?: Date;
    LoginState?: string;
    UserInRole?: IUserInRole;
    RequestedCustomerId?: string;
    MfaWasUsed? : boolean;
}
 
export interface UserState{
    RedirectWhenAuthenticated: boolean;
    RedirectUrl: string;
    UserName?: string;
    AccessToken?: string;
    RefreshToken?: string;
    AccessTokenExpirationDate?: Date;
    RefreshTokenExpirationDate?: Date;
    LoginState?: string;
    UserInRole? : IUserInRole;
    RequestedCustomerId? : string;
    TokenProvider? : ITokenProvider;
    TokenProviderName : string;
    UserPanelIsVisible : boolean;
    MfaWasUsed:boolean
}

interface _payload {
    authentication: UserState
}

export interface SetLoginStateAction { type: 'SET_LOGIN_STATE', newState: UserStateParam }
export interface ToggleUserProfilePanelVisibilityAction { type: 'TOGGLE_USER_PANEL_VISIBILITY' }
export interface ShowUserProfilePanelAction { type: 'SHOW_USER_PANEL' }
export interface HideUserProfilePanelAction { type: 'HIDE_USER_PANEL' }

export interface RehydrateAction { type: typeof Constants.REHYDRATE, payload: _payload }

export type UserAction = SetLoginStateAction | RehydrateAction | ToggleUserProfilePanelVisibilityAction | ShowUserProfilePanelAction | HideUserProfilePanelAction;

export const actions = {
    setLoginState: (state: UserStateParam) => ({ type: 'SET_LOGIN_STATE', newState: state } as SetLoginStateAction),
    toggleUserProfileVisibility: () => ({ type: 'TOGGLE_USER_PANEL_VISIBILITY' } as ToggleUserProfilePanelVisibilityAction),
    showUserProfile: () => ({ type: 'SHOW_USER_PANEL' } as ShowUserProfilePanelAction),
    hideUserProfile: () => ({ type: 'HIDE_USER_PANEL' } as HideUserProfilePanelAction)
}


export interface IAuthenticationActionCreators {
    setLoginState?: (state: UserStateParam) => SetLoginStateAction
    toggleUserProfileVisibility?: () => ToggleUserProfilePanelVisibilityAction
}

export const defaultState:UserState = { 
    UserName: "", 
    AccessToken: "", 
    RefreshToken: "", 
    AccessTokenExpirationDate: new Date(1970, 1, 1), 
    RefreshTokenExpirationDate: new Date(1970, 1, 1), 
    LoginState: LoginState.UNDEFINED,
    UserInRole: undefined,
    RequestedCustomerId : "",
    RedirectUrl : "",
    RedirectWhenAuthenticated : false,
    TokenProvider: undefined,
    TokenProviderName : "",
    UserPanelIsVisible: false,
    MfaWasUsed:false
}

export const expirationConfig = {
    // (Optional) Key to be used for the time relative to which store is to be expired
    persistedAtKey: 'AccessTokenExpirationDate',
    // (Required) Seconds after which store will be expired
    expireSeconds: 60,
    // (Optional) State to be used for resetting e.g. provide initial reducer state
    expiredState: defaultState,
    // (Optional) Use it if you don't want to manually set the time in the reducer i.e. at `persistedAtKey` 
    // and want the store to  be automatically expired if the record is not updated in the `expireSeconds` time
    autoExpire: false
}

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
    
    if (state === undefined) {
        return defaultState;
    }

    const action = incomingAction as UserAction;
    switch (action.type) {

        case Constants.REHYDRATE:
            console.debug("Rehydrating");
            const rehydrateAction = incomingAction as RehydrateAction;
            if (!rehydrateAction.payload)
                return defaultState;

            // var AccessTokenExpirationDate:Date = rehydrateAction.payload.authentication.AccessTokenExpirationDate
            //     ? new Date(rehydrateAction.payload.authentication.AccessTokenExpirationDate)
            //     : dayjs(new Date()).add(-1, 'day').toDate();

            var loginState = rehydrateAction.payload.authentication.LoginState;

            if (loginState === LoginState.LOGGING_IN || loginState === LoginState.REFRESHING_TOKEN) {
                loginState = LoginState.NOT_LOGGED_IN
            }

            var _newState = {
                ...rehydrateAction.payload.authentication,
                AccessTokenExpirationDate: rehydrateAction.payload.authentication.AccessTokenExpirationDate
                    ? new Date(rehydrateAction.payload.authentication.AccessTokenExpirationDate)
                    : new Date(),
                RefreshTokenExpirationDate :  rehydrateAction.payload.authentication.RefreshTokenExpirationDate                    
                    ? new Date(rehydrateAction.payload.authentication.RefreshTokenExpirationDate)
                    : new Date(),
                LoginState: loginState
            }
            console.debug("Rehydrated state", _newState);
            return _newState;
        
        case 'SET_LOGIN_STATE':
            const loginAction = incomingAction as SetLoginStateAction;
            var newState = {...state};
            var params = loginAction.newState;
            
            if (params.LoginState != null) {
                newState.LoginState = params.LoginState;
                if(newState.LoginState !== LoginState.LOGGED_IN){
                    newState.UserInRole = undefined;
                }
            }
            if (params.AccessToken != null) {
                newState.AccessToken = params.AccessToken;
            }
            
            if (params.RedirectWhenAuthenticated != null &&  params.RedirectUrl) {
                newState.RedirectWhenAuthenticated = params.RedirectWhenAuthenticated;
                newState.RedirectUrl = params.RedirectUrl;
            }
            if (params.UserInRole != null) {
                newState.UserInRole = params.UserInRole;
            }
            if (params.AccessTokenExpirationDate != null) {
                newState.AccessTokenExpirationDate = params.AccessTokenExpirationDate;
            }
            if (params.UserName != null) {
                newState.UserName = params.UserName;
            }
            if (params.RefreshToken != null) {
                newState.RefreshToken = params.RefreshToken;
            }

            if (params.RefreshTokenExpirationDate != null) {
                newState.RefreshTokenExpirationDate = params.RefreshTokenExpirationDate;
            }

            if (params.RequestedCustomerId != null) {
                newState.RequestedCustomerId = params.RequestedCustomerId;
            }

            if(params.MfaWasUsed != null){
                newState.MfaWasUsed = !!params.MfaWasUsed;
            }

            console.log("new login state", newState);

            return newState;

        case 'TOGGLE_USER_PANEL_VISIBILITY':
            return {...state,  UserPanelIsVisible: !state.UserPanelIsVisible}
        case 'SHOW_USER_PANEL':
            return state.UserPanelIsVisible ? state : {...state,  UserPanelIsVisible: true};
        case 'HIDE_USER_PANEL':
            return state.UserPanelIsVisible ? {...state,  UserPanelIsVisible: false} : state;
        default:
            return state;
    }
};