import * as React from 'react';
import { connect } from 'react-redux';
import './Contact.css'
import { useTranslation, Trans } from 'react-i18next';
import { ApplicationState } from '../../store';
import { IAuthenticationActionCreators}  from '../../store/Authentication'
import ContactCard from '../controls/ContactCard';
import * as AuthenticationStore from '../../store/Authentication'
import { authenticationServiceFactory } from '../../services/authenticationService';
import Context from '../../configuration/context';
import { useMemo } from 'react';

export type IContactProps =
  IAuthenticationActionCreators &
  {
    UserState: AuthenticationStore.UserState;
  }

const Contact = ({ UserState, setLoginState }: IContactProps) => {
  
  const { t } = useTranslation('contact');
  
  const authenticationService = useMemo(() => authenticationServiceFactory(Context.APIUrl, { setLoginState }, UserState), [UserState]);
  const contactCards = useMemo(() =>{
    return UserState.UserInRole?.selectedCustomer?.customerContacts 
      ? UserState.UserInRole?.selectedCustomer?.customerContacts.map((contact) => <ContactCard className="col-12 col-lg-6" key={contact.email} contact={contact} authenticationService={authenticationService} />) 
        : []
  }, [UserState.UserInRole?.selectedCustomer?.customerContacts])

  return (
  <div>
      <div className="container image-container">
        <div className="row limited">
          <img className="col-12" src="/assets/images/hero-contact.jpg" alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row limited">
          <div className="col-12 mt-4">
            {t('INFO')}
          </div>
          
            {contactCards}
            
          <div className="col-12 mt-4">
            <Trans i18nKey="INFO_OTHER_CONTACT_POSSIBILITIES" ns="contact">
              <a href="http://www.avonova.se/om-oss/hitta-till-oss/">INFO_OTHER_CONTACT_POSSIBILITIES</a>
            </Trans>
          </div>
        </div>
      </div>
  </div>
  )
} 

export default connect(
  (appState: ApplicationState) => (
    { UserState: appState.authentication }),
    { ...AuthenticationStore.actions }
  )(Contact);
