import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input, InputGroup, InputGroupText, Spinner } from 'reactstrap';
import { ApplicationState } from '../../store';

import Context from '../../configuration/context';

import './Files.css';

import '../../services/filesService';
import { filesServiceFactory, IFile } from '../../services/filesService';
import CustomerFiles from '../controls/CustomerFiles';


import { useTranslation, Trans } from 'react-i18next';
import { authenticationServiceFactory } from '../../services/authenticationService';
import * as AuthenticationStore from '../../store/Authentication'
import { IAuthenticationActionCreators } from '../../store/Authentication';
import dayjs from 'dayjs';


export type IFilesProps =
    IAuthenticationActionCreators &
    {
        authenticationState?: AuthenticationStore.UserState;
    }

const Files: React.FunctionComponent<React.PropsWithChildren<IFilesProps>> = ({ setLoginState, authenticationState }: React.PropsWithChildren<IFilesProps>) => {

    const [files, setFiles] = useState([] as IFile[]);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredFiles, setFilteredFiles] = useState<IFile[]>();
    const [fileFilterTerm, setFileFilterTerm] = useState("");
    const { t, ready } = useTranslation('filearea');

    useEffect(() =>  {
        if(ready)
            setBusyMessage('FETCHING_FILES');
    },[ready]);

    const LoginState = authenticationState?.LoginState;
    const customerId = authenticationState?.UserInRole?.selectedCustomer?.customerId;
    
    const [busyMessage, setBusyMessage] = useState<string>(t('FETCHING_FILES'));

    useEffect(() => {
        if (LoginState && LoginState === AuthenticationStore.LoginState.LOGGED_IN && customerId) {
            // setIsLoading(true);
            filesServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState)).loadFiles(customerId.toString())
                .then((files: IFile[]) => {
                    setIsLoading(false);
                    setFiles(files);
                    setFilteredFiles(files)
                }).catch((error: any) => {
                    console.error("Load file error: ",error);
                    setIsLoading(false);
                    setFiles([]);
                });
        }
    }, [customerId, LoginState, authenticationState, setLoginState ]);

    useEffect(() => {
        var _fileFilterTerm = fileFilterTerm.toLowerCase();
        setFilteredFiles(_fileFilterTerm.length > 0 ? 
                files.filter(f => 
                    f.title.toLowerCase().indexOf(_fileFilterTerm) >= 0 
                    || dayjs(f.created).format("YYYY-MM-DD").toLowerCase().startsWith(_fileFilterTerm)
                    || f.author.displayName.toLowerCase().indexOf(_fileFilterTerm) >= 0
                    || f.author.email.toLowerCase().indexOf(_fileFilterTerm) >= 0
                ) : [...files]);
    }, [files, fileFilterTerm]);

    const downloadFile = (href:string, filename:string) => {
        if(authenticationState?.LoginState && authenticationState.LoginState === AuthenticationStore.LoginState.LOGGED_IN){
            return filesServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState)).getFileContent(href).then((text: Blob) => {
                let file = new File([text], filename, { type: "application/octet-stream", });
                let exportUrl = URL.createObjectURL(file);
                const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
                a.href = exportUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(exportUrl);
            });
        }
    }
    const uploadFile = (formData: FormData) : Promise<void> =>  {
        setBusyMessage(t('UPLOADING_FILE'))
        setIsLoading(true);
        return new Promise<void>((resolve, reject ) => {
            if (authenticationState?.LoginState && authenticationState.LoginState === AuthenticationStore.LoginState.LOGGED_IN && !!customerId) {
                return filesServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState)).uploadFiles(customerId.toString(),formData).then((addedFiles: IFile[]) => {
                    setIsLoading(false);
                    setFiles([...addedFiles, ...files]);
                    resolve();
                }).catch((error: any) => {
                    setIsLoading(false);
                    setFiles([]);
                    reject();
                });
            }else{
                reject();
            }
        });
    }

    const deleteFile = (deleteLink:string) => {
        return filesServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState)).deleteFile(deleteLink)
            .then(() => {
                setFiles(files.filter(f => f.deleteLink !== decodeURI(deleteLink)));
            }).catch((error: any) => {

            });
    }

    const updateFilter = (event:React.KeyboardEvent<HTMLInputElement>) => {
        setFileFilterTerm(event.currentTarget.value);
    }

    return (
       <>
        <div className="row limited">
            <div className="col-12 image-container">
                <img src="/assets/images/hero-fakturaunderlag.jpg" alt="" />
            </div>
        </div>
        <div className="row limited filearea">
            <div className="col mt-4">
                <div className="files-content">
                    <div className="row">
                        <div className="col-md-8 col-12">
                            <p>
                                <Trans ns="filearea" i18nKey="INFO" >
                                Här finner du kundspecifika rutiner- och informationsdokument som har laddats upp för hela din organisation från Avonova. 
                                Alla utsedda beställare i din organisation som har tillgång till Avonovas Webbportal ser samtliga uppladdade dokument. 

                                <p className="important">OBS! Här får man inte ladda upp individrelaterade dokument med känslig information. Vi rekommenderas att ni använder er av meddelandefunktionen i portalen. </p>

                                </Trans>
                            </p>
                        </div>
                        <div className="col-md-4 col-12">
                            <InputGroup className="search-container">
                                <InputGroupText><i className="fa fa-search" /></InputGroupText>
                                <Input placeholder={t('SEARCH')} onKeyUp={updateFilter}  />
                            </InputGroup>
                        </div>
                        {
                            isLoading 
                                    ? <h1 className="loading-indicator-container col-12"><Spinner />{busyMessage}...</h1>
                                    : <div className="col-12"><CustomerFiles onDownload={downloadFile} onUpload={uploadFile} items={filteredFiles} onDelete={deleteFile} /></div>
                        }
                    </div>
                </div>
            </div>
        </div>

       </>
    );
}

export default connect(
    (appState: ApplicationState) => ({ authenticationState: appState.authentication }), 
    { ...AuthenticationStore.actions}
)(Files);