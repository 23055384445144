import dayjs from "dayjs";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IConversation, IConversationPageData, IConversationReference, IConversationService, IMessage} from "../../services/conversationService";

import { IConversationModel } from "../pages/Conversations";


const useConversations = (
    selectedCustomerId:string|number,
    selectedConversationId:string|number,
    conversationService:IConversationService
) => {

    interface IState{
        errorMessage:string,
    }

    let [errorMessage,setErrorMessage] = useState("");

    const queryClient = useQueryClient();
    var conversationCacheKey = "Conversation " + selectedConversationId;
    var conversationsDataCacheKey =  "Conversations for " + selectedCustomerId;

    const loadConversation = () => {
        setErrorMessage("");
        if(conversationQuery.isIdle && !conversationQuery.isSuccess)
            conversationQuery.refetch();
    };

    const addMessageToConversation = (form: IConversationModel, callback: (message:IMessage) => void) => {
        setErrorMessage("");
        messageMutation.mutate(form,{
            onSuccess(data, variables, context) {
                callback(data)
            },
            onError(error:any, variables, context) {
                setErrorMessage(error.toString());
                console.debug("error", error);
                console.debug("message", variables);
                console.debug("context", context);
            },
        });
    };

    const createConversation = (form: IConversationModel, callback: () => void) => {
        setErrorMessage("");
        conversationMutation.mutate(form, {
            onSuccess(data, variables, context) {
                callback();
            },
        });
    }

    const toggleArchivingStatus = () => {
        setErrorMessage("");
        archivingMutation.mutate();
    }

    var messageMutation = useMutation((form: IConversationModel) => {
        var temporaryMessage:IMessage = {
            author : "",
            authorIsCurrentUser : true,
            creationDate : new Date(),
            message :form.message,
            attachments : (!!form.attachments ? form.attachments : []).map(a => {
                return {
                    displayName : a.name,
                    url : ""
                }
            })
        }

        var conversation = queryClient.getQueryData<IConversation>(conversationCacheKey);
        if(conversation){
            conversation = {...conversation, messages : [temporaryMessage ,...conversation.messages]}
            queryClient.setQueryData(conversationCacheKey,conversation);
        }

        return conversationService.addMessageToConversation({
                messageBody: form.message,
                conversationId: selectedConversationId.toString() || ""
            }, form.attachments)
            .then((message:IMessage) => {
                var conversation = queryClient.getQueryData<IConversation>(conversationCacheKey);
                if(conversation){
                    temporaryMessage = message;
                    conversation = {...conversation, messages : [message ,...conversation.messages.slice(1)]}
                    queryClient.setQueryData(conversationCacheKey,conversation);
                }
                return message;
            });
    });

    var archivingMutation = useMutation(conversationCacheKey, () => {

        var refToBackup:IConversationReference;
        var refToCurrentConversationInList:IConversationReference;
        var conversation = queryClient.getQueryData<IConversation>(conversationCacheKey);
        if(conversation){
            var archivingOperation = conversation.isArchived ? conversationService.unarchiveConversation : conversationService.archiveConversation;
            var pageData = queryClient.getQueryData<IConversationPageData>(conversationsDataCacheKey);
            
            queryClient.setQueryData<IConversation>(conversationCacheKey, {
                ...conversation,
                isArchived : !conversation.isArchived
            });

            if(pageData?.conversations){
                pageData.conversations = pageData.conversations.map(c => {
                    if(c.conversationId !== conversation?.conversationId){
                        return c;
                    }
                    else{
                        refToBackup = c;
                        refToCurrentConversationInList = { ...c, isArchived: !c.isArchived}
                        return refToCurrentConversationInList;
                    }
                });
                queryClient.setQueryData<IConversationPageData>(conversationsDataCacheKey, {
                    ...pageData,
                    conversations : pageData?.conversations
                });
            }
            return archivingOperation(selectedConversationId.toString())
                .catch((error:any) => {
                    var pageData = queryClient.getQueryData<IConversationPageData>(conversationsDataCacheKey);
                    if(pageData?.conversations){
                        pageData.conversations.map(c => (c === refToCurrentConversationInList) ? refToBackup : c);
                        queryClient.setQueryData<IConversationPageData>(conversationsDataCacheKey, {
                            ...pageData,
                            conversations : [...pageData?.conversations]
                        });
                    };
                });
        }

        return Promise.resolve();
    });

    const conversationMutation = useMutation(conversationCacheKey,(form:IConversationModel) => {

        var conversationPageData = queryClient.getQueryData<IConversationPageData>(conversationsDataCacheKey);
        var temporaryConversationReference:IConversationReference;

        if(conversationPageData){
            temporaryConversationReference = { 
                conversationId : "-1",
                hasUnreadMessages : false,
                creationDate: dayjs(new Date()).format('LLL'),
                customerId : selectedCustomerId.toLocaleString(),
                isArchived : false,
                mostRecentActivityDate: dayjs(new Date()).format('LLL'),
                otherParty : [{ 
                    conversationId: "-1",
                    displayName: form.recipient.displayName,
                    email: form.recipient.email
                }],
                subject : form.subject
            }
            queryClient.setQueryData(conversationsDataCacheKey,{
                recipients : conversationPageData.recipients,
                conversations : [
                    temporaryConversationReference,
                    ...conversationPageData.conversations]
            });
        }

        return conversationService.createNewConversation(selectedCustomerId.toString(),{ ...form }, form.attachments)
            .then((c:IConversation) => {
                if(conversationPageData){
                    queryClient.setQueryData(conversationsDataCacheKey,{
                        recipients : conversationPageData.recipients,
                        conversations : [{
                            ...temporaryConversationReference, 
                            conversationId : c.conversationId
                        }, ... conversationPageData.conversations.slice(1)]   
                    });
                }
                return c;
            }).catch( (error:any) => {
                console.error("failed to create conversation");
                if(conversationPageData){
                    queryClient.setQueryData(conversationsDataCacheKey,{
                        recipients : conversationPageData.recipients,
                        conversations : conversationPageData.conversations.slice(1)
                    });
                }
            });
    });

    const conversationDataQuery = useQuery<IConversationPageData, any>(conversationsDataCacheKey, () => {
        if(!selectedCustomerId || selectedCustomerId === "-1" || selectedCustomerId === -1) 
            return new Promise<IConversationPageData>(() => {});
        else
            return conversationService.loadConversationsData(selectedCustomerId.toString())
    }, {
        refetchOnWindowFocus(query) {
            return false;
        },
        select : (data) => {
        return data
    }});

    const conversationQuery = useQuery<IConversation>(conversationCacheKey, () => {
        if(!selectedCustomerId || selectedConversationId === "-1" || selectedConversationId === -1) 
            return new Promise<IConversation>(() => {});
        else
            return conversationService.loadConversation(selectedConversationId.toString());
    },{ 
        refetchOnWindowFocus:false,
        enabled:false, onError: (error: any) => {
        setErrorMessage("403");
        console.error("Conversation load failed");
        throw error;
    } });
    
    return {
        conversations : conversationDataQuery.data?.conversations || [],
        selectedConversation : conversationQuery.data,
        selectedConversationId: selectedConversationId,
        recipients: conversationDataQuery.data?.recipients || [],
        conversationsAreLoading :conversationDataQuery.isFetching || selectedCustomerId == -1,
        loadConversation,
        conversationIsLoading: conversationQuery.isLoading,
        addMessageToConversation,
        isSubmitting: messageMutation.isLoading,
        createConversation,
        toggleArchivingStatus,
        errorMessage
    }

}

export default useConversations;