import { LoginTokensDTO } from "./customLoginService";
import jwt_decode from "jwt-decode";
import { IAuthenticationActionCreators, UserState } from "../store/Authentication";
import { IUserInRole } from "../components/pages/Login";
import Context from '../configuration/context';
import * as AuthenticationStore from '../store/Authentication'
import { authenticationServiceFactory } from "./authenticationService";

interface ISamlLoginService{
    getQrInfo: (saml:string) => Promise<string>;
    login: (accessToken: string, totpCode:string) => Promise<string>;
    register : (metadataEndpoint:string, validEmailDomains:string) => Promise<void>;
}

export default (APIBaseUrl: string, actionCreators: IAuthenticationActionCreators, currentUserState:UserState ) => {



    const fetchUserDetails = (nativeAccessToken: string) => {
        let url: string = Context.APIUrl + "/api/v1/User/GetUserInfo";
        if (currentUserState?.RequestedCustomerId) {
            url += "?specificCustomerId=" + currentUserState?.RequestedCustomerId
        }
        return fetch(url, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + nativeAccessToken
            },
            redirect: 'error',
            referrerPolicy: 'no-referrer'
        })
        .then((response: Response) => response.json() )
        .then((responseAsJson: IUserInRole) => {
            if (responseAsJson.availableCustomers) {
                responseAsJson.availableCustomers = responseAsJson.availableCustomers.sort((a, b) => a.customerName < b.customerName ? -1 : (a.customerName > b.customerName ? 1 : 0));
                if (responseAsJson.availableCustomers.length === 1) {
                    responseAsJson.selectedCustomer = responseAsJson.availableCustomers[0];
                }
                else if (currentUserState && currentUserState?.RequestedCustomerId) {
                    const requestedCustomer = responseAsJson.availableCustomers.find(c => c.customerId.toString() === currentUserState.RequestedCustomerId);
                    responseAsJson.selectedCustomer = requestedCustomer;
                }
            }
            return responseAsJson;
        }).catch((error: any) => {
            console.warn("Could not fetch user details");
            throw error;
        });
    }

    const updateGlobalLoginState = (userInRole:IUserInRole) => {
        userInRole.selectedCustomer = currentUserState?.UserInRole?.selectedCustomer || userInRole.selectedCustomer;
        actionCreators.setLoginState && actionCreators.setLoginState({
            LoginState: AuthenticationStore.LoginState.LOGGED_IN,
            UserInRole: userInRole
        });
    }

    return{
        register : (metadataEndpoint:string, validEmailDomains:string) => {

            var service = authenticationServiceFactory(APIBaseUrl, actionCreators, currentUserState);
                
            return service.getOrRenewNativeToken()
                .then((accessToken:string) => fetch(APIBaseUrl + "/api/v1/SAML/register", {
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache',
                        body: JSON.stringify({ "Url": metadataEndpoint, "Domain" : validEmailDomains}),
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'bearer ' + accessToken
                        },
                        redirect: 'error',
                        referrerPolicy: 'no-referrer'
                    })
                )
                .then(() => {})         
                .catch((error: any) => {
                    console.warn("Could not fetch user details");
                    throw error;
                });        
        },
        login : (accessToken:string, code:string) => {
            return new Promise<string>((resolve, reject) => {
                fetch(APIBaseUrl + "/api/v1/AccessToken/LoginBySAML?code=" + encodeURIComponent(code), {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache',
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'bearer ' + accessToken
                    },
                    redirect: 'error',
                    referrerPolicy: 'no-referrer'
                }).then((response:Response) => {
                    return response.json().then((response:LoginTokensDTO) => {
                        let nativeAccessToken = response.accessToken;
                        let nativeRefreshToken = response.refreshToken;
                        var tokenData: any = jwt_decode(nativeAccessToken);
                        var refreshTokenData: any = jwt_decode(nativeRefreshToken);
                        actionCreators.setLoginState && actionCreators.setLoginState({
                            AccessToken: nativeAccessToken,
                            AccessTokenExpirationDate: new Date(tokenData["exp"] * 1000),
                            RefreshToken: nativeRefreshToken,
                            RefreshTokenExpirationDate: new Date(refreshTokenData["exp"] * 1000),
                            MfaWasUsed: true
                        });
                        resolve(nativeAccessToken);
                        return nativeAccessToken;
                    });
                }).then(fetchUserDetails)
                .then((responseAsJson: IUserInRole) => {
                    if (responseAsJson.availableCustomers) {
                        responseAsJson.availableCustomers = responseAsJson.availableCustomers.sort((a, b) => a.customerName < b.customerName ? -1 : (a.customerName > b.customerName ? 1 : 0));
                        if (responseAsJson.availableCustomers.length === 1) {
                            responseAsJson.selectedCustomer = responseAsJson.availableCustomers[0];
                        }
                        else if (currentUserState && currentUserState?.RequestedCustomerId) {
                            const requestedCustomer = responseAsJson.availableCustomers.find(c => c.customerId.toString() === currentUserState.RequestedCustomerId);
                            responseAsJson.selectedCustomer = requestedCustomer;
                        }
                    }
                    return responseAsJson;
                })
                .then(updateGlobalLoginState)                
                .catch((error: any) => {
                    console.warn("Could not fetch user details");
                    throw error;
                })        
            });
        }
    } as ISamlLoginService;
}