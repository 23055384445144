import * as React from "react";
import { useEffect } from "react";
import { Button, FormGroup, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import useLogin from "../../hooks/useLogin";
import { IAuthenticationActionCreators } from "../../../store/Authentication";
import * as AuthenticationStore from '../../../store/Authentication'
import TOTPLogin from "./TOTPLogin";
export type IMSLoginProps = IAuthenticationActionCreators &{
        authenticationState?: AuthenticationStore.UserState;
}
const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;
    return msie || msie11;
};

const useRedirectFlow = isIE();

const MSLogin = (props:IMSLoginProps) => {
    const { t } = useTranslation('login');
    const { login, cancelLogin, continueAs, errorMessage, activeAccounts, hasActiveAccounts, loginIsInProgress, loginByTOTPCode, state, otpRegistrationUrl} = useLogin(props.setLoginState, props.authenticationState, useRedirectFlow);
    var printableErrorMessage:string = t(errorMessage);
   
    const [showAbortButton, setShowAbortButton] = React.useState(false);
    let timeout:NodeJS.Timeout;
    var showActiveAccounts: boolean = hasActiveAccounts;

    useEffect(()  => {
        if(hasActiveAccounts)
            login();
    }, []);

    var onLogin = (() => {
        setShowAbortButton(false);
        login();
    });

    var onCancelLogin = (() => {
        cancelLogin();
    });

    var onContinueAs = ((index: number) => {
        setShowAbortButton(false);
        continueAs(index);
    });
    
    useEffect(() => {
        timeout && clearTimeout(timeout);
        if (loginIsInProgress){
            timeout = setTimeout(() => {
                setShowAbortButton(true);
            }, 30000);
        }
    }, [loginIsInProgress]);

    return <>
        {!!errorMessage && <div className="error-message">{printableErrorMessage}</div>}
        <FormGroup className="loginContainer">
            {state == "IDLE" && <>
                {!showActiveAccounts && <Button className="col-12 btn-primary" onClick={login}>{t('LOGIN_WITH_MS')}</Button>}
                {showActiveAccounts && activeAccounts && <div>
                    {activeAccounts.map((info, index: number) => <Button key={index} onClick={() => { onContinueAs(index) }} className="col-12 mt-3 btn-primary">{t('CONTINUE_AS', { name: info.name + ", " + info.username })}</Button>)}
                    <Button className="col-12 mt-3 btn-primary" onClick={onLogin}>{t('LOGIN_WITH_MS')}</Button>
                </div>}
            </>}
            {state == 'AWAITING_CODE_LOGIN' && <>
                <TOTPLogin onSubmit={loginByTOTPCode}  qrRegistrationUrl={otpRegistrationUrl} onRestart={cancelLogin} isSubmitting={false}/>
            </>}            
            {(state == "ACQUIRING_MS_TOKEN" || state == "DOING_FINAL_LOGIN") && <>
                <h1 className="login-indicator-container"><Spinner className="login-indicator"></Spinner>{t('LOGGING_IN_MS')}...</h1>
                {showAbortButton && <Button className="col-12 mt-3 btn-primary" onClick={onCancelLogin}>{t('ABORT')}</Button> }
            </>} 
        </FormGroup>
    </>
}

export default MSLogin;