import * as React from "react";
import { useMemo, useState } from "react";
import { Input, Label } from "reactstrap";
import './CustomLogin.css';
import { createChangeHandler, CreateEnterCallback } from "../../../services/formUtils";
import { useTranslation } from 'react-i18next';
import stringUtils from "../../../services/stringUtils";
import SubmitButton from "./SubmitButton";

export interface IFetchLimitedInfoProps{
    onSubmit : (email:string) => Promise<void>;
    onEmailChange?: (newValue: string) => void;
}

const SetPassword = (props: IFetchLimitedInfoProps) => {

    const { t } = useTranslation('login');

    const [email,setEmail] = useState("");
    const [isSubmitting,setIsSubmitting] = useState(false);

    const emailIsValid = useMemo(() => stringUtils.validateEmail(email), [email]);

    React.useEffect(() => {
        props.onEmailChange && props.onEmailChange(email);
    }, [email, props]);

    const onSubmit = () => {
        if(!canBeSubmitted)
            return;
        setIsSubmitting(true);
        props.onSubmit(email).then(() => {
            setIsSubmitting(false);           
        },() => {
            setIsSubmitting(false);
        }).catch(() => {
            setIsSubmitting(false);
        });
    }

    const canBeSubmitted = useMemo(() => {
        return email.length > 0 && emailIsValid && !isSubmitting;
    }, [isSubmitting, emailIsValid, email]);

    return <>
        <Label>{t('LABEL_EMAIL')} <Input onKeyPress={CreateEnterCallback(() => onSubmit())} placeholder={t("PROMPT_FOR_EMAIL")} value={email} type="text" onChange={createChangeHandler(setEmail)} /></Label>
        <SubmitButton submitting={isSubmitting ? true : undefined} disabled={!canBeSubmitted} label={t('SUBMIT_BUTTON_CONTINUE')} activelabel={t('SUBMIT_BUTTON_FETCHING_USER_INFO')} className="btn-primary" onClick={onSubmit} />
    </>
}
export default SetPassword;