import * as React from "react";
import { useMemo, useState } from "react";
import { Button, Input, Label, Spinner } from "reactstrap";
import './CustomLogin.css';
import { createChangeHandler, CreateEnterCallback } from "../../../services/formUtils";
import { useTranslation, Trans } from 'react-i18next';
import SubmitButton from "./SubmitButton";

export interface ISetPasswordProps{
    onSubmit : (email:string, password:string) => Promise<void>;
    defaultEmail?:string;
    onEmailChange?: (newValue: string) => void;
    onRestart?: () => void;
    onRequestResetPasswordToken: () => Promise<void>;
}

const SetPassword = (props: ISetPasswordProps) => {

    const { t, i18n } = useTranslation('login');

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formIsHidden, setFormIsHidden] = useState(false);

    React.useEffect(() => {
        if (email === "" && props.defaultEmail !== email && props.defaultEmail) {
            setEmail(props.defaultEmail);
        }
    }, [props.defaultEmail]);

    React.useEffect(() => {
        props.onEmailChange && props.onEmailChange(email);
    }, [email]);

    const onSubmit = () => {
        if (!canBeSubmitted)
            return;
        setIsSubmitting(true);
        props.onSubmit(email, password).then(() => {
            setIsSubmitting(false);
        }, () => {
            setIsSubmitting(false);
        }).catch(() => {
            setIsSubmitting(false);
        });
    }

    const onResetPassword = () => {
        setFormIsHidden(true);
    }

    const canBeSubmitted = useMemo(() => {
        var canBeSubmitted:boolean = password.length > 0 && email.length > 0 && !isSubmitting;
        return canBeSubmitted || true;
    }, [isSubmitting, password, email]);

    return <>{
        !formIsHidden && <>
            <Label>{t('LABEL_EMAIL')} <Input placeholder={t("PROMPT_FOR_EMAIL")} value={email} type="text" onChange={createChangeHandler(setEmail)} disabled /></Label>
            <Label>{t('LABEL_PASSWORD')} <Input placeholder={t("PROMPT_FOR_PASSWORD")} type="password" value={password} onChange={createChangeHandler(setPassword)} onKeyPress={CreateEnterCallback(() => onSubmit())} /></Label>
            <span><Trans i18nKey="INFO_REQUEST_NEW_PASSWORD_RESET_LINK" ns="login"><a href="#" onClick={(event) => { event.preventDefault(); event.stopPropagation(); props.onRequestResetPasswordToken(); onResetPassword() }}></a></Trans></span>
            <span className="buttons">
                {props.onRestart && <Button onClick={props.onRestart}>{t('SUBMIT_BUTTON_RESTART')}</Button>}
                <SubmitButton submitting={isSubmitting ? true : undefined} disabled={!canBeSubmitted} label={t('SUBMIT_BUTTON_REQUEST_ONE_USE_PASSWORD')} activeLabel={t('SUBMIT_BUTTON_REQUESTING_ONE_USE_PASSWORD')} className="btn-primary" onClick={onSubmit} />
            </span>
        </>
        }
    </> 
    
}
export default SetPassword;