import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: '' });
var customReactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '04f73912-9b0f-46b9-8f5d-ae7f47fc3f1f',
        extensions: [customReactPlugin],
        extensionConfig: {
            [customReactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
console.debug("Application insights loaded");
export { appInsights, customReactPlugin };