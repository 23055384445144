import * as React from 'react';
import { connect } from 'react-redux';
import * as AuthenticationStore from '../../store/Authentication'
import * as AlertStore from '../../store/Alerts'
import { ApplicationState } from '../../store';
import { FormGroup, Input, Label, Spinner } from 'reactstrap';
import './Login.css'
import { IAuthenticationActionCreators } from '../../store/Authentication';
import { IAlertActionCreators } from '../../store/Alerts';
import { IUser, ICustomerContact, IAdministrator } from '../../models/User';
import { Trans, useTranslation } from 'react-i18next';
import Context from '../../configuration/context';
import { useEffect, useMemo, useState } from 'react';
import { appInsights } from '../../services/applicationInsights';
import CustomLogin from '../controls/CustomLogin/CustomLogin';
import customLoginService from '../../services/customLoginService';
import MSLogin from '../controls/MSLogin/MSLogin';
import samlLoginService from '../../services/samlLoginService';
import { ITokenProvider } from '../../services/authenticationService';
import { useLocation } from 'react-router-dom';
import { access } from 'fs';
import { createChangeHandler } from '../../services/formUtils';
import SubmitButton from '../controls/CustomLogin/SubmitButton';

export type IRegisterSamlProps =
    IAuthenticationActionCreators &
    {
        authenticationState?: AuthenticationStore.UserState;
    }

const SamlRegister = function (params: IRegisterSamlProps) {

    const { i18n, t } = useTranslation('login');

    const { search } = useLocation();
    const queryParameters = new URLSearchParams(search)
    const accessToken = queryParameters.get("accesstoken");

    useEffect(() => {
       
    },[]);

    const onSubmit = () => {
        setIsSubmitting(true);
        if(params.authenticationState)
            samlLoginService(Context.APIUrl, params, params.authenticationState).register(metadataEndpoint, domains).then(() => {
                setIsSubmitting(false);
                setDomains("");
                setMetadataEndpoint("");
            })
    }


    const [metadataEndpoint, setMetadataEndpoint] = useState("");
    const [domains, setDomains] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    
    const canBeSubmitted = !isSubmitting && metadataEndpoint.length > 0;

    return (
        <div className="login-container center">
            <div className="col-md-6 offset-md-3">
                <h2><Trans i18nKey="LABEL_ENDPOINT" ns="login">Register SAML IdP</Trans></h2>
                <FormGroup className="loginContainer">
                    {isSubmitting && <>
                        <h1 className="login-indicator-container"><Spinner className="login-indicator"></Spinner>{t('LOGGING_IN_MS')}...</h1>
                    </>}
                    {!isSubmitting && <>
                    <Label><Trans i18nKey="LABEL_ENDPOINT" ns="login">Metadata endpoint</Trans> <Input placeholder={t("PROMPT_FOR_METDATA_ENDPOINT")} value={metadataEndpoint} type="text" onChange={createChangeHandler(setMetadataEndpoint)} /></Label>
                    <Label><Trans i18nKey="LABEL_EMAIL_DOMAINS" ns="login">Valid domains</Trans> <Input placeholder={t("PROMPT_FOR_EMAIL_DOMAINS")} value={domains} type="text" onChange={createChangeHandler(setDomains)} /></Label>
                    <span className="buttons">
                        <SubmitButton submitting={isSubmitting ? true : undefined} disabled={!canBeSubmitted} label={t('SUBMIT_BUTTON_REQUEST_ONE_USE_PASSWORD')} activeLabel={t('SUBMIT_BUTTON_REQUESTING_ONE_USE_PASSWORD')} className="btn-primary" onClick={onSubmit} />
                    </span>
                    </>}
                </FormGroup>
            </div>
        </div>
    )

};

export default connect(
    (appState: ApplicationState) => (
        { authenticationState: appState.authentication }),
    { ...AuthenticationStore.actions, ...AlertStore.actionCreators } 
)(SamlRegister);