import * as DatePicker from "react-datepicker";

import sv from 'date-fns/locale/sv';
import nn from 'date-fns/locale/nn';

DatePicker.registerLocale('sv', sv);
DatePicker.registerLocale('no', nn);
DatePicker.setDefaultLocale('sv');

const useConfiguredDatePicker = function(){
    return { DatePicker }
}
export default useConfiguredDatePicker;