import { SetStateAction } from "react";

export function createChangeHandler(setter: React.Dispatch<SetStateAction<string>>){
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setter(event.currentTarget.value || "");
    };
}

export function CreateEnterCallback(callbackMethod: any, escapeCallback?: any) {
    return (event: any) => {
        if (event.keyCode === 13 || event.charCode === 13) {
            callbackMethod();
        }
        if ((event.keyCode === 27 || event.charCode === 13)&&  escapeCallback) {
            event.preventDefault();
            event.stopPropagation();
            escapeCallback();
        }
    };
}