import { Action, Reducer } from "redux";

export interface SetLanguageAction { type: 'SET_LANGUAGE', language: string }

export type LanguageAction = SetLanguageAction;

const defaultState = "";

export const reducer: Reducer<string> = (state: string | undefined = defaultState, incomingAction: Action): string => {
    const action = incomingAction as LanguageAction;
    switch (action.type) {
        case 'SET_LANGUAGE':
            return action.language
        default:
            return state;
    }
};