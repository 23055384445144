import { useMemo } from "react";
import { appInsights } from "../../services/applicationInsights";
import { authenticationServiceFactory, IError } from "../../services/authenticationService";
import Context from '../../configuration/context';
import { loginScopes } from "../../configuration/authConfig";
import * as AuthenticationStore from '../../store/Authentication'
import { SetLoginStateAction, UserStateParam } from "../../store/Authentication";
import React from "react";

export type MSLoginState = "IDLE" | "ACQUIRING_MS_TOKEN" | "AWAITING_CODE_LOGIN" | "DOING_FINAL_LOGIN";

interface ILoginState{
    state:MSLoginState;
    msAccessToken:string;
    otpRegistrationUrl:string;
}

const useLogin = (setLoginState: ((state: UserStateParam) => SetLoginStateAction)|undefined, authenticationState: AuthenticationStore.UserState|undefined, useRedirectFlow:boolean) => {
    
    const [errorMessage, setErrorMessage] = React.useState("");
    const [state,_setState] = React.useState<ILoginState>({
        state: "IDLE",
        msAccessToken : "",
        otpRegistrationUrl : ""
    });

    const setState = (_state:ILoginState) =>{
        console.debug(_state)
        _setState(_state);
    }

    const authenticationService = useMemo(() => { 
        return authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState);
    }, [authenticationState, setLoginState]); 
    
    var activeAccounts = authenticationService.getCurrentlyLoggedInUsers();
    var hasActiveAccounts: boolean = !!activeAccounts && activeAccounts.length > 0;
    var loginIsInProgress: boolean = authenticationState?.LoginState === AuthenticationStore.LoginState.LOGGING_IN;

    const onMsAccessTokenAqcuired = (msAccessToken:string) => {

        authenticationService
            .acquireNativeToken(msAccessToken)
            .then(response =>{
                if(response.totpIsNeeded){
                    setState({
                        ...state,
                        state: "AWAITING_CODE_LOGIN",
                        otpRegistrationUrl : response.totpURL,
                    })
                }
            });
    };

    const onError = (error: IError) => {
        
        setState({
            ...state,
            state:"IDLE"
        });

        console.error(error.errorCode, error.errorMessage);
        if (error.errorCode === "403") {
            appInsights.stopTrackEvent("Login process", { "outcome": "user not found" });
            setErrorMessage('ERROR_USER_NOT_FOUND');
        }
        if (error.errorCode === "404") {
            appInsights.stopTrackEvent("Login process", { "outcome": "user not found" });
            setErrorMessage('ERROR_USER_NOT_FOUND');
        }
        else if (error.errorCode === "-1" && error.errorMessage === "Could not find server"){
            appInsights.stopTrackEvent("Login process", { "outcome": "backend error" });
            setErrorMessage('ERROR_BACKEND_NOT_FOUND');
        }
        else {
            console.log('Unexpected error in "useLogin"', error);
            appInsights.stopTrackEvent("Login process", { "outcome": "unmanaged error", "message:" : error.errorMessage });
            setErrorMessage(error.errorMessage);
        }
    };

    const login = (() => {
        try {
            appInsights.startTrackEvent("Login process");

            setState({
                ...state,
                state : "ACQUIRING_MS_TOKEN"
            });
            
            authenticationService.loginUser(loginScopes, useRedirectFlow)
                .then(onMsAccessTokenAqcuired)
                .catch(onError);

        } catch (error) {
            appInsights.stopTrackEvent("Login process", { "outcome": "unmanaged error", "error:": JSON.stringify(error) });
            console.error('Unexpected error in "login"', error);
            setErrorMessage('ERROR_UNKNOWN_ERROR');
        }
    });

    const cancelLogin = () => {
        try {
            authenticationService.cancel();
            setState({
                state: "IDLE",
                msAccessToken : "",
                otpRegistrationUrl : ""
            });

         } catch (error) {
             console.error(error);
             setErrorMessage('ERROR_UNKNOWN_ERROR');
         }
    }

    const loginByTOTPCode = ((code:string ) => {

        console.log(state);

        appInsights.startTrackEvent("Login process");
        authenticationService.verifyByTOTP(code)
            .then(() => {               
                appInsights.stopTrackEvent("Login process", { "outcome" : "success"});
            })
            .catch((error: IError) => {
                console.error(error.errorCode, error.errorMessage);
                if (error.errorCode === "403") {
                    appInsights.stopTrackEvent("Login process", { "outcome": "user not found" });
                    setErrorMessage('ERROR_USER_NOT_FOUND');
                }
                if (error.errorCode === "404") {
                    appInsights.stopTrackEvent("Login process", { "outcome": "user not found" });
                    setErrorMessage('ERROR_USER_NOT_FOUND');
                }
                else if (error.errorCode === "-1" && error.errorMessage === "Could not find server"){
                    appInsights.stopTrackEvent("Login process", { "outcome": "backend error" });
                    setErrorMessage('ERROR_BACKEND_NOT_FOUND');
                }
                else {
                    appInsights.stopTrackEvent("Login process", { "outcome": "unmanaged error", "message:" : error.errorMessage });
                    setErrorMessage(error.errorMessage);
                }
            });
    });

    const continueAs = ((index: number) => {
        appInsights.startTrackEvent("Login process");

        if (!activeAccounts)
            return;

        try {
            setState({
                ...state,
                state : "ACQUIRING_MS_TOKEN"
            });
            
            authenticationService.continueAsUser(activeAccounts[index], false)
            .then(onMsAccessTokenAqcuired)
            .catch(onError);
        } catch (error) {
            console.error('Unexpected error in "ContinueAs"', error);
            setErrorMessage('ERROR_UNKNOWN_ERROR');
        }
    });
    
    return {
        login,
        cancelLogin,
        continueAs,
        errorMessage,
        activeAccounts,
        hasActiveAccounts,
        loginIsInProgress,
        state:state.state,
        loginByTOTPCode,
        otpRegistrationUrl: state.otpRegistrationUrl
    }

}

export default useLogin;