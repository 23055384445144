import 'bootstrap/dist/css/bootstrap.css';
import { createRoot } from 'react-dom/client';
import * as React from 'react';
import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router';

import configureStore from './store/configureStore';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
// import { unregister } from './registerServiceWorker';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import {msalConfig} from  './configuration/authConfig';

import './services/i18nConfiguration';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalErrorBoundary from './components/controls/GlobalErrorBoundary';

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;

const msalInstance = new PublicClientApplication(msalConfig);
const reactQueryClient = new QueryClient();
const store = configureStore();
var container = document.getElementById('root');
if(container){
    var root = createRoot(container);
    root.render(
        <GlobalErrorBoundary>
            <Provider store={store}>
                <MsalProvider instance={msalInstance}>
                    <HelmetProvider>
                    <BrowserRouter>
                        <QueryClientProvider client={reactQueryClient}>
                            <App />
                        </QueryClientProvider>
                    </BrowserRouter>
                    </HelmetProvider>
                </MsalProvider>
            </Provider>
        </GlobalErrorBoundary>
        ,
        );
    registerServiceWorker();
}